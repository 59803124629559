// import { useLoading } from '../context/LoadingContext';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { scroller } from 'react-scroll';

import Navbar from "./Navbar";
import Expertise from './Expertise';
import Works from './Works';
import Process from './Process';
import CreativeCompo from './Creative';
import { Footer, MainBorder } from '../components';
import Welcome from './Welcome';

const Home = () => {
    const location = useLocation();

    // This is the loading modal code!!!
    // const { setIsLoading } = useLoading();

    // useEffect(() => {
    //     // Start loading
    //     setIsLoading(true);

    //     // Simulate a delay for loading components (or replace this with actual data loading logic)
    //     const timer = setTimeout(() => {
    //         setIsLoading(false); // Stop loading after everything is ready
    //     }, 1000); // Adjust delay as needed

    //     return () => clearTimeout(timer);
    // }, [setIsLoading]);

    useEffect(() => {
        // Scroll to the top when the component loads
        window.scrollTo(0, 0);

        // If there's a hash in the URL, scroll to the corresponding section
        if (location.hash) {
            const target = location.hash.replace('#', '');
            scroller.scrollTo(target, {
                smooth: true,
                duration: 500,
                offset: -100, // Adjust this offset based on your navbar height
            });
        }
    }, [location]);

    return (
        <div className="relative">

            {/* Logo element in top-left corner */}
            <a
                href="https://www.kekstcnc.com/"
                aria-label="Visit Kekst CNC website"
                rel="noopener noreferrer"
                className="absolute bg-[#210837] z-10 top-2
                px-1 py-0 w-14 h-6 ml-[10vw]
                sm:px-2 sm:py-1 sm:w-16 sm:h-10 sm:ml-[10vw] sm:top-0
                md:px-2 md:py-1 md:w-16 md:h-10 md:ml-[10vw] md:top-0
                lg:px-2 lg:py-1 lg:w-18 lg:h-12 lg:ml-[10vw] lg:top-1
                xl:px-3 xl:py-1 xl:w-20 xl:h-14 xl:ml-[10vw] xl:top-0
                2xl:px-3 2xl:py-2 2xl:w-20 2xl:h-14 2xl:ml-[10vw] 2xl:top-4
                3xl:px-1 3xl:py-0 3xl:w-[6vw] 3xl:h-[4vw] 3xl:ml-[10vw] 3xl:top-2
                flex items-center justify-center"
            >
                {/* SVG Logo */}
                <svg
                    id="Layer_1"
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1180 645.14"
                    className="w-10 h-10 sm:w-12 sm:h-12 md:w-16 md:h-16 lg:w-20 lg:h-20 3xl:w-[4vw] 3xl:h-[3vw]"
                >
                    <defs>
                        <style>{`.cls-1{fill:#fff;}`}</style>
                    </defs>
                    <path
                        className="cls-1"
                        d="M153,615.66c34.85,0,67.62-17.31,88.69-46h41.78c-.92,1.73-1.76,3.18-2.17,3.89-26.26,48-75.39,77.73-128.3,77.73-81.42,0-147.66-69.1-147.66-154s66.24-154,147.66-154c54.73,0,104.38,31.1,130.18,81.32H241.5c-21-28.63-53.43-45.75-88.51-45.75-61.8,0-112.09,53.14-112.09,118.46S91.19,615.66,153,615.66m675.61-46c-21.07,28.73-53.84,46-88.69,46-61.81,0-112.09-53.14-112.09-118.46S678.1,378.74,739.91,378.74c35.08,0,67.49,17.12,88.51,45.75h41.67c-25.8-50.22-75.45-81.32-130.18-81.32-81.42,0-147.66,69.1-147.66,154s66.24,154,147.66,154c52.91,0,102-29.78,128.3-77.74.41-.7,1.24-2.15,2.16-3.88ZM572.14,346H536.57v245.5L340.13,346.15v-.21H304.55V648.43h35.58V403.06L536.57,648.38v.08h35.57Zm-53.56-300V9.55l-187.72,0V319l187.72-.06V282.54H367.2V181.93H503.34V145.56H367.2V45.92Zm332,276.77c65.2,0,109-37.37,109-93,0-42.5-27.45-69.49-81.6-80.21l-43.38-8.78c-37.41-6.93-56.37-23.15-56.37-48.22,0-32.74,27.24-53.89,69.39-53.89,43.65,0,70.43,20,77.44,57.9l.4,2.16h34.47l-.37-3c-6.83-56.09-49.46-89.57-114-89.57-62.19,0-104,35.42-104,88.15,0,42.58,28.08,70.47,81.18,80.65L872.4,185c33.86,6.41,51.76,23.09,51.76,48.23,0,34.06-28.4,56.08-72.34,56.08-49.89,0-81.27-25.36-88.37-71.41l-.34-2.24H728.62l.31,2.95c3.38,32.32,16.27,58.87,37.27,76.8s50.09,27.29,84.36,27.29m-314-3.87h35.6V9.53h-35.6ZM621.25,164.3,745.63,9.76H698.91L573.81,164.3,697.66,318.89h47.66ZM104.31,318.82h35.61V9.53H104.31ZM189,164.3,313.41,9.76H266.68L141.58,164.3,265.44,318.89h47.65ZM1185.33,9.65H966.71V46h91.14V318.64h36.34V46h91.14Z"
                        transform="translate(-5.33 -6.1)"
                    />
                </svg>
            </a >
            {/* Fullscreen Video Divider */}
            <div className="absolute z-10 mt-[95vh] hidden sm:block overflow-hidden w-full pr-2 lg:pr-4 xl:pr-[25px] 2xl:pr-[45px] 3xl:pr-[45px] pl-2 lg:pl-4 xl:pl-[25px] 2xl:pl-[45px] 3xl:pl-[45px]">
                <img
                    src="../assets/img/home/linhas.svg"
                    alt="line"
                    className="object-cover w-full h-auto"
                />
            </div>

            <video
                className="w-full h-screen object-cover hidden sm:block"
                autoPlay
                loop
                muted
                playsInline
                onContextMenu={(e) => e.preventDefault()}
            >
                <source src="https://vtwrol5ehwjzsuap.public.blob.vercel-storage.com/videos/kekst-6dJngeqMBxc1f6d8Mdks91qvQqgIAH.mp4" type="video/mp4" />
            </video>

            {/* Mobile Video */}
            <div className='z-10 mt-[468px] absolute overflow-hidden h-[150px] mr-2 ml-2 block sm:hidden'>
                <img src="../assets/img/home/divider.svg" alt="line" className='w-full object-cover scale-150' />
            </div>

            <video
                className="w-full h-[531px] object-cover block sm:hidden"
                autoPlay
                loop
                muted
                playsInline
                onContextMenu={(e) => e.preventDefault()}
            >
                <source src="https://vtwrol5ehwjzsuap.public.blob.vercel-storage.com/videos/kekst-6dJngeqMBxc1f6d8Mdks91qvQqgIAH.mp4" type="video/mp4" />
            </video>

            <Navbar />

            {/* Sections with IDs for scrolling */}
            <div id="welcome" className="section">
                <Welcome />
            </div>
            <div id="expertise" className="section">
                <Expertise />
            </div>
            <div id="works" className="section">
                <Works />
            </div>
            <div id="process" className="section">
                <Process />
            </div>
            <div id="creative" className="section">
                <CreativeCompo />
            </div>
            <div id="lets-talk" className="section">
                <Footer />
            </div>
            <MainBorder />
        </div>
    );
};

export default Home;
