import React from "react";
import CustomVideoPlayer from "../videoPlayer/CustomVideoPlayer"; // Adjust path as needed

const ManifestoCompo = () => {
    return (
        <div>
            <div className="border-t-[1px] border-white py-6 md:py-[50px] 2xl:py-[2.5vw]">
                <div className="flex text-white"></div>
                <div>
                    <div className="pl-5 md:pl-[34px] lg:pl-[59px] xl:pl-[100px] 2xl:pl-[308px]">
                        <CustomVideoPlayer
                            videoSrc="https://vtwrol5ehwjzsuap.public.blob.vercel-storage.com/newvideos/KekstCNCCreativeStudio_v4-mni7OqFvAIFJ4Ll6Nc3Ce8j5HdVcH0.mp4"
                            poster="https://vtwrol5ehwjzsuap.public.blob.vercel-storage.com/images/6-veMzhQLy2TPgwd6JhFujAva9pVB841.jpg"
                            className="w-full h-auto"
                            onContextMenu={(e) => e.preventDefault()}
                            draggable="false"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ManifestoCompo;
