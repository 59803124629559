/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect } from "react";
import styles from "./CustomVideoPlayer.module.css";

const CustomVideoPlayer = ({ videoSrc, poster, className = "" }) => {
    // References
    const videoRef = useRef(null);
    const wrapperRef = useRef(null);
    const progressBarRef = useRef(null);
    const volumeControlRef = useRef(null);
    const controlsTimeoutRef = useRef(null);

    // State variables
    const [isPlaying, setIsPlaying] = useState(false);
    const [volume, setVolume] = useState(1);
    const [duration, setDuration] = useState(1); // Avoid division by zero
    const [currentTime, setCurrentTime] = useState(0);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [isMuted, setIsMuted] = useState(false);
    const [showControls, setShowControls] = useState(true);

    // Detect mobile devices
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    // Aspect ratio for the video
    const aspectRatio = 16 / 9;
    const [wrapperHeight, setWrapperHeight] = useState("auto");

    // Update wrapper height based on aspect ratio
    const updateHeight = () => {
        if (wrapperRef.current) {
            const width = wrapperRef.current.offsetWidth;
            const calculatedHeight = width / aspectRatio;
            setWrapperHeight(calculatedHeight);
        }
    };

    useEffect(() => {
        updateHeight();
        window.addEventListener("resize", updateHeight);

        return () => {
            window.removeEventListener("resize", updateHeight);
        };
    }, [aspectRatio]);

    // Sync isPlaying state with video playback
    useEffect(() => {
        const video = videoRef.current;

        const handlePlay = () => {
            setIsPlaying(true);
            resetControlsTimeout();
        };
        const handlePause = () => {
            setIsPlaying(false);
            resetControlsTimeout();
        };

        const handleTimeUpdate = () => {
            const currentTime = video.currentTime;
            const duration = video.duration || 1;

            // Calculate progress value out of 1000
            const progressValue = (currentTime / duration) * 1000;

            // Update CSS variable for progress on the progress bar
            if (progressBarRef.current) {
                progressBarRef.current.style.setProperty("--progress", `${progressValue / 10}%`);
            }

            setCurrentTime(currentTime);
        };

        const handleVolumeChange = () => {
            const volumePercent = video.volume * 100;
            if (volumeControlRef.current) {
                volumeControlRef.current.style.setProperty("--volume", `${volumePercent}%`);
            }
            setVolume(video.volume);
            setIsMuted(video.muted || video.volume === 0);
        };

        const handleLoadedMetadata = () => {
            const duration = video.duration || 1;
            setDuration(duration);
        };

        video.addEventListener("play", handlePlay);
        video.addEventListener("pause", handlePause);
        video.addEventListener("timeupdate", handleTimeUpdate);
        video.addEventListener("volumechange", handleVolumeChange);
        video.addEventListener("loadedmetadata", handleLoadedMetadata);

        // Initialize CSS variables
        if (progressBarRef.current) {
            progressBarRef.current.style.setProperty("--progress", `0%`);
        }

        handleTimeUpdate();
        handleVolumeChange();

        return () => {
            video.removeEventListener("play", handlePlay);
            video.removeEventListener("pause", handlePause);
            video.removeEventListener("timeupdate", handleTimeUpdate);
            video.removeEventListener("volumechange", handleVolumeChange);
            video.removeEventListener("loadedmetadata", handleLoadedMetadata);
        };
    }, []);

    // Handle fullscreen changes and screen orientation
    useEffect(() => {
        const handleFullscreenChange = () => {
            const isFullscreenNow =
                document.fullscreenElement === wrapperRef.current ||
                (isIOS && videoRef.current.webkitDisplayingFullscreen);
            setIsFullscreen(isFullscreenNow);

            // Update wrapper height on fullscreen change
            updateHeight();

            if (!isFullscreenNow) {
                // Unlock orientation when exiting fullscreen
                if (window.screen.orientation && window.screen.orientation.unlock) {
                    try {
                        window.screen.orientation.unlock();
                    } catch (err) {
                        console.error("Failed to unlock orientation:", err);
                    }
                }
            }
        };

        document.addEventListener("fullscreenchange", handleFullscreenChange);
        // For iOS devices
        if (isIOS) {
            videoRef.current.addEventListener("webkitendfullscreen", handleFullscreenChange);
        }

        return () => {
            document.removeEventListener("fullscreenchange", handleFullscreenChange);
            if (isIOS) {
                videoRef.current.removeEventListener("webkitendfullscreen", handleFullscreenChange);
            }
        };
    }, [isIOS]);

    // Handle orientation changes
    useEffect(() => {
        const handleOrientationChange = () => {
            // Update the wrapper height when orientation changes
            updateHeight();
        };

        window.addEventListener("orientationchange", handleOrientationChange);

        return () => {
            window.removeEventListener("orientationchange", handleOrientationChange);
        };
    }, []);

    // Reset controls timeout
    const resetControlsTimeout = () => {
        if (controlsTimeoutRef.current) {
            clearTimeout(controlsTimeoutRef.current);
        }
        setShowControls(true);
        controlsTimeoutRef.current = setTimeout(() => {
            setShowControls(false);
        }, 2000); // Controls will hide after 2 seconds
    };

    // Clean up on unmount
    useEffect(() => {
        return () => {
            if (controlsTimeoutRef.current) {
                clearTimeout(controlsTimeoutRef.current);
            }
        };
    }, []);

    // Handle play/pause toggle
    const togglePlayPause = () => {
        const video = videoRef.current;
        if (video.paused || video.ended) {
            video.play();
        } else {
            video.pause();
        }
        resetControlsTimeout();
    };

    // Handle volume change
    const handleVolumeChange = (e) => {
        const newVolume = parseFloat(e.target.value);
        const video = videoRef.current;
        video.volume = newVolume;
        video.muted = newVolume === 0;
        setVolume(newVolume);

        const volumePercent = newVolume * 100;
        if (volumeControlRef.current) {
            volumeControlRef.current.style.setProperty("--volume", `${volumePercent}%`);
        }
        resetControlsTimeout();
    };

    // Toggle mute
    const toggleMute = () => {
        const video = videoRef.current;
        video.muted = !video.muted;
        setIsMuted(video.muted);
        if (volumeControlRef.current) {
            volumeControlRef.current.style.setProperty(
                "--volume",
                `${video.muted ? 0 : video.volume * 100}%`
            );
        }
        resetControlsTimeout();
    };

    // Handle seek
    const handleSeek = (e) => {
        const newProgressValue = parseFloat(e.target.value);
        const video = videoRef.current;
        const duration = video.duration || 1;

        // Calculate the new currentTime based on progress value
        const newTime = (newProgressValue / 1000) * duration;

        video.currentTime = newTime;
        setCurrentTime(newTime);

        // Update CSS variable for progress on the progress bar
        if (progressBarRef.current) {
            progressBarRef.current.style.setProperty("--progress", `${newProgressValue / 10}%`);
        }
        resetControlsTimeout();
    };

    // Handle fullscreen toggle
    const handleFullscreen = async () => {
        const video = videoRef.current;
        const wrapper = wrapperRef.current;

        if (isIOS && video.webkitEnterFullscreen) {
            video.webkitEnterFullscreen();
            setIsFullscreen(true);
        } else {
            if (!document.fullscreenElement) {
                // Request fullscreen
                if (wrapper.requestFullscreen) {
                    await wrapper.requestFullscreen();
                } else if (wrapper.webkitRequestFullscreen) {
                    await wrapper.webkitRequestFullscreen();
                } else if (wrapper.msRequestFullscreen) {
                    await wrapper.msRequestFullscreen();
                }

                setIsFullscreen(true);

                // Lock orientation to landscape on supported devices
                if (window.screen.orientation && window.screen.orientation.lock) {
                    try {
                        await window.screen.orientation.lock("landscape");
                    } catch (err) {
                        console.error("Failed to lock orientation:", err);
                    }
                }

                resetControlsTimeout();
            } else {
                // Exit fullscreen
                if (document.exitFullscreen) {
                    await document.exitFullscreen();
                } else if (document.webkitExitFullscreen) {
                    await document.webkitExitFullscreen();
                } else if (document.msExitFullscreen) {
                    await document.msExitFullscreen();
                }

                setIsFullscreen(false);

                // Unlock orientation
                if (window.screen.orientation && window.screen.orientation.unlock) {
                    try {
                        await window.screen.orientation.unlock();
                    } catch (err) {
                        console.error("Failed to unlock orientation:", err);
                    }
                }

                setShowControls(true);
                if (controlsTimeoutRef.current) {
                    clearTimeout(controlsTimeoutRef.current);
                }
            }
        }
    };

    // Format time for display
    const formatTime = (time) => {
        if (!time || isNaN(time)) return "00:00";
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        const pad = (num) => (num < 10 ? "0" + num : num);
        return `${pad(minutes)}:${pad(seconds)}`;
    };

    return (
        <div
            ref={wrapperRef}
            className={`${styles.videoWrapper} ${className}`}
            style={{ height: wrapperHeight }}
            onMouseMove={resetControlsTimeout}
            onClick={resetControlsTimeout}
            onTouchStart={resetControlsTimeout}
            onContextMenu={(e) => e.preventDefault()} // Disable right-click on wrapper
        >
            <video
                ref={videoRef}
                src={videoSrc}
                poster={poster}
                className={styles.videoElement}
                controls={false} // Enable native controls on iOS devices
                playsInline={true} // Use playsInline on non-iOS devices
                webkit-playsinline="true"
                x5-playsinline="true"
                onClick={(e) => {
                    e.stopPropagation();
                    togglePlayPause();
                }}
                onContextMenu={(e) => e.preventDefault()}// Disable right-click on video
            >
                Your browser does not support the video tag.
            </video>
            {!isPlaying && (
                <div
                    className={styles.centerPlayButton}
                    onClick={(e) => {
                        e.stopPropagation();
                        togglePlayPause();
                    }}
                >
                    <svg viewBox="0 0 24 24" className={styles.playIcon}>
                        <path fill="black" d="M8,5.14V19.14L19,12.14L8,5.14Z" />
                    </svg>
                </div>
            )}
            <div
                className={`${styles.controls} ${showControls ? styles.show : styles.hide}`}
                onClick={(e) => {
                    e.stopPropagation();
                    resetControlsTimeout();
                }}
                onMouseMove={(e) => {
                    e.stopPropagation();
                    resetControlsTimeout();
                }}
                onTouchStart={(e) => {
                    e.stopPropagation();
                    resetControlsTimeout();
                }}
            >
                <button onClick={togglePlayPause} className={styles.playButton}>
                    {isPlaying ? (
                        <svg viewBox="0 0 24 24" className={styles.pauseIcon}>
                            <path fill="currentColor" d="M14,19H18V5H14M6,19H10V5H6V19Z" />
                        </svg>
                    ) : (
                        <svg viewBox="0 0 24 24" className={styles.playIcon}>
                            <path fill="currentColor" d="M8,5.14V19.14L19,12.14L8,5.14Z" />
                        </svg>
                    )}
                </button>
                <input
                    ref={progressBarRef}
                    type="range"
                    min="0"
                    max="1000"
                    value={(currentTime / duration) * 1000}
                    onChange={handleSeek}
                    className={styles.progressBar}
                />
                <div className={styles.timeDisplay}>
                    <span>{formatTime(currentTime)}</span> / <span>{formatTime(duration)}</span>
                </div>
                <div className={styles.volumeContainer}>
                    <button onClick={toggleMute} className={styles.muteButton}>
                        {isMuted || volume === 0 ? (
                            <svg className={styles.volumeIcon} viewBox="0 0 24 24">
                                {/* Muted Volume Icon */}
                                <path
                                    fill="currentColor"
                                    d="M12,4L9.91,6.09L12,8.18M4.27,3L3,4.27L7.73,9H3V15H7L12,20V13.27L16.25,17.53C15.58,18.04 14.83,18.46 14,18.7V20.77C15.38,20.45 16.63,19.82 17.68,18.96L19.73,21L21,19.73L12,10.73M19,12C19,12.94 18.8,13.82 18.46,14.64L19.97,16.15C20.62,14.91 21,13.5 21,12C21,7.72 18,4.14 14,3.23V5.29C16.89,6.15 19,8.83 19,12Z"
                                />
                            </svg>
                        ) : volume >= 0.5 ? (
                            <svg className={styles.volumeIcon} viewBox="0 0 24 24">
                                {/* High Volume Icon */}
                                <path
                                    fill="currentColor"
                                    d="M14,3.23V5.29C16.89,6.15 19,8.83 19,12C19,15.17 16.89,17.84 14,18.7V20.77C18,19.86 21,16.28 21,12C21,7.72 18,4.14 14,3.23M16.5,12C16.5,10.23 15.5,8.71 14,7.97V16C15.5,15.29 16.5,13.76 16.5,12M3,9V15H7L12,20V4L7,9H3Z"
                                />
                            </svg>
                        ) : (
                            <svg className={styles.volumeIcon} viewBox="0 0 24 24">
                                {/* Low Volume Icon */}
                                <path
                                    fill="currentColor"
                                    d="M5,9V15H9L14,20V4L9,9M18.5,12C18.5,10.23 17.5,8.71 16,7.97V16C17.5,15.29 18.5,13.76 18.5,12Z"
                                />
                            </svg>
                        )}
                    </button>
                    <input
                        ref={volumeControlRef}
                        type="range"
                        min="0"
                        max="1"
                        step="0.01"
                        value={volume}
                        onChange={handleVolumeChange}
                        className={styles.volumeControl}
                    />
                </div>
                <button onClick={handleFullscreen} className={styles.fullscreenButton}>
                    {isFullscreen ? (
                        <svg viewBox="0 0 24 24" className={styles.fullscreenIcon}>
                            {/* Exit Fullscreen Icon */}
                            <path
                                fill="currentColor"
                                d="M5 16h3v3h2v-5H5v2zm3-8H5v2h5V5H8v3zm6 11h2v-3h3v-2h-5v5zm2-11V5h-2v5h5V8h-3z"
                            />
                        </svg>
                    ) : (
                        <svg viewBox="0 0 24 24" className={styles.fullscreenIcon}>
                            {/* Enter Fullscreen Icon */}
                            <path
                                fill="currentColor"
                                d="M7 14H5v5h5v-2H7v-3zm-2-4h2V7h3V5H5v5zm12 7h-3v2h5v-5h-2v3zM14 5v2h3v3h2V5h-5z"
                            />
                        </svg>
                    )}
                </button>
            </div>
        </div>
    );
};

export default CustomVideoPlayer;
