/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect } from "react";
import { Footer, MainBorder } from "../components";
import Navbar from "./Navbar";
import { Link } from "react-router-dom";
import CustomVideoPlayer from "../videoPlayer/CustomVideoPlayer";

const Skwp = ({ isSticky, element }) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="relative">
            {/* Navbar */}
            <Navbar sticky={isSticky} />

            {/* Welcome Section with Images */}
            <div className="relative text-white welcome" ref={element}>
                {/* Desktop Image */}
                <img
                    src="/assets/img/skwp/header_SKWP.jpg"
                    alt="SKW Desktop"
                    className="w-full hidden md:block object-cover min-h-[850px]"
                />
                {/* Mobile Image */}
                <img
                    src="/assets/img/skwp/header_SKWP.jpg"
                    alt="SKW Mobile"
                    className="w-full h-full object-cover min-h-[715px] block md:hidden"
                />
                {/* Overlay Content */}
                <div className="w-full absolute bottom-0">
                    <div className="flex flex-col px-2 lg:px-4 xl:px-[25px] 2xl:px-[45px]">
                        {/* Title */}
                        <div className="flex">
                            <div className="w-6 lg:w-[60px] 2xl:w-[299px] 3xl:w-[15vw] flex-none"></div>
                            <div>
                                <div
                                    className="font-Helvetica font-bold mt-auto
                                    text-[50px] leading-[34px] md:text-[50px] md:leading-[57px]
                                    lg:text-[70px]
                                    xl:text-[80px]
                                    lg:leading-[80px] xl:leading-[184px]
                                    py-14 sm:py-6 md:py-10 lg:py-10 xl:py-5 2xl:py-16 
                                    3xl:py-[6vw] 5xl:py-[8vw]
                                    3xl:text-[6.5vw] "
                                >
                                    SKWP
                                </div>
                            </div>
                        </div>
                        {/* Desktop Overview: */}
                        <div className="hidden lg:block">
                            <div
                                className="lg:flex-col 2xl:flex-row flex border-white border-y-[1px] sm:text-4xl md:text-3xl lg:text-[22px] 3xl:text-[1.2vw] 5xl:text-[1vw] lg:h-[35vh] xl:h-[30vh] 2xl:h-[30vh] 3xl:h-[25vh] items-start 2xl:items-center"
                            >
                                <div
                                    className="font-normal lg:pl-[60px] lg:pr-0 lg:pb-4 lg:pt-4 xl:pt-4 xl:pb-4 2xl:pt-0 2xl:pb-[2.2vw] w-[300px] lg:w-[200px] 2xl:w-[308px] 3xl:w-[14vw] mb-0 3xl:pl-[2vw] 3xl:pr-[2vw] 2xl:px-[2vw]"
                                >
                                    Overview:
                                </div>
                                <div className="font-normal lg:grid grid-cols-3 w-full items-start 2xl:items-center">
                                    <div className="font-normal pl-[60px] 2xl:pl-8 pr-4 xl:border-l-0 2xl:border-l-[1px] 
                                                leading-tight h-full 
                                                3xl:pl-[2.15vw] 3xl:pr-[1vw] 5xl:pl-[2.5vw] 5xl:pr-[1.2vw]">
                                        Developed a strategic campaign to highlight SKWP’s leadership in advanced chemical processes.
                                    </div>
                                    <div className="font-normal pl-8 pr-4 border-l-[1px] leading-tight h-full 
                                                3xl:pl-[2vw] 3xl:pr-[2vw] 5xl:pl-[3vw] 5xl:pr-[2vw]">
                                        Simplified complex industrial concepts through narrative-driven videos and infographic explainers.
                                    </div>
                                    <div className="font-normal pl-8 pr-4 border-l-[1px] leading-tight h-full 
                                                3xl:pl-[2vw] 2xl:pr-[3vw] 5xl:pl-[3vw] 5xl:pr-[2vw]">
                                        Delivered targeted messaging to policymakers, media and economic stakeholders, fostering recognition of SKWP’s societal and economic impact.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="px-2 lg:px-4 xl:px-[25px] 2xl:px-[45px]">
                {/* Mobile Overview: */}
                <div className="block lg:hidden font-Helvetica font-normal py-[41px] text-white 
                                pl-6 3xl:pl-[4vw] 5xl:pl-[6vw]">
                    <div className="text-[13px] leading-[14px] uppercase 3xl:text-[1vw] 5xl:text-[1.2vw]">Overview:</div>
                    <div className="text-[13px] leading-[15px] py-3 border-b-[1px] 
                                3xl:text-[1vw] 5xl:text-[1.2vw]">
                        Developed a strategic campaign to highlight SKWP’s leadership in advanced chemical processes.
                    </div>
                    <div className="text-[13px] leading-[15px] py-3 border-b-[1px] 
                                3xl:text-[1vw] 5xl:text-[1.2vw]">
                        Simplified complex industrial concepts through narrative-driven videos and infographic explainers.
                    </div>
                    <div className="text-[13px] leading-[15px] py-3 border-b-[1px] 
                                3xl:text-[1vw] 5xl:text-[1.2vw]">
                        Delivered targeted messaging to policymakers, media and economic stakeholders, fostering recognition of SKWP’s societal and economic impact.
                    </div>
                </div>

                {/* Partnership Section */}
                <div
                    className="flex flex-col 2xl:flex-row md:pt-[10px] lg:pt-10 xl:pt-[156px] 2xl:pt-[211px] 
                    pb-10 lg:pb-20 xl:pb-[106px] 2xl:pb-[134px] w-full font-Helvetica text-white"
                >
                    <div
                        className="uppercase xl:normal-case text-start 2xl:text-left font-normal text-[13px] md:text-lg 2xl:text-2xl 3xl:text-[1.2vw] pt-3 xl:w-full 2xl:w-[308px] 3xl:w-[15vw] flex-none px-6 lg:px-[60px] 2xl:px-[2vw] py-6 2xl:py-0.2"
                    >
                        The partnership:
                    </div>
                    <div
                        className="font-bold md:font-normal text-[18px] md:text-[28px] 2xl:text-[40px] 3xl:text-[1.8vw] px-6 lg:px-[60px] 2xl:px-[0] 2xl:pr-[3.5vw] w-full leading-tight"
                    >
                        In collaboration with SKWP, we crafted a strategic campaign designed to amplify the company’s role in advanced chemical processes. By integrating a narrative-driven video with supplementary outputs, such as infographic explainers, we simplified complex industrial concepts and delivered a cohesive message that resonated with policymakers, media representatives and economic stakeholders.
                    </div>
                </div>

                {/* Video Section 1 */}
                <div>
                    <CustomVideoPlayer
                        className="w-full h-full object-cover cursor-pointer max-h-[95vh]"
                        videoSrc="https://vtwrol5ehwjzsuap.public.blob.vercel-storage.com/skwp-WAnlNtX0reyapqSvg5XyPdCauJvt6j.mp4"
                        controls
                        preload="auto"
                        poster="/assets/img/skwp/cover.png"
                        draggable="false"
                        onContextMenu={(e) => e.preventDefault()}
                    />
                </div>

                {/* Description Section */}
                <div
                    className="py-10 2xl:py-[5vw] text-[14px] lg:text-[18px] xl:text-[1.2vw] flex font-normal font-Helvetica text-white"
                >
                    <div className="w-6 lg:w-[60px] 2xl:w-[299px] 3xl:w-[15vw] flex-none"></div>
                    <div className="px-0 pr-6 sm:px-6 lg:px-0 lg:pr-10 2xl:pr-[3.5vw]">
                        <div className="pb-5">
                            The SKWP campaign combined a narrative-rich video with complementary outputs to effectively communicate the crucial importance of advanced chemical processes, such as the production of AdBlue, fertilizers and denitrification agents, in everyday life. The video served as the campaign’s cornerstone, bringing to life the societal and economic impact of these products, while infographic explainers highlighted key parts of the video narrative to reinforce understanding and retention.
                        </div>
                        <div className="">
                            Designed for maximum versatility, the campaign’s outputs were optimized for digital platforms and in-person presentations, ensuring SKWP’s message reached policymakers, media representatives and economic stakeholders with precision and impact. By framing these processes within the context of their essential contributions to daily life and sustainable development, the campaign inspired action and fostered recognition of SKWP’s vital role in supporting the nation’s economy and environmental well-being. This initiative firmly positioned SKWP as a leader in chemical innovation and societal advancement.
                        </div>
                    </div>
                </div>

                {/* Image Section 1 */}
                <div className="flex">
                    <div className="hidden lg:block w-6 lg:w-[60px] 2xl:w-[299px] 3xl:w-[15vw] flex-none"></div>
                    <div className="w-full">
                        <img src="/assets/img/skwp/00_story _skwp.jpg" className="w-full" alt="skw Image 1" />
                    </div>
                </div>

                {/* Image Section 2 */}
                <div className="py-8 2xl:py-16 3xl:py-[6vw] flex">
                    <div className="hidden lg:block flex-none"></div>
                    <div className="w-full">
                        <img src="/assets/img/skwp/03_story_skwp.jpg" className="w-full" alt="skw Image 3" />
                    </div>
                </div>

                {/* Image Section 3 */}
                <div className="flex">
                    <div className="hidden lg:block flex-none"></div>
                    <div className="w-full">
                        <img src="/assets/img/skwp/04_story_skwp.jpg" className="w-full" alt="skw Image 4" />
                    </div>
                </div>

                {/* Image Section 4 */}
                <div className="py-8 2xl:py-16 3xl:py-[6vw] flex">
                    <div className="hidden lg:block flex-none"></div>
                    <div className="w-full">
                        <img src="/assets/img/skwp/05_story_skwp.jpg" className="w-full" alt="skw Image 5" />
                    </div>
                </div>

                {/* Image Section 5 */}
                <div className="pb-8 2xl:pb-16 3xl:pb-[6vw] flex">
                    <div className="hidden lg:block flex-none"></div>
                    <div className="w-full">
                        <img src="/assets/img/skwp/06_story_skwp.jpg" className="w-full" alt="skw Image 6" />
                    </div>
                </div>

                {/* Two Images with Hover Effect */}
                <div className="w-full border-t border-white pt-0">
                    <div className="flex text-white font-Helvetica">
                        <div className="grid grid-cols-2 w-full min-h-[300px] md:min-h-[400px]">
                            {/* First Image Container */}
                            <div className="overflow-hidden md:border-r md:border-white relative group">
                                <Link to="/ourWork/avolta">
                                    <div
                                        className="cursor-pointer py-[26px] 2xl:py-[100px] 
                                   3xl:py-[6vw] 5xl:py-[10vw] 
                                   px-[15px] md:px-10 3xl:px-[2vw] 5xl:px-[3vw] 
                                   flex flex-col h-full relative"
                                    >
                                        {/* Background image using pseudo-element */}
                                        <div className="absolute inset-0 bg-cover bg-center transition-transform duration-300 ease-in-out 
                                        bg-[url('assets/img/avolta/06_footer_Avolta.jpg')] 
                                        group-hover:scale-110 
                                        before:content-[''] before:absolute before:inset-0 
                                        before:bg-cover before:bg-center before:bg-no-repeat 
                                        before:transition-transform before:duration-300 
                                        before:ease-in-out before:scale-100 group-hover:before:scale-110" />

                                        {/* Content overlay */}
                                        <div className="relative z-10 flex flex-col justify-between h-full">
                                            {/* Title and Description */}
                                            <div>
                                                <div className="font-bold text-[15px] lg:text-base xl:text-2xl 
                                                3xl:text-[1.5vw] 5xl:text-[1vw]">
                                                    Avolta
                                                </div>
                                                <div className="flex-1">
                                                    <div
                                                        className="max-w-[440px] 3xl:max-w-[20vw] 
                                                   py-[26px] lg:py-[43px] 
                                                   xl:py-[67px] 3xl:py-[4vw] 
                                                   font-normal text-[12px] lg:text-[21px] 
                                                   xl:text-[32px] 2xl:text-[40px] 
                                                   3xl:text-[1.3vw] 5xl:text-[1.2vw] 
                                                   leading-tight 5xl:py-[5vw]"
                                                    >
                                                        Enhancing Avolta’s LinkedIn presence with a creative strategy that transforms data into dynamic, interactive narratives.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex py-8 items-center">
                                                <div className="uppercase font-normal text-[13px] lg:text-xs xl:text-lg 3xl:text-[1.1vw]">
                                                    read more
                                                </div>
                                                <div>
                                                    <svg
                                                        className="ml-3 pt-[2px] w-6 h-6 3xl:w-[1.5vw] 3xl:h-[1.5vw] 5xl:w-[1vw] 5xl:h-[1vw]"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <g id="arrow_outward">
                                                            <path
                                                                id="Vector"
                                                                d="M6.5 5.5V7.5H15.09L5.5 17.09L6.91 18.5L16.5 8.91V17.5H18.5V5.5H6.5Z"
                                                                fill="white"
                                                            />
                                                        </g>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            {/* Second Image Container */}
                            <div className="overflow-hidden border-l border-white relative group">
                                <Link to="/ourWork/farmerVoice">
                                    <div
                                        className="cursor-pointer py-[26px] 2xl:py-[100px] 
                                   3xl:py-[6vw] 5xl:py-[10vw] 
                                   px-[15px] md:px-10 3xl:px-[2vw] 5xl:px-[3vw] 
                                   flex flex-col h-full relative"
                                    >
                                        {/* Background image using pseudo-element */}
                                        <div className="absolute inset-0 bg-cover bg-center transition-transform duration-300 ease-in-out 
                                        bg-[url('assets/img/newwaters/Farmer_new_img.jpg')] 
                                         md:bg-[url('assets/img/malteser/farmer_header.png')] 
                                        group-hover:scale-110 
                                        before:content-[''] before:absolute before:inset-0 
                                        before:bg-cover before:bg-center before:bg-no-repeat 
                                        before:transition-transform before:duration-300 
                                        before:ease-in-out before:scale-100 group-hover:before:scale-110" />

                                        {/* Content overlay */}
                                        <div className="relative z-10 flex flex-col justify-between h-full">
                                            {/* Title and Description */}
                                            <div>
                                                <div className="font-bold text-[15px] lg:text-base xl:text-2xl 
                                                3xl:text-[1.5vw] 5xl:text-[1vw]">
                                                    Farmer Voice
                                                </div>
                                                <div className="flex-1">
                                                    <div
                                                        className="max-w-[440px] 3xl:max-w-[20vw] 
                                                   py-[26px] lg:py-[43px] 
                                                   xl:py-[67px] 3xl:py-[4vw] 
                                                   font-normal text-[12px] lg:text-[21px] 
                                                   xl:text-[32px] 2xl:text-[40px] 
                                                   3xl:text-[1.3vw] 5xl:text-[1.2vw] 
                                                   leading-tight 5xl:py-[5vw]"
                                                    >
                                                        Blending tradition with innovation to empower agriculture, creating a narrative-driven, stakeholder-engaging identity.
                                                    </div>
                                                </div>
                                            </div>
                                            {/* "Read More" Link */}
                                            <div className="flex py-8 items-center pl-4 
                                            3xl:py-[2vw] 5xl:py-[2.5vw]">
                                                <div
                                                    className="uppercase font-normal text-[13px] 
                                               lg:text-xs xl:text-lg 
                                               3xl:text-[1.1vw] 5xl:text-[1.2vw]"
                                                >
                                                    read more
                                                </div>
                                                <div>
                                                    <svg
                                                        className="ml-3 pt-[2px] w-6 h-6 
                                               3xl:w-[1.5vw] 3xl:h-[1.5vw] 
                                               5xl:w-[1vw] 5xl:h-[1vw]"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <g id="arrow_outward">
                                                            <path
                                                                id="Vector"
                                                                d="M6.5 5.5V7.5H15.09L5.5 17.09L6.91 18.5L16.5 8.91V17.5H18.5V5.5H6.5Z"
                                                                fill="white"
                                                            />
                                                        </g>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

            {/* Footer and MainBorder Components */}
            <Footer />
            <MainBorder />
        </div >
    );
};

export default Skwp;
