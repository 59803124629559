/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect } from "react";
import { Footer, MainBorder } from "../components";
import Navbar from "./Navbar";
import { Link } from "react-router-dom";

const Netclean = ({ isSticky, element }) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="relative">
            <Navbar sticky={isSticky} />
            <div className="relative text-white welcome" ref={element}>
                {/* Desktop Image */}
                <img
                    src="/assets/img/netclean/netclean.png"
                    alt="Netclean Desktop"
                    className="w-full hidden md:block object-cover min-h-[850px]"
                />
                {/* Mobile Image */}
                <img
                    src="/assets/img/netclean/netclean_mobile.png"
                    alt="Netclean Mobile"
                    className="w-full block md:hidden"
                />
                <div className="w-full absolute bottom-0 font-Helvetica">
                    <div className="flex flex-col px-2 lg:px-4 xl:px-[25px] 2xl:px-[45px]">
                        <div className="flex">
                            <div className="w-6 lg:w-[60px] 2xl:w-[299px] 3xl:w-[15vw] flex-none"></div>
                            <div>
                                <div
                                    className="font-helvetica font-bold mt-auto text-[50px] leading-[34px] md:text-[50px] md:leading-[57px] lg:text-[70px] xl:text-[80px] 2xl:text-[5.5vw] 3xl:text-[6.5vw] lg:leading-[80px] xl:leading-[184px] py-14 sm:py-6 md:py-10 lg:py-10 xl:py-5 2xl:py-16 3xl:py-[6vw]"
                                >
                                    NetClean
                                </div>
                            </div>
                        </div>
                        <div className="hidden lg:block">
                            <div
                                className="lg:flex-col 2xl:flex-row flex border-white border-y-[1px]
                                sm:text-4xl md:text-3xl lg:text-[22px] 3xl:text-[1.2vw] 5xl:text-[1vw]
                                lg:h-[220px] xl:h-[188px] 2xl:h-[200px] 3xl:h-[18vh] 5xl:h-[20vh]
                                items-start 2xl:items-center"
                            >
                                <div
                                    className="font-normal lg:pl-[60px] lg:pr-0 lg:pb-4 lg:pt-4 xl:pt-4 xl:pb-4 2xl:pt-0 2xl:pb-[2.2vw] w-[300px] lg:w-[200px] 2xl:w-[308px] 3xl:w-[14vw] mb-0 3xl:pl-[2vw] 3xl:pr-[2vw] 2xl:px-[2vw]"
                                >
                                    Overview:
                                </div>
                                <div className="font-normal lg:grid grid-cols-3 w-full items-start 2xl:items-center 
                                                3xl:gap-[1vw] 5xl:gap-[1.5vw]">
                                    <div className="pl-[60px] 2xl:pl-8 pr-4 xl:border-l-0 2xl:border-l-[1px] 
                                                leading-tight h-full 
                                                3xl:pl-[2.15vw] 3xl:pr-[1vw] 5xl:pl-[2.5vw] 5xl:pr-[1.2vw]">
                                        Strengthened NetClean's Insight Report strategy.
                                    </div>
                                    <div className="pl-8 pr-4 border-l-[1px] leading-tight h-full 
                                                3xl:pl-[2vw] 3xl:pr-[2vw] 5xl:pl-[3vw] 5xl:pr-[2vw]">
                                        Crafted a multichannel, visual approach to communicate key insights and core values.
                                    </div>
                                    <div className="pl-8 pr-4 border-l-[1px] leading-tight h-full 
                                                3xl:pl-[2vw] 2xl:pr-[3vw] 5xl:pl-[3vw] 5xl:pr-[2vw]">
                                        Converted data into dynamic stories for stakeholder engagement through social media.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="px-2 lg:px-4 xl:px-[25px] 2xl:px-[45px]">
                {/* Mobile Overview: */}
                <div className="block lg:hidden font-Helvetica font-normal py-[41px] text-white pl-6">
                    <div className="text-[13px] leading-[14px] uppercase">Overview:</div>
                    <div className="text-[13px] leading-[15px] py-3 border-b-[1px]">
                        Strengthened NetClean's Insight Report strategy.
                    </div>
                    <div className="text-[13px] leading-[15px] py-3 border-b-[1px]">
                        Crafted a multichannel, visual approach to communicate key insights and core values.
                    </div>
                    <div className="text-[13px] leading-[15px] py-3 border-b-[1px]">
                        Converted data into dynamic stories for stakeholder engagement through social media.
                    </div>
                </div>
                {/* Partnership Section */}
                <div
                    className="flex flex-col 2xl:flex-row md:pt-[10px] lg:pt-10 xl:pt-[156px] 2xl:pt-[211px] 
                    pb-10 lg:pb-20 xl:pb-[106px] 2xl:pb-[134px] w-full font-Helvetica text-white"
                >
                    <div
                        className="uppercase xl:normal-case text-start 2xl:text-left font-normal text-[13px] md:text-lg 2xl:text-2xl 3xl:text-[1.2vw] pt-3 xl:w-full 2xl:w-[308px] 3xl:w-[15vw] flex-none px-6 lg:px-[60px] 2xl:px-[2vw] py-6 2xl:py-0.2"
                    >
                        The partnership:
                    </div>
                    <div
                        className="font-bold md:font-normal text-[18px] md:text-[28px] 2xl:text-[40px] 3xl:text-[1.8vw] px-6 lg:px-[60px] 2xl:px-[0] 2xl:pr-[3.5vw] w-full leading-tight"
                    >
                        In our collaboration with NetClean, we developed a thoughtful approach to the Insight Report, presenting critical information on the IT industry's response to child sexual abuse material (CSAM). This approach aligned with NetClean’s commitment to impactful and responsible communication, emphasizing clarity and sensitivity.
                    </div>
                </div>
                {/* Video Section 1 */}
                <div>
                    <video
                        className="w-full h-auto object-cover"
                        autoPlay
                        loop
                        muted
                        playsInline
                        onContextMenu={(e) => e.preventDefault()}
                    >
                        <source src="https://vtwrol5ehwjzsuap.public.blob.vercel-storage.com/videos/NetClean_movie_1-OgBxtwCkbV44QTSC78DwU48r5dyEPb.mp4" type="video/mp4" />
                    </video>
                </div>
                {/* Description Section */}
                <div
                    className="py-10 2xl:py-[5vw] text-[14px] lg:text-[18px] xl:text-[1.2vw] flex font-normal font-Helvetica text-white"
                >
                    <div className="w-6 lg:w-[60px] 2xl:w-[299px] 3xl:w-[15vw] flex-none"></div>
                    <div className="px-0 pr-6 sm:px-6 lg:px-0 lg:pr-10 2xl:pr-[3.5vw]">
                        <div className="pb-5">
                            Our team collaborated with NetClean to develop the creative concept and design for the Insight Report, transforming it into a tool optimized for social media. Based on data from over 500 senior IT professionals in the UK, Sweden, Belgium, and the Netherlands, the report highlights unique challenges in addressing CSAM and reflects responses from C-suite executives to managers on the evolving risk landscape.
                        </div>
                        <div className="pb-5">
                            We developed a campaign that distilled key findings into engaging visuals—infographics, illustrations, and animations—making complex information easy to share. Our aim was to go beyond static reporting, sparking an interactive dialogue with stakeholders and expanding the report’s reach.
                        </div>
                        <div>
                            This multi-platform approach sparked discussions and boosted awareness, establishing NetClean as a proactive voice on CSAM. By making findings accessible, we drove engagement, reinforcing NetClean’s strategy to reach key audiences effectively.
                        </div>
                    </div>
                </div>
                {/* Image Section 1 */}
                <div className="pb-0 md:pb-8 2xl:pb-16">
                    <img src="/assets/img/netclean/netclean_1.jpg" className="w-full" alt="Netclean Image 1" />
                </div>
                {/* Image Section 2 */}
                <div className="py-8 md:py-0">
                    <img src="/assets/img/netclean/netclean_2.jpg" className="w-full" alt="Netclean Image 2" />
                </div>
                {/* Image Section 3 */}
                <div className="flex">
                    <div className="hidden lg:block lg:w-[299px] flex-none"></div>
                    <div className="w-full">
                        <img src="/assets/img/netclean/netclean_3.png" className="w-full" alt="Netclean Image 3" />
                    </div>
                </div>

                {/* Video Section 2 */}
                <div className="py-8 2xl:py-16 flex">
                    <div className="hidden lg:block lg:w-[299px] flex-none border-b-[1px]"></div>
                    <div className="w-full flex flex-col lg:flex-row">
                        <video
                            className="w-full lg:w-1/2 h-auto object-contain mb-8 lg:mb-0 3xl:max-h-[45vw]"
                            autoPlay
                            loop
                            muted
                            playsInline
                            onContextMenu={(e) => e.preventDefault()}
                        >
                            <source src="https://vtwrol5ehwjzsuap.public.blob.vercel-storage.com/videos/NetClean_movie_2-iec0y1t3wZrjxRN8B2wJMmOzszS15F.mp4" type="video/mp4" />
                        </video>
                        <video
                            className="w-auto lg:w-1/2 h-auto object-contain lg:pt-0 3xl:max-h-[50vw]"
                            autoPlay
                            loop
                            muted
                            playsInline
                            onContextMenu={(e) => e.preventDefault()}
                        >
                            <source src="https://vtwrol5ehwjzsuap.public.blob.vercel-storage.com/videos/NetClean_movie_3-VKNsJale7iaIR0F7R9GRMvSk0tYXem.mp4" type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>


                {/* Image Section 4 */}
                <div className="pb-8 2xl:pb-16 3xl:pb-[6vw] flex">
                    <img src="/assets/img/netclean/netclean_4.png" className="w-full" alt="Netclean Image 4" />
                </div>
                {/* Image Section 5 */}
                <div className="pb-8 2xl:pb-16 3xl:pb-[6vw] flex">
                    <div className="hidden lg:block lg:w-[299px] flex-none"></div>
                    <div className="w-full">
                        <img src="/assets/img/netclean/netclean_5.png" className="w-full" alt="Netclean Image 5" />
                    </div>
                </div>

                {/* Two Images with Hover Effect */}
                <div className="w-full border-t border-white pt-0">
                    <div className="flex text-white font-Helvetica">
                        <div className="grid grid-cols-2 w-full min-h-[300px] md:min-h-[400px]">
                            {/* First Image Container */}
                            <div className="overflow-hidden border-r border-white relative group">
                                <Link to="/ourWork/uniper">
                                    <div
                                        className="cursor-pointer py-[26px] 2xl:py-[100px] 
                                   3xl:py-[6vw] 5xl:py-[10vw] 
                                   px-[15px] md:px-10 3xl:px-[2vw] 5xl:px-[3vw] 
                                   flex flex-col h-full relative"
                                    >
                                        {/* Background image using pseudo-element */}
                                        <div className="absolute inset-0 bg-cover bg-center transition-transform duration-300 ease-in-out 
                                        bg-[url('assets/img/newwaters/Uniper_new_img.jpg')] 
                                        md:bg-[url('assets/img/mars/uniper_2.png')] 
                                        group-hover:scale-110 
                                        before:content-[''] before:absolute before:inset-0 
                                        before:bg-cover before:bg-center before:bg-no-repeat 
                                        before:transition-transform before:duration-300 
                                        before:ease-in-out before:scale-100 group-hover:before:scale-110" />

                                        {/* Content overlay */}
                                        <div className="relative z-10 flex flex-col justify-between h-full">
                                            {/* Title and Description */}
                                            <div>
                                                <div className="font-bold text-[15px] lg:text-base xl:text-2xl 
                                                3xl:text-[1.5vw] 5xl:text-[1vw]">
                                                    Uniper
                                                </div>
                                                <div className="flex-1">
                                                    <div
                                                        className="max-w-[440px] 3xl:max-w-[20vw] 
                                                   py-[26px] lg:py-[43px] 
                                                   xl:py-[67px] 3xl:py-[4vw] 
                                                   font-normal text-[12px] lg:text-[21px] 
                                                   xl:text-[32px] 2xl:text-[40px] 
                                                   3xl:text-[1.3vw] 5xl:text-[1.2vw] 
                                                   leading-tight 5xl:py-[5vw]"
                                                    >
                                                        Shaping Uniper's campaign to position them as the heartbeat of an evolving energy landscape.
                                                    </div>
                                                </div>
                                            </div>
                                            {/* "Read More" Link */}
                                            <div className="flex py-8 items-center pl-4 
                                            3xl:py-[2vw] 5xl:py-[2.5vw]">
                                                <div
                                                    className="uppercase font-normal text-[13px] 
                                               lg:text-xs xl:text-lg 
                                               3xl:text-[1.1vw] 5xl:text-[1.2vw]"
                                                >
                                                    read more
                                                </div>
                                                <div>
                                                    <svg
                                                        className="ml-3 pt-[2px] w-6 h-6 
                                               3xl:w-[1.5vw] 3xl:h-[1.5vw] 
                                               5xl:w-[1vw] 5xl:h-[1vw]"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <g id="arrow_outward">
                                                            <path
                                                                id="Vector"
                                                                d="M6.5 5.5V7.5H15.09L5.5 17.09L6.91 18.5L16.5 8.91V17.5H18.5V5.5H6.5Z"
                                                                fill="white"
                                                            />
                                                        </g>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            {/* Second Image Container */}
                            <div className="overflow-hidden border-l border-white relative group">
                                <Link to="/ourWork/avolta">
                                    <div
                                        className="cursor-pointer py-[26px] 2xl:py-[100px] 
                                   3xl:py-[6vw] 5xl:py-[10vw] 
                                   px-[15px] md:px-10 3xl:px-[2vw] 5xl:px-[3vw] 
                                   flex flex-col h-full relative"
                                    >
                                        {/* Background image using pseudo-element */}
                                        <div className="absolute inset-0 bg-cover bg-center transition-transform duration-300 ease-in-out 
                                        bg-[url('assets/img/avolta/06_footer_Avolta.jpg')] 
                                        group-hover:scale-110 
                                        before:content-[''] before:absolute before:inset-0 
                                        before:bg-cover before:bg-center before:bg-no-repeat 
                                        before:transition-transform before:duration-300 
                                        before:ease-in-out before:scale-100 group-hover:before:scale-110" />

                                        {/* Content overlay */}
                                        <div className="relative z-10 flex flex-col justify-between h-full">
                                            {/* Title and Description */}
                                            <div>
                                                <div className="font-bold text-[15px] lg:text-base xl:text-2xl 
                                                3xl:text-[1.5vw] 5xl:text-[1vw]">
                                                    Avolta
                                                </div>
                                                <div className="flex-1">
                                                    <div className="max-w-[440px] 3xl:max-w-[20vw] py-[26px] lg:py-[43px] xl:py-[67px] 3xl:py-[4vw] font-normal text-[12px] lg:text-[21px] xl:text-[32px] 2xl:text-[40px] 3xl:text-[1.3vw] leading-tight">
                                                        Enhancing Avolta’s LinkedIn presence with a creative strategy that transforms data into dynamic, interactive narratives.
                                                    </div>
                                                </div>
                                            </div>
                                            {/* "Read More" Link */}
                                            <div className="flex py-8 items-center pl-4 
                                            3xl:py-[2vw] 5xl:py-[2.5vw]">
                                                <div
                                                    className="uppercase font-normal text-[13px] 
                                               lg:text-xs xl:text-lg 
                                               3xl:text-[1.1vw] 5xl:text-[1.2vw]"
                                                >
                                                    read more
                                                </div>
                                                <div>
                                                    <svg
                                                        className="ml-3 pt-[2px] w-6 h-6 
                                               3xl:w-[1.5vw] 3xl:h-[1.5vw] 
                                               5xl:w-[1vw] 5xl:h-[1vw]"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <g id="arrow_outward">
                                                            <path
                                                                id="Vector"
                                                                d="M6.5 5.5V7.5H15.09L5.5 17.09L6.91 18.5L16.5 8.91V17.5H18.5V5.5H6.5Z"
                                                                fill="white"
                                                            />
                                                        </g>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            {/* Footer and MainBorder Components */}
            <Footer />
            <MainBorder />
        </div>
    );
};

export default Netclean;
