/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect } from "react";
import { Footer, MainBorder } from "../components";
import Navbar from "./Navbar";
import { Link } from "react-router-dom";

const Avolta = ({ isSticky, element }) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="relative">
            {/* Navbar */}
            <Navbar sticky={isSticky} />

            {/* Welcome Section with Images */}
            <div className="relative text-white welcome" ref={element}>
                {/* Desktop Image */}
                <img
                    src="/assets/img/avolta/01_header_Avolta.jpg"
                    alt="Avolta Desktop"
                    className="w-full hidden md:block object-cover min-h-[850px]"
                />
                {/* Mobile Image */}
                <img
                    src="/assets/img/avolta/01_header_Avolta.jpg" // @kekstEduardoNicoleit Add a mobile image
                    alt="Avolta Mobile"
                    className="w-full h-full object-cover min-h-[615px] block md:hidden"
                />
                {/* Overlay Content */}
                <div className="w-full absolute bottom-0">
                    <div className="flex flex-col px-2 lg:px-4 xl:px-[25px] 2xl:px-[45px]">
                        {/* Title */}
                        <div className="flex">
                            <div className="w-6 lg:w-[60px] 2xl:w-[299px] 3xl:w-[15vw] flex-none"></div>
                            <div>
                                <div
                                    className="font-Helvetica font-bold mt-auto
                                    text-[50px] leading-[34px] md:text-[50px] md:leading-[57px]
                                    lg:text-[70px]
                                    xl:text-[80px]
                                    lg:leading-[80px] xl:leading-[184px]
                                    py-14 sm:py-6 md:py-10 lg:py-10 xl:py-5 2xl:py-16 
                                    3xl:py-[6vw] 5xl:py-[8vw]
                                    3xl:text-[6.5vw] "
                                >
                                    Avolta
                                </div>
                            </div>
                        </div>
                        {/* Desktop Overview: */}
                        <div className="hidden lg:block">
                            <div
                                className="lg:flex-col 2xl:flex-row flex border-white border-y-[1px] sm:text-4xl md:text-3xl lg:text-[22px] 3xl:text-[1.2vw] 5xl:text-[1vw] lg:h-[37vh] xl:h-[30vh] 2xl:h-[30vh] 3xl:h-[25vh] items-start 2xl:items-center"
                            >
                                <div
                                    className="font-normal lg:pl-[60px] lg:pr-0 lg:pb-4 lg:pt-4 xl:pt-4 xl:pb-4 2xl:pt-0 2xl:pb-[2.2vw] w-[300px] lg:w-[200px] 2xl:w-[308px] 3xl:w-[14vw] mb-0 3xl:pl-[2vw] 3xl:pr-[2vw] 2xl:px-[2vw]"
                                >
                                    Overview:
                                </div>
                                <div className="font-normal lg:grid grid-cols-3 w-full items-start 2xl:items-center">
                                    <div className="font-normal pl-[60px] 2xl:pl-8 pr-4 xl:border-l-0 2xl:border-l-[1px] 
                                                leading-tight h-full 
                                                3xl:pl-[2.15vw] 3xl:pr-[1vw] 5xl:pl-[2.5vw] 5xl:pr-[1.2vw]">
                                        Developed a visual concept and creative strategy for Avolta’s social media communication.
                                    </div>
                                    <div className="font-normal pl-8 pr-4 border-l-[1px] leading-tight h-full 
                                                3xl:pl-[2vw] 3xl:pr-[2vw] 5xl:pl-[3vw] 5xl:pr-[2vw]">
                                        Combined data visualization and illustration for a cohesive LinkedIn format.
                                    </div>
                                    <div className="font-normal pl-8 pr-4 border-l-[1px] leading-tight h-full 
                                                3xl:pl-[2vw] 2xl:pr-[3vw] 5xl:pl-[3vw] 5xl:pr-[2vw]">
                                        Positioned Avolta’s updates as innovative, reinforcing credibility among industry stakeholders.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="px-2 lg:px-4 xl:px-[25px] 2xl:px-[45px]">
                {/* Mobile Overview: */}
                <div className="block lg:hidden font-Helvetica font-normal py-[41px] text-white 
                                pl-6 3xl:pl-[4vw] 5xl:pl-[6vw]">
                    <div className="text-[13px] leading-[14px] uppercase 3xl:text-[1vw] 5xl:text-[1.2vw]">Overview:</div>
                    <div className="text-[13px] leading-[15px] py-3 border-b-[1px] 
                                3xl:text-[1vw] 5xl:text-[1.2vw]">
                        Developed a visual concept and creative strategy for Avolta’s social media communication.
                    </div>
                    <div className="text-[13px] leading-[15px] py-3 border-b-[1px] 
                                3xl:text-[1vw] 5xl:text-[1.2vw]">
                        Combined data visualization and illustration for a cohesive LinkedIn format.
                    </div>
                    <div className="text-[13px] leading-[15px] py-3 border-b-[1px] 
                                3xl:text-[1vw] 5xl:text-[1.2vw]">
                        Positioned Avolta’s updates as innovative, reinforcing credibility among industry stakeholders.
                    </div>
                </div>

                {/* Partnership Section */}
                <div
                    className="flex flex-col 2xl:flex-row md:pt-[10px] lg:pt-10 xl:pt-[156px] 2xl:pt-[211px] 
                    pb-10 lg:pb-20 xl:pb-[106px] 2xl:pb-[134px] w-full font-Helvetica text-white"
                >
                    <div
                        className="uppercase xl:normal-case text-start 2xl:text-left font-normal text-[13px] md:text-lg 2xl:text-2xl 3xl:text-[1.2vw] pt-3 xl:w-full 2xl:w-[308px] 3xl:w-[15vw] flex-none px-6 lg:px-[60px] 2xl:px-[2vw] py-6 2xl:py-0.2"
                    >
                        The partnership:
                    </div>
                    <div
                        className="font-bold md:font-normal text-[18px] md:text-[28px] 2xl:text-[40px] 3xl:text-[1.8vw] px-6 lg:px-[60px] 2xl:px-[0] 2xl:pr-[3.5vw] w-full leading-tight"
                    >
                        In collaboration with Avolta, we crafted a visual concept and creative strategy tailored for their social media communication, specifically designed to elevate the presentation of their results. By incorporating dynamic data visualization, striking illustrations and clear messaging, the strategy ensured that complex business updates were both accessible and visually engaging. This approach aligned seamlessly with Avolta’s innovative identity, enabling their updates to resonate with a professional audience on LinkedIn.
                    </div>
                </div>

                {/* Video Section 1 */}
                <div>
                    <video
                        className="w-full h-full object-cover cursor-pointer max-h-[95vh]"
                        autoPlay
                        loop
                        muted
                        playsInline
                        onContextMenu={(e) => e.preventDefault()}
                        preload="auto"
                    >
                        <source src="https://vtwrol5ehwjzsuap.public.blob.vercel-storage.com/avolta-1bwpdBMYaCyihYI0wQYNws7AkOFw4n.mp4" type="video/mp4" />
                    </video>
                </div>

                {/* Description Section */}
                <div
                    className="py-10 2xl:py-[5vw] text-[14px] lg:text-[18px] xl:text-[1.2vw] flex font-normal font-Helvetica text-white"
                >
                    <div className="w-6 lg:w-[60px] 2xl:w-[299px] 3xl:w-[15vw] flex-none"></div>
                    <div className="px-0 pr-6 sm:px-6 lg:px-0 lg:pr-10 2xl:pr-[3.5vw]">
                        <div className="pb-5">
                            The visual concept and creative strategy for Avolta’s social media communication redefined how results were shared, transforming technical data into a visually compelling and interactive experience. Designed primarily for LinkedIn, the concept integrated data visualization, illustration and content strategy to present updates in a format that was both appealing and easy to understand, encouraging audience interaction and sharing.
                        </div>
                        <div className="">
                            The main goal of this approach was to help Avolta strengthen its connection with stakeholders and industry experts, while focusing on positioning the company as a forward-thinking leader in travel retail and food & beverage. The project highlighted Avolta’s strategic emphasis on innovation and excellence, reflecting their global presence across 73 countries, 5,100 points of sale and a diverse portfolio spanning duty-free, food & beverage and convenience. By aligning with their vision of placing the traveler at the core of their strategy, the visual concept brought Avolta’s updates to life in a way that enhanced their credibility and engagement within the professional network.
                        </div>
                    </div>
                </div>

                {/* Image Section 2 */}
                <div className="flex">
                    <div className="w-full">
                        <img src="/assets/img/avolta/02_Avolta.jpg" className="w-full" alt="Avolta Image 2" />
                    </div>
                </div>

                {/* Image Section 3 */}
                <div className="py-8 2xl:py-16 3xl:py-[6vw] flex">
                    <div className="hidden lg:block flex-none"></div>
                    <div className="w-full">
                        <img src="/assets/img/avolta/03_Avolta.jpg" className="w-full" alt="avolta Image 3" />
                    </div>
                </div>

                {/* Image Section 4 */}
                <div className="flex">
                    <div className="hidden lg:block flex-none"></div>
                    <div className="w-full">
                        <img src="/assets/img/avolta/04_Avolta.jpg" className="w-full" alt="avolta Image 4" />
                    </div>
                </div>

                {/* Image Section 5 */}
                <div className="pb-8 2xl:pb-16 3xl:pb-[6vw] flex">
                    <div className="hidden lg:block w-6 lg:w-[60px] 2xl:w-[299px] 3xl:w-[15vw] flex-none"></div>
                    <div className="hidden lg:block flex-none"></div>
                    <div className="w-full">
                        <img src="/assets/img/avolta/05_Avolta.jpg" className="w-full" alt="avolta Image 5" />
                    </div>
                </div>

                {/* Two Images with Hover Effect */}
                <div className="w-full border-t border-white pt-0">
                    <div className="flex text-white font-Helvetica">
                        <div className="grid grid-cols-2 w-full min-h-[300px] md:min-h-[400px]">
                            {/* First Image Container */}
                            <div className="overflow-hidden md:border-r md:border-white relative group">
                                <Link to="/ourWork/netClean">
                                    <div
                                        className="cursor-pointer py-[26px] 2xl:py-[100px] 
                                   3xl:py-[6vw] 5xl:py-[10vw] 
                                   px-[15px] md:px-10 3xl:px-[2vw] 5xl:px-[3vw] 
                                   flex flex-col h-full relative"
                                    >
                                        {/* Background image using pseudo-element */}
                                        <div className="absolute inset-0 bg-cover bg-center transition-transform duration-300 ease-in-out 
                                        bg-[url('assets/img/volkswagen/netclean_3.png')] 
                                        md:bg-[url('assets/img/volkswagen/netclean_2.png')] 
                                        group-hover:scale-110 
                                        before:content-[''] before:absolute before:inset-0 
                                        before:bg-cover before:bg-center before:bg-no-repeat 
                                        before:transition-transform before:duration-300 
                                        before:ease-in-out before:scale-100 group-hover:before:scale-110" />

                                        {/* Content overlay */}
                                        <div className="relative z-10 flex flex-col justify-between h-full">
                                            {/* Title and Description */}
                                            <div>
                                                <div className="font-bold text-[15px] lg:text-base xl:text-2xl 
                                                3xl:text-[1.5vw] 5xl:text-[1vw]">
                                                    NetClean
                                                </div>
                                                <div className="flex-1">
                                                    <div
                                                        className="max-w-[440px] 3xl:max-w-[20vw] 
                                                   py-[26px] lg:py-[43px] 
                                                   xl:py-[67px] 3xl:py-[4vw] 
                                                   font-normal text-[12px] lg:text-[21px] 
                                                   xl:text-[32px] 2xl:text-[40px] 
                                                   3xl:text-[1.3vw] 5xl:text-[1.2vw] 
                                                   leading-tight 5xl:py-[5vw]"
                                                    >
                                                        Redefining how insight reports become impactful narrative tools for sensitive and critical topics.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex py-8 items-center">
                                                <div className="uppercase font-normal text-[13px] lg:text-xs xl:text-lg 3xl:text-[1.1vw]">
                                                    read more
                                                </div>
                                                <div>
                                                    <svg
                                                        className="ml-3 pt-[2px] w-6 h-6 3xl:w-[1.5vw] 3xl:h-[1.5vw] 5xl:w-[1vw] 5xl:h-[1vw]"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <g id="arrow_outward">
                                                            <path
                                                                id="Vector"
                                                                d="M6.5 5.5V7.5H15.09L5.5 17.09L6.91 18.5L16.5 8.91V17.5H18.5V5.5H6.5Z"
                                                                fill="white"
                                                            />
                                                        </g>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            {/* Second Image Container */}
                            <div className="overflow-hidden relative group">
                                <Link to="/ourWork/skwp">
                                    <div className="cursor-pointer py-[26px] 2xl:py-[100px] 3xl:py-[6vw] px-[15px] md:px-10 3xl:px-[2vw] flex flex-col h-full relative">
                                        {/* Background image using pseudo-element */}
                                        <div className="absolute inset-0 bg-cover bg-center transition-transform duration-300 ease-in-out bg-[url('assets/img/skwp/07_footer_skwp.jpg')] group-hover:scale-110 before:content-[''] before:absolute before:inset-0 before:bg-cover before:bg-center before:bg-no-repeat before:transition-transform before:duration-300 before:ease-in-out before:scale-100 group-hover:before:scale-110" />

                                        {/* Content overlay */}
                                        <div className="relative z-10 flex flex-col justify-between h-full">
                                            <div>
                                                <div className="font-bold text-[15px] lg:text-base xl:text-2xl 3xl:text-[1.5vw]">SKWP</div>
                                                <div className="flex-1">
                                                    <div className="max-w-[440px] 3xl:max-w-[20vw] py-[26px] lg:py-[43px] xl:py-[67px] 3xl:py-[4vw] font-normal text-[12px] lg:text-[21px] xl:text-[32px] 2xl:text-[40px] 3xl:text-[1.3vw] leading-tight">
                                                        Showcasing SKWP’s leadership in chemical innovation with a campaign that simplifies complexity and amplifies societal impact.
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Read More Section */}
                                            <div className="flex py-8 items-center pl-4 
                                            3xl:py-[2vw] 5xl:py-[2.5vw]">
                                                <div className="uppercase font-normal text-[13px] lg:text-xs xl:text-lg 
                                                3xl:text-[1.1vw] 5xl:text-[1.2vw]">
                                                    read more
                                                </div>
                                                <div>
                                                    <svg
                                                        className="ml-3 pt-[2px] w-6 h-6 
                                               3xl:w-[1.5vw] 5xl:w-[1vw] 
                                               3xl:h-[1.5vw] 5xl:h-[1vw]"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <g id="arrow_outward">
                                                            <path
                                                                id="Vector"
                                                                d="M6.5 5.5V7.5H15.09L5.5 17.09L6.91 18.5L16.5 8.91V17.5H18.5V5.5H6.5Z"
                                                                fill="white"
                                                            />
                                                        </g>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            {/* Footer and MainBorder Components */}
            <Footer />
            <MainBorder />
        </div >
    );
};

export default Avolta;
