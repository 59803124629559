// Map.jsx
import React from 'react';

const Map = () => {
    return (
        <div
            className="embedded-svg"
            style={{ position: "relative", zIndex: 1 }}
            dangerouslySetInnerHTML={{
                __html: `
<?xml version="1.0" encoding="utf-8"?>
<!-- Generator: Adobe Illustrator 27.3.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 1069.5 654.7" style="enable-background:new 0 0 1069.5 654.7;" xml:space="preserve">
<style type="text/css">
	.st0{fill:#5A198F;}
	.st1{fill:#FBFFFF;}
	.st2{fill:#FFFFFA;}
	.st3{fill:#FFFFFD;}
	.st4{fill:#FDFFFF;}
	.st5{fill:#F9FFFF;}
	.st6{fill:#FEFFFF;}
	.st7{fill:#FAFFFF;}
	.st8{fill:#FFFEFF;}
	.st9{fill:#FFFFFB;}
	.st10{fill:#FFFDFF;}
	.st11{fill:#FFFFFE;}
	.st12{fill:#FCFFFF;}
	.st13{fill:#FFFFFC;}
	.st14{fill:#FFFBFF;}
</style>
<g>
	<path class="st0" d="M685.2,120.7c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6
		C684,123.3,685.2,122.2,685.2,120.7z"/>
	<path class="st0" d="M672.7,152.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C670.1,151.7,671.3,152.9,672.7,152.9z"/>
	<path class="st0" d="M692.4,118.1c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S693.9,118.1,692.4,118.1z"/>
	<path class="st0" d="M682.6,295.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C685.2,296.4,684,295.2,682.6,295.2z"/>
	<path class="st0" d="M672.7,143c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C670.1,141.8,671.3,143,672.7,143z"/>
	<path class="st0" d="M670.1,130.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6
		C671.3,127.9,670.1,129.1,670.1,130.6z"/>
	<path class="st0" d="M692.4,196.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C695.1,198,693.9,196.8,692.4,196.8z"/>
	<path class="st0" d="M692.4,187c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6C695.1,188.1,693.9,187,692.4,187z"
		/>
	<path class="st0" d="M692.4,177.1c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C695.1,178.3,693.9,177.1,692.4,177.1z"/>
	<path class="st0" d="M692.4,127.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C695.1,129.1,693.9,127.9,692.4,127.9z"/>
	<path class="st0" d="M692.4,216.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C695.1,217.7,693.9,216.5,692.4,216.5z"/>
	<path class="st0" d="M692.4,206.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C695.1,207.8,693.9,206.6,692.4,206.6z"/>
	<path class="st0" d="M692.4,167.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C695.1,168.5,693.9,167.3,692.4,167.3z"/>
	<path class="st0" d="M692.4,147.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C695.1,148.8,693.9,147.6,692.4,147.6z"/>
	<path class="st0" d="M692.4,137.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C695.1,138.9,693.9,137.8,692.4,137.8z"/>
	<path class="st0" d="M692.4,157.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S693.9,157.4,692.4,157.4z"/>
	<path class="st0" d="M672.7,211.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C670.1,210.7,671.3,211.9,672.7,211.9z"/>
	<circle class="st0" cx="672.7" cy="307.7" r="2.6"/>
	<circle class="st0" cx="672.7" cy="268.3" r="2.6"/>
	<path class="st0" d="M672.7,280.8c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C670.1,279.6,671.3,280.8,672.7,280.8z"/>
	<path class="st0" d="M672.7,290.6c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C670.1,289.4,671.3,290.6,672.7,290.6z"/>
	<path class="st0" d="M672.7,320.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C670.1,319,671.3,320.1,672.7,320.1z"/>
	<path class="st0" d="M672.7,300.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C670.1,299.3,671.3,300.5,672.7,300.5z"/>
	<path class="st0" d="M685.2,337.2c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6
		C684,339.8,685.2,338.6,685.2,337.2z"/>
	<path class="st0" d="M682.6,314.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C685.2,316.1,684,314.9,682.6,314.9z"/>
	<circle class="st0" cx="682.6" cy="307.7" r="2.6"/>
	<path class="st0" d="M682.6,324.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C685.2,325.9,684,324.7,682.6,324.7z"/>
	<path class="st0" d="M672.7,172.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C670.1,171.4,671.3,172.5,672.7,172.5z"/>
	<circle class="st0" cx="672.7" cy="199.4" r="2.6"/>
	<path class="st0" d="M692.4,226.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C695.1,227.5,693.9,226.3,692.4,226.3z"/>
	<path class="st0" d="M672.7,192.2c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C670.1,191,671.3,192.2,672.7,192.2z"/>
	<circle class="st0" cx="672.7" cy="179.8" r="2.6"/>
	<circle class="st0" cx="672.7" cy="160.1" r="2.6"/>
	<path class="st0" d="M672.7,251.3c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C670.1,250.1,671.3,251.3,672.7,251.3z"/>
	<path class="st0" d="M672.7,261.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C670.1,259.9,671.3,261.1,672.7,261.1z"/>
	<circle class="st0" cx="672.7" cy="238.8" r="2.6"/>
	<path class="st0" d="M672.7,221.7c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C670.1,220.6,671.3,221.7,672.7,221.7z"/>
	<path class="st0" d="M672.7,231.6c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C670.1,230.4,671.3,231.6,672.7,231.6z"/>
	<path class="st0" d="M692.4,275.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C695.1,276.7,693.9,275.5,692.4,275.5z"/>
	<path class="st0" d="M722,236.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C724.6,237.3,723.4,236.2,722,236.2z"/>
	<path class="st0" d="M722,206.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S723.4,206.6,722,206.6z"/>
	<path class="st0" d="M722,216.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S723.4,216.5,722,216.5z"/>
	<path class="st0" d="M722,187c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6C724.6,188.1,723.4,187,722,187z
		"/>
	<path class="st0" d="M722,226.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S723.4,226.3,722,226.3z"/>
	<path class="st0" d="M722,196.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S723.4,196.8,722,196.8z"/>
	<path class="st0" d="M722,147.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S723.4,147.6,722,147.6z"/>
	<path class="st0" d="M722,157.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S723.4,157.4,722,157.4z"/>
	<path class="st0" d="M722,167.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S723.4,167.3,722,167.3z"/>
	<path class="st0" d="M722,177.1c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S723.4,177.1,722,177.1z"/>
	<path class="st0" d="M722,255.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S723.4,255.8,722,255.8z"/>
	<path class="st0" d="M722,295.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C724.6,296.4,723.4,295.2,722,295.2z"/>
	<path class="st0" d="M722,305c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S723.4,305,722,305z"/>
	<path class="st0" d="M722,314.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S723.4,314.9,722,314.9z"/>
	<path class="st0" d="M722,344.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S723.4,344.4,722,344.4z"/>
	<path class="st0" d="M712.1,344.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C714.8,345.6,713.6,344.4,712.1,344.4z"/>
	<path class="st0" d="M722,324.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C724.6,325.9,723.4,324.7,722,324.7z"/>
	<path class="st0" d="M722,246c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S723.4,246,722,246z"/>
	<path class="st0" d="M722,334.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C724.6,335.7,723.4,334.6,722,334.6z"/>
	<path class="st0" d="M722,265.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C724.6,266.9,723.4,265.7,722,265.7z"/>
	<path class="st0" d="M722,275.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S723.4,275.5,722,275.5z"/>
	<path class="st0" d="M692.4,236.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C695.1,237.3,693.9,236.2,692.4,236.2z"/>
	<path class="st0" d="M722,285.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C724.6,286.5,723.4,285.4,722,285.4z"/>
	<path class="st0" d="M692.4,305c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6C695.1,306.2,693.9,305,692.4,305z"
		/>
	<path class="st0" d="M692.4,314.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C695.1,316.1,693.9,314.9,692.4,314.9z"/>
	<path class="st0" d="M692.4,324.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C695.1,325.9,693.9,324.7,692.4,324.7z"/>
	<path class="st0" d="M692.4,334.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C695.1,335.7,693.9,334.6,692.4,334.6z"/>
	<path class="st0" d="M692.4,295.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C695.1,296.4,693.9,295.2,692.4,295.2z"/>
	<path class="st0" d="M692.4,255.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C695.1,257,693.9,255.8,692.4,255.8z"/>
	<path class="st0" d="M692.4,246c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6C695.1,247.2,693.9,246,692.4,246z"
		/>
	<path class="st0" d="M692.4,285.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C695.1,286.5,693.9,285.4,692.4,285.4z"/>
	<path class="st0" d="M692.4,265.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C695.1,266.9,693.9,265.7,692.4,265.7z"/>
	<path class="st0" d="M722,59c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S723.4,59,722,59z"/>
	<path class="st0" d="M722,49.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S723.4,49.2,722,49.2z"/>
	<path class="st0" d="M722,39.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C724.6,40.5,723.4,39.4,722,39.4z"/>
	<path class="st0" d="M830.4,39.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C833,40.5,831.8,39.4,830.4,39.4z"/>
	<path class="st0" d="M830.4,29.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S831.8,29.5,830.4,29.5z"/>
	<path class="st0" d="M712.1,59c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S713.6,59,712.1,59z"/>
	<path class="st0" d="M722,98.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S723.4,98.4,722,98.4z"/>
	<path class="st0" d="M722,88.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C724.6,89.7,723.4,88.6,722,88.6z"/>
	<path class="st0" d="M722,108.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C724.6,109.4,723.4,108.2,722,108.2z"/>
	<path class="st0" d="M722,118.1c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S723.4,118.1,722,118.1z"/>
	<path class="st0" d="M722,78.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C724.6,79.9,723.4,78.7,722,78.7z"/>
	<path class="st0" d="M722,127.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C724.6,129.1,723.4,127.9,722,127.9z"/>
	<path class="st0" d="M722,68.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S723.4,68.9,722,68.9z"/>
	<path class="st0" d="M722,137.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C724.6,138.9,723.4,137.8,722,137.8z"/>
	<path class="st0" d="M712.1,236.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C714.8,237.3,713.6,236.2,712.1,236.2z"/>
	<path class="st0" d="M712.1,187c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C714.8,188.1,713.6,187,712.1,187z"/>
	<path class="st0" d="M712.1,285.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C714.8,286.5,713.6,285.4,712.1,285.4z"/>
	<path class="st0" d="M712.1,275.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C714.8,276.7,713.6,275.5,712.1,275.5z"/>
	<path class="st0" d="M712.1,265.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C714.8,266.9,713.6,265.7,712.1,265.7z"/>
	<path class="st0" d="M712.1,246c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C714.8,247.2,713.6,246,712.1,246z"/>
	<path class="st0" d="M712.1,206.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C714.8,207.8,713.6,206.6,712.1,206.6z"/>
	<path class="st0" d="M712.1,196.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C714.8,198,713.6,196.8,712.1,196.8z"/>
	<path class="st0" d="M712.1,226.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C714.8,227.5,713.6,226.3,712.1,226.3z"/>
	<path class="st0" d="M712.1,216.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C714.8,217.7,713.6,216.5,712.1,216.5z"/>
	<path class="st0" d="M712.1,255.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C714.8,257,713.6,255.8,712.1,255.8z"/>
	<path class="st0" d="M702.3,78.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C704.9,79.9,703.7,78.7,702.3,78.7z"/>
	<path class="st0" d="M702.3,88.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C704.9,89.7,703.7,88.6,702.3,88.6z"/>
	<path class="st0" d="M702.3,108.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C704.9,109.4,703.7,108.2,702.3,108.2z"/>
	<path class="st0" d="M702.3,68.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C704.9,70.1,703.7,68.9,702.3,68.9z"/>
	<path class="st0" d="M702.3,98.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S703.7,98.4,702.3,98.4z"/>
	<path class="st0" d="M682.6,98.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S684,98.4,682.6,98.4z"/>
	<path class="st0" d="M662.9,98.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S664.3,98.4,662.9,98.4z"/>
	<path class="st0" d="M593.9,39.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C596.5,40.5,595.3,39.4,593.9,39.4z"/>
	<path class="st0" d="M603.7,39.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C606.4,40.5,605.2,39.4,603.7,39.4z"/>
	<path class="st0" d="M603.7,29.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C606.4,30.7,605.2,29.5,603.7,29.5z"/>
	<path class="st0" d="M593.9,29.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S595.3,29.5,593.9,29.5z"/>
	<path class="st0" d="M584,39.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C586.7,40.5,585.5,39.4,584,39.4z"/>
	<path class="st0" d="M712.1,314.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C714.8,316.1,713.6,314.9,712.1,314.9z"/>
	<path class="st0" d="M712.1,324.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C714.8,325.9,713.6,324.7,712.1,324.7z"/>
	<path class="st0" d="M712.1,305c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C714.8,306.2,713.6,305,712.1,305z"/>
	<path class="st0" d="M712.1,295.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C714.8,296.4,713.6,295.2,712.1,295.2z"/>
	<path class="st0" d="M712.1,334.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C714.8,335.7,713.6,334.6,712.1,334.6z"/>
	<path class="st0" d="M672.7,330c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C670.1,328.8,671.3,330,672.7,330z"/>
	<path class="st0" d="M682.6,19.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C685.2,20.9,684,19.7,682.6,19.7z"/>
	<path class="st0" d="M712.1,68.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C714.8,70.1,713.6,68.9,712.1,68.9z"/>
	<path class="st0" d="M712.1,29.5c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S713.6,29.5,712.1,29.5z"/>
	<path class="st0" d="M702.3,29.5c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S703.7,29.5,702.3,29.5z"/>
	<path class="st0" d="M692.4,29.5c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S693.9,29.5,692.4,29.5z"/>
	<path class="st0" d="M692.4,19.7c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S693.9,19.7,692.4,19.7z"/>
	<path class="st0" d="M682.6,9.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S684,9.8,682.6,9.8z"/>
	<path class="st0" d="M682.6,285.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C685.2,286.5,684,285.4,682.6,285.4z"/>
	<path class="st0" d="M702.3,118.1c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S703.7,118.1,702.3,118.1z"/>
	<path class="st0" d="M712.1,127.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C714.8,129.1,713.6,127.9,712.1,127.9z"/>
	<path class="st0" d="M712.1,137.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C714.8,138.9,713.6,137.8,712.1,137.8z"/>
	<path class="st0" d="M712.1,147.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C714.8,148.8,713.6,147.6,712.1,147.6z"/>
	<path class="st0" d="M712.1,167.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C714.8,168.5,713.6,167.3,712.1,167.3z"/>
	<path class="st0" d="M712.1,157.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S713.6,157.4,712.1,157.4z"/>
	<path class="st0" d="M712.1,177.1c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C714.8,178.3,713.6,177.1,712.1,177.1z"/>
	<path class="st0" d="M712.1,88.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C714.8,89.7,713.6,88.6,712.1,88.6z"/>
	<path class="st0" d="M712.1,118.1c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S713.6,118.1,712.1,118.1z"/>
	<path class="st0" d="M712.1,98.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S713.6,98.4,712.1,98.4z"/>
	<path class="st0" d="M712.1,78.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C714.8,79.9,713.6,78.7,712.1,78.7z"/>
	<path class="st0" d="M712.1,108.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C714.8,109.4,713.6,108.2,712.1,108.2z"/>
	<path class="st0" d="M682.6,147.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C685.2,148.8,684,147.6,682.6,147.6z"/>
	<path class="st0" d="M682.6,167.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C685.2,168.5,684,167.3,682.6,167.3z"/>
	<circle class="st0" cx="682.6" cy="160.1" r="2.6"/>
	<path class="st0" d="M692.4,88.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6C695.1,89.7,693.9,88.6,692.4,88.6z
		"/>
	<path class="st0" d="M702.3,127.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C704.9,129.1,703.7,127.9,702.3,127.9z"/>
	<circle class="st0" cx="682.6" cy="179.8" r="2.6"/>
	<circle class="st0" cx="682.6" cy="238.8" r="2.6"/>
	<path class="st0" d="M682.6,246c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C685.2,247.2,684,246,682.6,246z"/>
	<path class="st0" d="M682.6,255.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C685.2,257,684,255.8,682.6,255.8z"/>
	<path class="st0" d="M682.6,187c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C685.2,188.1,684,187,682.6,187z"/>
	<circle class="st0" cx="682.6" cy="268.3" r="2.6"/>
	<path class="st0" d="M682.6,275.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C685.2,276.7,684,275.5,682.6,275.5z"/>
	<path class="st0" d="M682.6,226.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C685.2,227.5,684,226.3,682.6,226.3z"/>
	<circle class="st0" cx="682.6" cy="199.4" r="2.6"/>
	<path class="st0" d="M682.6,206.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C685.2,207.8,684,206.6,682.6,206.6z"/>
	<path class="st0" d="M682.6,216.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C685.2,217.7,684,216.5,682.6,216.5z"/>
	<path class="st0" d="M702.3,226.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C704.9,227.5,703.7,226.3,702.3,226.3z"/>
	<path class="st0" d="M702.3,187c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C704.9,188.1,703.7,187,702.3,187z"/>
	<path class="st0" d="M702.3,177.1c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C704.9,178.3,703.7,177.1,702.3,177.1z"/>
	<path class="st0" d="M702.3,206.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C704.9,207.8,703.7,206.6,702.3,206.6z"/>
	<path class="st0" d="M702.3,216.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C704.9,217.7,703.7,216.5,702.3,216.5z"/>
	<path class="st0" d="M702.3,196.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C704.9,198,703.7,196.8,702.3,196.8z"/>
	<path class="st0" d="M702.3,137.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C704.9,138.9,703.7,137.8,702.3,137.8z"/>
	<path class="st0" d="M702.3,147.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C704.9,148.8,703.7,147.6,702.3,147.6z"/>
	<path class="st0" d="M702.3,167.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C704.9,168.5,703.7,167.3,702.3,167.3z"/>
	<path class="st0" d="M702.3,157.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S703.7,157.4,702.3,157.4z"/>
	<path class="st0" d="M702.3,255.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C704.9,257,703.7,255.8,702.3,255.8z"/>
	<path class="st0" d="M702.3,285.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C704.9,286.5,703.7,285.4,702.3,285.4z"/>
	<path class="st0" d="M702.3,295.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C704.9,296.4,703.7,295.2,702.3,295.2z"/>
	<path class="st0" d="M702.3,305c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C704.9,306.2,703.7,305,702.3,305z"/>
	<path class="st0" d="M702.3,314.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C704.9,316.1,703.7,314.9,702.3,314.9z"/>
	<path class="st0" d="M702.3,236.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C704.9,237.3,703.7,236.2,702.3,236.2z"/>
	<path class="st0" d="M702.3,334.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C704.9,335.7,703.7,334.6,702.3,334.6z"/>
	<path class="st0" d="M702.3,324.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C704.9,325.9,703.7,324.7,702.3,324.7z"/>
	<path class="st0" d="M702.3,275.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C704.9,276.7,703.7,275.5,702.3,275.5z"/>
	<path class="st0" d="M702.3,246c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C704.9,247.2,703.7,246,702.3,246z"/>
	<path class="st0" d="M702.3,265.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C704.9,266.9,703.7,265.7,702.3,265.7z"/>
	<path class="st0" d="M653,354.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C655.6,355.4,654.5,354.2,653,354.2z"/>
	<path class="st0" d="M761.4,88.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C764,89.7,762.9,88.6,761.4,88.6z"/>
	<path class="st0" d="M761.4,108.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C764,109.4,762.9,108.2,761.4,108.2z"/>
	<path class="st0" d="M761.4,137.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C764,138.9,762.9,137.8,761.4,137.8z"/>
	<path class="st0" d="M761.4,118.1c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S762.9,118.1,761.4,118.1z"/>
	<path class="st0" d="M761.4,127.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C764,129.1,762.9,127.9,761.4,127.9z"/>
	<path class="st0" d="M761.4,98.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S762.9,98.4,761.4,98.4z"/>
	<path class="st0" d="M751.6,49.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C754.2,50.4,753,49.2,751.6,49.2z"/>
	<path class="st0" d="M761.4,68.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S762.9,68.9,761.4,68.9z"/>
	<path class="st0" d="M761.4,246c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S762.9,246,761.4,246z"/>
	<circle class="st0" cx="761.4" cy="179.8" r="2.6"/>
	<path class="st0" d="M761.4,216.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S762.9,216.5,761.4,216.5z"
		/>
	<path class="st0" d="M761.4,206.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S762.9,206.6,761.4,206.6z"
		/>
	<circle class="st0" cx="761.4" cy="199.4" r="2.6"/>
	<circle class="st0" cx="761.4" cy="238.8" r="2.6"/>
	<path class="st0" d="M761.4,226.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S762.9,226.3,761.4,226.3z"
		/>
	<path class="st0" d="M761.4,187c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C764,188.1,762.9,187,761.4,187z"/>
	<path class="st0" d="M761.4,167.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S762.9,167.3,761.4,167.3z"
		/>
	<circle class="st0" cx="761.4" cy="160.1" r="2.6"/>
	<path class="st0" d="M761.4,147.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S762.9,147.6,761.4,147.6z"
		/>
	<path class="st0" d="M761.4,78.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C764,79.9,762.9,78.7,761.4,78.7z"/>
	<path class="st0" d="M771.3,236.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C773.9,237.3,772.7,236.2,771.3,236.2z"/>
	<path class="st0" d="M771.3,196.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C773.9,198,772.7,196.8,771.3,196.8z"/>
	<path class="st0" d="M771.3,206.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C773.9,207.8,772.7,206.6,771.3,206.6z"/>
	<path class="st0" d="M771.3,216.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C773.9,217.7,772.7,216.5,771.3,216.5z"/>
	<path class="st0" d="M771.3,226.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C773.9,227.5,772.7,226.3,771.3,226.3z"/>
	<path class="st0" d="M771.3,187c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6C773.9,188.1,772.7,187,771.3,187z"
		/>
	<path class="st0" d="M771.3,157.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S772.7,157.4,771.3,157.4z"/>
	<path class="st0" d="M771.3,147.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C773.9,148.8,772.7,147.6,771.3,147.6z"/>
	<path class="st0" d="M771.3,334.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C773.9,335.7,772.7,334.6,771.3,334.6z"/>
	<path class="st0" d="M771.3,167.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C773.9,168.5,772.7,167.3,771.3,167.3z"/>
	<path class="st0" d="M771.3,177.1c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C773.9,178.3,772.7,177.1,771.3,177.1z"/>
	<path class="st0" d="M771.3,305c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6C773.9,306.2,772.7,305,771.3,305z"
		/>
	<path class="st0" d="M771.3,246c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6C773.9,247.2,772.7,246,771.3,246z"
		/>
	<path class="st0" d="M771.3,295.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C773.9,296.4,772.7,295.2,771.3,295.2z"/>
	<path class="st0" d="M771.3,344.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C773.9,345.6,772.7,344.4,771.3,344.4z"/>
	<path class="st0" d="M771.3,324.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C773.9,325.9,772.7,324.7,771.3,324.7z"/>
	<path class="st0" d="M771.3,314.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C773.9,316.1,772.7,314.9,771.3,314.9z"/>
	<path class="st0" d="M771.3,255.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C773.9,257,772.7,255.8,771.3,255.8z"/>
	<path class="st0" d="M771.3,265.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C773.9,266.9,772.7,265.7,771.3,265.7z"/>
	<path class="st0" d="M771.3,275.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C773.9,276.7,772.7,275.5,771.3,275.5z"/>
	<path class="st0" d="M771.3,285.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C773.9,286.5,772.7,285.4,771.3,285.4z"/>
	<path class="st0" d="M761.4,354.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C764,355.4,762.9,354.2,761.4,354.2z"/>
	<path class="st0" d="M751.6,172.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C748.9,171.4,750.1,172.5,751.6,172.5z"/>
	<path class="st0" d="M751.6,211.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C748.9,210.7,750.1,211.9,751.6,211.9z"/>
	<path class="st0" d="M751.6,221.7c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C748.9,220.6,750.1,221.7,751.6,221.7z"/>
	<path class="st0" d="M751.6,182.4c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C748.9,181.2,750.1,182.4,751.6,182.4z"/>
	<path class="st0" d="M751.6,192.2c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C748.9,191,750.1,192.2,751.6,192.2z"/>
	<path class="st0" d="M761.4,255.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S762.9,255.8,761.4,255.8z"
		/>
	<path class="st0" d="M751.6,261.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C748.9,259.9,750.1,261.1,751.6,261.1z"/>
	<path class="st0" d="M751.6,251.3c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C748.9,250.1,750.1,251.3,751.6,251.3z"/>
	<path class="st0" d="M751.6,231.6c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C748.9,230.4,750.1,231.6,751.6,231.6z"/>
	<path class="st0" d="M751.6,241.4c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C748.9,240.2,750.1,241.4,751.6,241.4z"/>
	<path class="st0" d="M751.6,270.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C748.9,269.8,750.1,270.9,751.6,270.9z"/>
	<path class="st0" d="M751.6,93.8c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C748.9,92.6,750.1,93.8,751.6,93.8z"/>
	<path class="st0" d="M751.6,103.7c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S750.1,103.7,751.6,103.7z"/>
	<path class="st0" d="M751.6,162.7c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S750.1,162.7,751.6,162.7z"/>
	<path class="st0" d="M751.6,113.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C748.9,112.3,750.1,113.5,751.6,113.5z"/>
	<path class="st0" d="M751.6,84c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6C748.9,82.8,750.1,84,751.6,84z
		"/>
	<path class="st0" d="M751.6,123.3c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S750.1,123.3,751.6,123.3z"/>
	<path class="st0" d="M751.6,143c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C748.9,141.8,750.1,143,751.6,143z"/>
	<path class="st0" d="M751.6,152.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C748.9,151.7,750.1,152.9,751.6,152.9z"/>
	<path class="st0" d="M751.6,133.2c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C748.9,132,750.1,133.2,751.6,133.2z"/>
	<path class="st0" d="M751.6,202.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C748.9,200.9,750.1,202.1,751.6,202.1z"/>
	<path class="st0" d="M761.4,324.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C764,325.9,762.9,324.7,761.4,324.7z"/>
	<path class="st0" d="M751.6,280.8c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C748.9,279.6,750.1,280.8,751.6,280.8z"/>
	<path class="st0" d="M761.4,314.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S762.9,314.9,761.4,314.9z"
		/>
	<path class="st0" d="M761.4,334.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C764,335.7,762.9,334.6,761.4,334.6z"/>
	<circle class="st0" cx="761.4" cy="347" r="2.6"/>
	<path class="st0" d="M761.4,295.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C764,296.4,762.9,295.2,761.4,295.2z"/>
	<circle class="st0" cx="761.4" cy="268.3" r="2.6"/>
	<path class="st0" d="M761.4,275.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S762.9,275.5,761.4,275.5z"
		/>
	<path class="st0" d="M771.3,137.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C773.9,138.9,772.7,137.8,771.3,137.8z"/>
	<path class="st0" d="M761.4,285.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C764,286.5,762.9,285.4,761.4,285.4z"/>
	<circle class="st0" cx="761.4" cy="307.7" r="2.6"/>
	<path class="st0" d="M751.6,330c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C748.9,328.8,750.1,330,751.6,330z"/>
	<path class="st0" d="M751.6,320.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C748.9,319,750.1,320.1,751.6,320.1z"/>
	<path class="st0" d="M751.6,310.3c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C748.9,309.1,750.1,310.3,751.6,310.3z"/>
	<path class="st0" d="M751.6,300.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C748.9,299.3,750.1,300.5,751.6,300.5z"/>
	<path class="st0" d="M751.6,290.6c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C748.9,289.4,750.1,290.6,751.6,290.6z"/>
	<path class="st0" d="M764,366.7c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6
		C762.9,369.3,764,368.2,764,366.7z"/>
	<path class="st0" d="M751.6,339.8c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C748.9,338.6,750.1,339.8,751.6,339.8z"/>
	<path class="st0" d="M751.6,369.3c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C748.9,368.2,750.1,369.3,751.6,369.3z"/>
	<path class="st0" d="M751.6,359.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C748.9,358.3,750.1,359.5,751.6,359.5z"/>
	<path class="st0" d="M751.6,349.7c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C748.9,348.5,750.1,349.7,751.6,349.7z"/>
	<path class="st0" d="M791,147.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S792.4,147.6,791,147.6z"/>
	<path class="st0" d="M791,177.1c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S792.4,177.1,791,177.1z"/>
	<path class="st0" d="M791,157.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S792.4,157.4,791,157.4z"/>
	<path class="st0" d="M791,167.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S792.4,167.3,791,167.3z"/>
	<path class="st0" d="M791,127.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6C793.6,129.1,792.4,127.9,791,127.9z
		"/>
	<path class="st0" d="M791,137.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6C793.6,138.9,792.4,137.8,791,137.8z
		"/>
	<path class="st0" d="M791,98.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S792.4,98.4,791,98.4z"/>
	<path class="st0" d="M791,118.1c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S792.4,118.1,791,118.1z"/>
	<path class="st0" d="M791,78.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6C793.6,79.9,792.4,78.7,791,78.7z"/>
	<path class="st0" d="M791,108.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6C793.6,109.4,792.4,108.2,791,108.2z
		"/>
	<path class="st0" d="M791,88.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6C793.6,89.7,792.4,88.6,791,88.6z"/>
	<path class="st0" d="M791,246c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S792.4,246,791,246z"/>
	<path class="st0" d="M791,236.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6C793.6,237.3,792.4,236.2,791,236.2z
		"/>
	<path class="st0" d="M791,255.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S792.4,255.8,791,255.8z"/>
	<path class="st0" d="M791,275.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S792.4,275.5,791,275.5z"/>
	<path class="st0" d="M791,265.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6C793.6,266.9,792.4,265.7,791,265.7z
		"/>
	<path class="st0" d="M791,196.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S792.4,196.8,791,196.8z"/>
	<path class="st0" d="M791,187c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6C793.6,188.1,792.4,187,791,187z"/>
	<path class="st0" d="M791,226.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S792.4,226.3,791,226.3z"/>
	<path class="st0" d="M791,206.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S792.4,206.6,791,206.6z"/>
	<path class="st0" d="M791,216.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S792.4,216.5,791,216.5z"/>
	<path class="st0" d="M791,68.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S792.4,68.9,791,68.9z"/>
	<path class="st0" d="M791,285.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6C793.6,286.5,792.4,285.4,791,285.4z
		"/>
	<path class="st0" d="M791,349.7c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6C788.3,348.5,789.5,349.7,791,349.7z
		"/>
	<path class="st0" d="M781.1,334.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C783.8,335.7,782.6,334.6,781.1,334.6z"/>
	<path class="st0" d="M781.1,275.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C783.8,276.7,782.6,275.5,781.1,275.5z"/>
	<path class="st0" d="M781.1,305c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6C783.8,306.2,782.6,305,781.1,305z"
		/>
	<path class="st0" d="M781.1,255.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C783.8,257,782.6,255.8,781.1,255.8z"/>
	<path class="st0" d="M781.1,285.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C783.8,286.5,782.6,285.4,781.1,285.4z"/>
	<path class="st0" d="M781.1,206.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C783.8,207.8,782.6,206.6,781.1,206.6z"/>
	<path class="st0" d="M781.1,265.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C783.8,266.9,782.6,265.7,781.1,265.7z"/>
	<path class="st0" d="M781.1,246c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6C783.8,247.2,782.6,246,781.1,246z"
		/>
	<path class="st0" d="M791,295.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6C793.6,296.4,792.4,295.2,791,295.2z
		"/>
	<path class="st0" d="M781.1,216.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C783.8,217.7,782.6,216.5,781.1,216.5z"/>
	<path class="st0" d="M781.1,236.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C783.8,237.3,782.6,236.2,781.1,236.2z"/>
	<path class="st0" d="M781.1,295.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C783.8,296.4,782.6,295.2,781.1,295.2z"/>
	<path class="st0" d="M771.3,88.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6C773.9,89.7,772.7,88.6,771.3,88.6z
		"/>
	<path class="st0" d="M771.3,98.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S772.7,98.4,771.3,98.4z"/>
	<path class="st0" d="M771.3,118.1c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S772.7,118.1,771.3,118.1z"/>
	<path class="st0" d="M771.3,78.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6C773.9,79.9,772.7,78.7,771.3,78.7z
		"/>
	<path class="st0" d="M771.3,108.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C773.9,109.4,772.7,108.2,771.3,108.2z"/>
	<path class="st0" d="M771.3,68.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6C773.9,70.1,772.7,68.9,771.3,68.9z
		"/>
	<path class="st0" d="M771.3,359.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6
		C768.6,358.3,769.8,359.5,771.3,359.5z"/>
	<path class="st0" d="M771.3,127.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C773.9,129.1,772.7,127.9,771.3,127.9z"/>
	<path class="st0" d="M781.1,324.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C783.8,325.9,782.6,324.7,781.1,324.7z"/>
	<path class="st0" d="M781.1,314.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C783.8,316.1,782.6,314.9,781.1,314.9z"/>
	<path class="st0" d="M781.1,226.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C783.8,227.5,782.6,226.3,781.1,226.3z"/>
	<path class="st0" d="M781.1,98.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S782.6,98.4,781.1,98.4z"/>
	<path class="st0" d="M781.1,349.7c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6S779.7,349.7,781.1,349.7z"/>
	<path class="st0" d="M781.1,68.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6C783.8,70.1,782.6,68.9,781.1,68.9z
		"/>
	<path class="st0" d="M781.1,78.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6C783.8,79.9,782.6,78.7,781.1,78.7z
		"/>
	<path class="st0" d="M781.1,88.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6C783.8,89.7,782.6,88.6,781.1,88.6z
		"/>
	<path class="st0" d="M791,305c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S792.4,305,791,305z"/>
	<path class="st0" d="M791,324.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6C793.6,325.9,792.4,324.7,791,324.7z
		"/>
	<path class="st0" d="M791,314.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S792.4,314.9,791,314.9z"/>
	<path class="st0" d="M791,334.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6C793.6,335.7,792.4,334.6,791,334.6z
		"/>
	<path class="st0" d="M781.1,157.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S782.6,157.4,781.1,157.4z"/>
	<path class="st0" d="M781.1,167.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C783.8,168.5,782.6,167.3,781.1,167.3z"/>
	<path class="st0" d="M781.1,177.1c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C783.8,178.3,782.6,177.1,781.1,177.1z"/>
	<path class="st0" d="M781.1,196.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C783.8,198,782.6,196.8,781.1,196.8z"/>
	<path class="st0" d="M781.1,187c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6C783.8,188.1,782.6,187,781.1,187z"
		/>
	<path class="st0" d="M781.1,108.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C783.8,109.4,782.6,108.2,781.1,108.2z"/>
	<path class="st0" d="M781.1,118.1c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S782.6,118.1,781.1,118.1z"/>
	<path class="st0" d="M781.1,127.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C783.8,129.1,782.6,127.9,781.1,127.9z"/>
	<path class="st0" d="M781.1,137.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C783.8,138.9,782.6,137.8,781.1,137.8z"/>
	<path class="st0" d="M781.1,147.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C783.8,148.8,782.6,147.6,781.1,147.6z"/>
	<path class="st0" d="M544.6,143c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6S543.2,143,544.6,143z"/>
	<path class="st0" d="M534.8,147.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C537.4,148.8,536.2,147.6,534.8,147.6z"/>
	<path class="st0" d="M534.8,157.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S536.2,157.4,534.8,157.4z"/>
	<path class="st0" d="M537.4,169.9c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6S537.4,171.4,537.4,169.9z"/>
	<path class="st0" d="M515.1,192.2c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C512.4,191,513.6,192.2,515.1,192.2z"/>
	<path class="st0" d="M515.1,182.4c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C512.4,181.2,513.6,182.4,515.1,182.4z"/>
	<path class="st0" d="M515.1,172.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C512.4,171.4,513.6,172.5,515.1,172.5z"/>
	<path class="st0" d="M515.1,246c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C517.7,247.2,516.5,246,515.1,246z"/>
	<path class="st0" d="M524.9,236.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C527.5,237.3,526.4,236.2,524.9,236.2z"/>
	<path class="st0" d="M515.1,265.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C517.7,266.9,516.5,265.7,515.1,265.7z"/>
	<path class="st0" d="M524.9,157.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S526.4,157.4,524.9,157.4z"/>
	<path class="st0" d="M524.9,167.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S526.4,167.3,524.9,167.3z"
		/>
	<path class="st0" d="M515.1,202.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C512.4,200.9,513.6,202.1,515.1,202.1z"/>
	<path class="st0" d="M515.1,255.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C517.7,257,516.5,255.8,515.1,255.8z"/>
	<path class="st0" d="M515.1,236.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C517.7,237.3,516.5,236.2,515.1,236.2z"/>
	<path class="st0" d="M524.9,246c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S526.4,246,524.9,246z"/>
	<path class="st0" d="M527.5,258.5c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6
		C526.4,261.1,527.5,259.9,527.5,258.5z"/>
	<path class="st0" d="M515.1,226.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C517.7,227.5,516.5,226.3,515.1,226.3z"/>
	<path class="st0" d="M542,179.8c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6S542,178.3,542,179.8z"/>
	<path class="st0" d="M534.8,226.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C537.4,227.5,536.2,226.3,534.8,226.3z"/>
	<path class="st0" d="M544.6,255.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C547.2,257,546.1,255.8,544.6,255.8z"/>
	<path class="st1" d="M534.8,236.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C537.4,237.3,536.2,236.2,534.8,236.2z"/>
	<path class="st2" d="M524.9,177.1c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S526.4,177.1,524.9,177.1z"
		/>
	<path class="st0" d="M534.8,246c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6C537.4,247.2,536.2,246,534.8,246z"
		/>
	<path class="st0" d="M534.8,265.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C537.4,266.9,536.2,265.7,534.8,265.7z"/>
	<path class="st0" d="M534.8,255.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C537.4,257,536.2,255.8,534.8,255.8z"/>
	<path class="st0" d="M524.9,226.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S526.4,226.3,524.9,226.3z"
		/>
	<path class="st0" d="M485.5,354.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C488.1,355.4,486.9,354.2,485.5,354.2z"/>
	<path class="st0" d="M485.5,364.1c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C488.1,365.3,486.9,364.1,485.5,364.1z"/>
	<path class="st0" d="M485.5,344.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S486.9,344.4,485.5,344.4z"/>
	<path class="st0" d="M485.5,383.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S486.9,383.8,485.5,383.8z"/>
	<path class="st0" d="M485.5,373.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S486.9,373.9,485.5,373.9z"/>
	<path class="st0" d="M485.5,334.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C488.1,335.7,486.9,334.6,485.5,334.6z"/>
	<path class="st3" d="M495.3,251.3c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C492.7,250.1,493.9,251.3,495.3,251.3z"/>
	<path class="st0" d="M524.9,187c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C527.5,188.1,526.4,187,524.9,187z"/>
	<path class="st0" d="M485.5,324.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C488.1,325.9,486.9,324.7,485.5,324.7z"/>
	<path class="st0" d="M485.5,403.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S486.9,403.4,485.5,403.4z"/>
	<path class="st0" d="M485.5,305c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S486.9,305,485.5,305z"/>
	<path class="st0" d="M475.6,305c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C478.3,306.2,477.1,305,475.6,305z"/>
	<path class="st0" d="M475.6,364.1c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C478.3,365.3,477.1,364.1,475.6,364.1z"/>
	<path class="st0" d="M475.6,354.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C478.3,355.4,477.1,354.2,475.6,354.2z"/>
	<path class="st0" d="M485.5,393.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C488.1,394.8,486.9,393.6,485.5,393.6z"/>
	<path class="st0" d="M475.6,383.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S477.1,383.8,475.6,383.8z"/>
	<path class="st0" d="M475.6,403.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C478.3,404.6,477.1,403.4,475.6,403.4z"/>
	<path class="st0" d="M475.6,373.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C478.3,375.1,477.1,373.9,475.6,373.9z"/>
	<path class="st0" d="M475.6,314.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C478.3,316.1,477.1,314.9,475.6,314.9z"/>
	<path class="st0" d="M475.6,324.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C478.3,325.9,477.1,324.7,475.6,324.7z"/>
	<path class="st0" d="M475.6,344.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C478.3,345.6,477.1,344.4,475.6,344.4z"/>
	<path class="st0" d="M475.6,334.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C478.3,335.7,477.1,334.6,475.6,334.6z"/>
	<path class="st0" d="M485.5,314.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S486.9,314.9,485.5,314.9z"/>
	<path class="st0" d="M505.2,261.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S503.7,261.1,505.2,261.1z"
		/>
	<path class="st0" d="M495.3,241.4c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C492.7,240.2,493.9,241.4,495.3,241.4z"/>
	<path class="st0" d="M495.3,231.6c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C492.7,230.4,493.9,231.6,495.3,231.6z"/>
	<path class="st0" d="M505.2,241.4c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S503.7,241.4,505.2,241.4z"
		/>
	<path class="st4" d="M505.2,231.6c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C502.6,230.4,503.7,231.6,505.2,231.6z"/>
	<path class="st0" d="M505.2,221.7c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C502.6,220.6,503.7,221.7,505.2,221.7z"/>
	<path class="st0" d="M524.9,275.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S526.4,275.5,524.9,275.5z"
		/>
	<path class="st0" d="M544.6,265.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C547.2,266.9,546.1,265.7,544.6,265.7z"/>
	<path class="st0" d="M524.9,196.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S526.4,196.8,524.9,196.8z"
		/>
	<path class="st0" d="M527.5,209.3c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6
		C526.4,211.9,527.5,210.7,527.5,209.3z"/>
	<path class="st0" d="M522.3,150.2c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6
		C523.5,147.6,522.3,148.8,522.3,150.2z"/>
	<path class="st0" d="M505.2,251.3c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S503.7,251.3,505.2,251.3z"
		/>
	<path class="st0" d="M495.3,261.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C492.7,259.9,493.9,261.1,495.3,261.1z"/>
	<path class="st0" d="M544.6,133.2c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6C542,132,543.2,133.2,544.6,133.2z
		"/>
	<path class="st0" d="M544.6,152.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6S543.2,152.9,544.6,152.9z"/>
	<path class="st0" d="M515.1,354.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C517.7,355.4,516.5,354.2,515.1,354.2z"/>
	<path class="st0" d="M515.1,314.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C517.7,316.1,516.5,314.9,515.1,314.9z"/>
	<path class="st0" d="M524.9,314.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S526.4,314.9,524.9,314.9z"
		/>
	<path class="st0" d="M515.1,324.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C517.7,325.9,516.5,324.7,515.1,324.7z"/>
	<path class="st0" d="M515.1,344.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C517.7,345.6,516.5,344.4,515.1,344.4z"/>
	<path class="st0" d="M515.1,334.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C517.7,335.7,516.5,334.6,515.1,334.6z"/>
	<path class="st0" d="M475.6,393.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C478.3,394.8,477.1,393.6,475.6,393.6z"/>
	<path class="st0" d="M515.1,364.1c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C517.7,365.3,516.5,364.1,515.1,364.1z"/>
	<path class="st0" d="M515.1,403.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C517.7,404.6,516.5,403.4,515.1,403.4z"/>
	<path class="st0" d="M505.2,202.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C502.6,200.9,503.7,202.1,505.2,202.1z"/>
	<path class="st0" d="M515.1,413.3c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S516.5,413.3,515.1,413.3z"/>
	<path class="st0" d="M505.2,413.3c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S506.7,413.3,505.2,413.3z"/>
	<path class="st0" d="M515.1,373.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C517.7,375.1,516.5,373.9,515.1,373.9z"/>
	<path class="st0" d="M515.1,383.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S516.5,383.8,515.1,383.8z"/>
	<path class="st0" d="M515.1,393.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C517.7,394.8,516.5,393.6,515.1,393.6z"/>
	<path class="st0" d="M623.5,413.3c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S624.9,413.3,623.5,413.3z"/>
	<path class="st0" d="M613.6,202.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C611,200.9,612.1,202.1,613.6,202.1z"/>
	<path class="st0" d="M613.6,251.3c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S612.1,251.3,613.6,251.3z"
		/>
	<path class="st0" d="M613.6,241.4c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S612.1,241.4,613.6,241.4z"
		/>
	<path class="st0" d="M643.2,393.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C645.8,394.8,644.6,393.6,643.2,393.6z"/>
	<path class="st0" d="M653,393.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C655.6,394.8,654.5,393.6,653,393.6z"/>
	<path class="st0" d="M613.6,211.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S612.1,211.9,613.6,211.9z"
		/>
	<path class="st0" d="M613.6,231.6c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C611,230.4,612.1,231.6,613.6,231.6z"/>
	<path class="st0" d="M613.6,261.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S612.1,261.1,613.6,261.1z"
		/>
	<path class="st0" d="M613.6,290.6c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S612.1,290.6,613.6,290.6z"
		/>
	<path class="st0" d="M613.6,280.8c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C611,279.6,612.1,280.8,613.6,280.8z"/>
	<path class="st0" d="M613.6,270.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C611,269.8,612.1,270.9,613.6,270.9z"/>
	<path class="st0" d="M613.6,221.7c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C611,220.6,612.1,221.7,613.6,221.7z"/>
	<path class="st0" d="M623.5,423.1c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S624.9,423.1,623.5,423.1z"/>
	<path class="st0" d="M623.5,433c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S624.9,433,623.5,433z"/>
	<path class="st0" d="M505.2,192.2c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S503.7,192.2,505.2,192.2z"
		/>
	<path class="st0" d="M643.2,403.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S644.6,403.4,643.2,403.4z"
		/>
	<path class="st0" d="M623.5,403.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S624.9,403.4,623.5,403.4z"
		/>
	<path class="st0" d="M613.6,192.2c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S612.1,192.2,613.6,192.2z"
		/>
	<path class="st0" d="M613.6,182.4c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S612.1,182.4,613.6,182.4z"
		/>
	<path class="st0" d="M623.5,393.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C626.1,394.8,624.9,393.6,623.5,393.6z"/>
	<path class="st0" d="M613.6,162.7c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S612.1,162.7,613.6,162.7z"/>
	<path class="st0" d="M613.6,172.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C611,171.4,612.1,172.5,613.6,172.5z"/>
	<path class="st0" d="M564.3,172.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C561.7,171.4,562.9,172.5,564.3,172.5z"/>
	<path class="st0" d="M554.5,196.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S555.9,196.8,554.5,196.8z"
		/>
	<circle class="st0" cx="564.3" cy="160.1" r="2.6"/>
	<path class="st0" d="M564.3,275.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C567,276.7,565.8,275.5,564.3,275.5z"/>
	<path class="st0" d="M564.3,152.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S562.9,152.9,564.3,152.9z"
		/>
	<path class="st0" d="M554.5,216.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S555.9,216.5,554.5,216.5z"
		/>
	<path class="st5" d="M544.6,246c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6C547.2,247.2,546.1,246,544.6,246z"
		/>
	<path class="st0" d="M564.3,192.2c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S562.9,192.2,564.3,192.2z"
		/>
	<circle class="st0" cx="564.3" cy="268.3" r="2.6"/>
	<circle class="st0" cx="564.3" cy="179.8" r="2.6"/>
	<path class="st0" d="M554.5,226.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S555.9,226.3,554.5,226.3z"
		/>
	<path class="st0" d="M554.5,236.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C557.1,237.3,555.9,236.2,554.5,236.2z"/>
	<path class="st0" d="M544.6,236.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C547.2,237.3,546.1,236.2,544.6,236.2z"/>
	<path class="st6" d="M544.6,226.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C547.2,227.5,546.1,226.3,544.6,226.3z"/>
	<path class="st0" d="M502.6,179.8c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6
		C503.7,177.1,502.6,178.3,502.6,179.8z"/>
	<path class="st0" d="M544.6,280.8c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6
		C542,279.6,543.2,280.8,544.6,280.8z"/>
	<path class="st0" d="M557.1,278.2c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6
		C555.9,280.8,557.1,279.6,557.1,278.2z"/>
	<path class="st0" d="M557.1,297.9c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6
		C555.9,300.5,557.1,299.3,557.1,297.9z"/>
	<path class="st0" d="M557.1,288c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6
		C555.9,290.6,557.1,289.4,557.1,288z"/>
	<path class="st0" d="M554.5,246c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S555.9,246,554.5,246z"/>
	<path class="st0" d="M554.5,255.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S555.9,255.8,554.5,255.8z"
		/>
	<path class="st0" d="M554.5,265.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C557.1,266.9,555.9,265.7,554.5,265.7z"/>
	<path class="st0" d="M534.8,137.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C537.4,138.9,536.2,137.8,534.8,137.8z"/>
	<path class="st0" d="M554.5,162.7c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S553,162.7,554.5,162.7z"/>
	<path class="st0" d="M544.6,162.7c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6S543.2,162.7,544.6,162.7z"/>
	<path class="st0" d="M554.5,182.4c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C551.8,181.2,553,182.4,554.5,182.4z"/>
	<path class="st0" d="M554.5,172.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C551.8,171.4,553,172.5,554.5,172.5z"/>
	<path class="st0" d="M554.5,192.2c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C551.8,191,553,192.2,554.5,192.2z"/>
	<path class="st0" d="M544.6,192.2c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6S543.2,192.2,544.6,192.2z"/>
	<path class="st0" d="M653,364.1c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C655.6,365.3,654.5,364.1,653,364.1z"/>
	<path class="st0" d="M653,373.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S654.5,373.9,653,373.9z"/>
	<path class="st0" d="M524.9,74.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C522.3,73,523.5,74.1,524.9,74.1z"/>
	<path class="st0" d="M534.8,74.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6C532.1,73,533.3,74.1,534.8,74.1z"
		/>
	<path class="st0" d="M524.9,54.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C522.3,53.3,523.5,54.5,524.9,54.5z"/>
	<path class="st0" d="M524.9,64.3c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S523.5,64.3,524.9,64.3z"/>
	<path class="st0" d="M564.3,226.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C567,227.5,565.8,226.3,564.3,226.3z"/>
	<path class="st0" d="M564.3,216.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C567,217.7,565.8,216.5,564.3,216.5z"/>
	<path class="st0" d="M564.3,206.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C567,207.8,565.8,206.6,564.3,206.6z"/>
	<path class="st0" d="M554.5,206.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S555.9,206.6,554.5,206.6z"
		/>
	<path class="st0" d="M544.6,216.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C547.2,217.7,546.1,216.5,544.6,216.5z"/>
	<path class="st0" d="M544.6,206.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C547.2,207.8,546.1,206.6,544.6,206.6z"/>
	<path class="st0" d="M554.5,152.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C551.8,151.7,553,152.9,554.5,152.9z"/>
	<circle class="st0" cx="564.3" cy="238.8" r="2.6"/>
	<path class="st0" d="M564.3,246c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C567,247.2,565.8,246,564.3,246z"/>
	<path class="st0" d="M554.5,133.2c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C551.8,132,553,133.2,554.5,133.2z"/>
	<path class="st0" d="M554.5,143c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C551.8,141.8,553,143,554.5,143z"/>
	<circle class="st0" cx="564.3" cy="199.4" r="2.6"/>
	<path class="st0" d="M564.3,137.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C567,138.9,565.8,137.8,564.3,137.8z"/>
	<path class="st0" d="M574.2,137.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C576.8,138.9,575.6,137.8,574.2,137.8z"/>
	<path class="st0" d="M564.3,255.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C567,257,565.8,255.8,564.3,255.8z"/>
	<path class="st0" d="M564.3,334.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C567,335.7,565.8,334.6,564.3,334.6z"/>
	<circle class="st0" cx="564.3" cy="347" r="2.6"/>
	<path class="st0" d="M554.5,492c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S555.9,492,554.5,492z"/>
	<path class="st0" d="M554.5,531.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S555.9,531.4,554.5,531.4z"/>
	<path class="st0" d="M564.3,364.1c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C567,365.3,565.8,364.1,564.3,364.1z"/>
	<path class="st0" d="M564.3,373.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C567,375.1,565.8,373.9,564.3,373.9z"/>
	<path class="st0" d="M554.5,541.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S555.9,541.2,554.5,541.2z"
		/>
	<path class="st0" d="M554.5,551c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S555.9,551,554.5,551z"/>
	<path class="st0" d="M554.5,511.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S555.9,511.7,554.5,511.7z"
		/>
	<path class="st0" d="M554.5,501.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S555.9,501.8,554.5,501.8z"/>
	<path class="st0" d="M554.5,521.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S555.9,521.5,554.5,521.5z"
		/>
	<path class="st0" d="M564.3,354.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C567,355.4,565.8,354.2,564.3,354.2z"/>
	<circle class="st0" cx="564.3" cy="455.3" r="2.6"/>
	<path class="st0" d="M564.3,442.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S565.8,442.8,564.3,442.8z"/>
	<path class="st0" d="M564.3,433c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S565.8,433,564.3,433z"/>
	<circle class="st0" cx="564.3" cy="386.4" r="2.6"/>
	<circle class="st0" cx="564.3" cy="475" r="2.6"/>
	<path class="st0" d="M564.3,462.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C567,463.7,565.8,462.5,564.3,462.5z"/>
	<path class="st0" d="M564.3,423.1c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S565.8,423.1,564.3,423.1z"/>
	<path class="st0" d="M564.3,393.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C567,394.8,565.8,393.6,564.3,393.6z"/>
	<path class="st0" d="M564.3,403.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C567,404.6,565.8,403.4,564.3,403.4z"/>
	<circle class="st0" cx="564.3" cy="415.9" r="2.6"/>
	<path class="st0" d="M554.5,413.3c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S555.9,413.3,554.5,413.3z"/>
	<path class="st0" d="M554.5,324.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C557.1,325.9,555.9,324.7,554.5,324.7z"/>
	<path class="st0" d="M554.5,314.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S555.9,314.9,554.5,314.9z"
		/>
	<path class="st0" d="M554.5,334.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C557.1,335.7,555.9,334.6,554.5,334.6z"/>
	<path class="st0" d="M554.5,344.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S555.9,344.4,554.5,344.4z"
		/>
	<path class="st0" d="M554.5,354.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C557.1,355.4,555.9,354.2,554.5,354.2z"/>
	<path class="st0" d="M554.5,373.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S555.9,373.9,554.5,373.9z"
		/>
	<path class="st0" d="M554.5,364.1c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C557.1,365.3,555.9,364.1,554.5,364.1z"/>
	<path class="st0" d="M544.6,511.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C547.2,512.9,546.1,511.7,544.6,511.7z"/>
	<path class="st0" d="M544.6,531.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S546.1,531.4,544.6,531.4z"/>
	<path class="st0" d="M544.6,521.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C547.2,522.7,546.1,521.5,544.6,521.5z"/>
	<path class="st0" d="M544.6,541.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C547.2,542.4,546.1,541.2,544.6,541.2z"/>
	<path class="st0" d="M544.6,551c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6C547.2,552.2,546.1,551,544.6,551z"
		/>
	<path class="st0" d="M554.5,393.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C557.1,394.8,555.9,393.6,554.5,393.6z"/>
	<path class="st0" d="M554.5,452.6c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S555.9,452.6,554.5,452.6z"/>
	<path class="st0" d="M554.5,442.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S555.9,442.8,554.5,442.8z"/>
	<path class="st0" d="M554.5,462.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S555.9,462.5,554.5,462.5z"
		/>
	<path class="st0" d="M554.5,472.3c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S555.9,472.3,554.5,472.3z"/>
	<path class="st0" d="M554.5,433c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S555.9,433,554.5,433z"/>
	<path class="st0" d="M554.5,482.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C557.1,483.3,555.9,482.2,554.5,482.2z"/>
	<path class="st0" d="M554.5,423.1c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S555.9,423.1,554.5,423.1z"/>
	<path class="st0" d="M554.5,403.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S555.9,403.4,554.5,403.4z"
		/>
	<path class="st0" d="M554.5,383.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S555.9,383.8,554.5,383.8z"/>
	<path class="st0" d="M564.3,482.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C567,483.3,565.8,482.2,564.3,482.2z"/>
	<path class="st0" d="M564.3,541.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C567,542.4,565.8,541.2,564.3,541.2z"/>
	<path class="st0" d="M603.7,423.1c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S605.2,423.1,603.7,423.1z"/>
	<path class="st0" d="M603.7,373.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C606.4,375.1,605.2,373.9,603.7,373.9z"/>
	<path class="st0" d="M613.6,373.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C616.2,375.1,615,373.9,613.6,373.9z"/>
	<path class="st0" d="M603.7,393.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C606.4,394.8,605.2,393.6,603.7,393.6z"/>
	<path class="st0" d="M603.7,413.3c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S605.2,413.3,603.7,413.3z"/>
	<path class="st0" d="M603.7,403.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C606.4,404.6,605.2,403.4,603.7,403.4z"/>
	<circle class="st0" cx="564.3" cy="494.6" r="2.6"/>
	<path class="st0" d="M584,501.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S585.5,501.8,584,501.8z"/>
	<path class="st0" d="M584,492c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S585.5,492,584,492z"/>
	<path class="st0" d="M584,511.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C586.7,512.9,585.5,511.7,584,511.7z"/>
	<path class="st0" d="M584,521.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C586.7,522.7,585.5,521.5,584,521.5z"/>
	<path class="st0" d="M603.7,433c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S605.2,433,603.7,433z"/>
	<path class="st0" d="M603.7,472.3c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S605.2,472.3,603.7,472.3z"/>
	<path class="st0" d="M613.6,482.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C616.2,483.3,615,482.2,613.6,482.2z"/>
	<path class="st0" d="M584,482.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C586.7,483.3,585.5,482.2,584,482.2z"/>
	<path class="st0" d="M613.6,472.3c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S615,472.3,613.6,472.3z"/>
	<path class="st0" d="M603.7,452.6c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S605.2,452.6,603.7,452.6z"/>
	<path class="st0" d="M613.6,452.6c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S615,452.6,613.6,452.6z"/>
	<path class="st0" d="M603.7,442.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S605.2,442.8,603.7,442.8z"/>
	<path class="st0" d="M603.7,462.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C606.4,463.7,605.2,462.5,603.7,462.5z"/>
	<path class="st0" d="M603.7,482.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C606.4,483.3,605.2,482.2,603.7,482.2z"/>
	<path class="st0" d="M603.7,383.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S605.2,383.8,603.7,383.8z"/>
	<path class="st0" d="M584,364.1c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C586.7,365.3,585.5,364.1,584,364.1z"/>
	<path class="st0" d="M564.3,531.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S565.8,531.4,564.3,531.4z"/>
	<path class="st0" d="M584,334.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C586.7,335.7,585.5,334.6,584,334.6z"/>
	<path class="st0" d="M584,354.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C586.7,355.4,585.5,354.2,584,354.2z"/>
	<path class="st0" d="M584,344.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C586.7,345.6,585.5,344.4,584,344.4z"/>
	<path class="st0" d="M584,324.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C586.7,325.9,585.5,324.7,584,324.7z"/>
	<path class="st0" d="M544.6,501.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S546.1,501.8,544.6,501.8z"/>
	<path class="st0" d="M564.3,511.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C567,512.9,565.8,511.7,564.3,511.7z"/>
	<path class="st0" d="M584,472.3c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S585.5,472.3,584,472.3z"/>
	<path class="st0" d="M564.3,521.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C567,522.7,565.8,521.5,564.3,521.5z"/>
	<path class="st0" d="M564.3,501.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S565.8,501.8,564.3,501.8z"/>
	<path class="st0" d="M584,373.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C586.7,375.1,585.5,373.9,584,373.9z"/>
	<path class="st0" d="M584,433c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S585.5,433,584,433z"/>
	<path class="st0" d="M584,442.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S585.5,442.8,584,442.8z"/>
	<path class="st0" d="M584,452.6c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S585.5,452.6,584,452.6z"/>
	<path class="st0" d="M584,462.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C586.7,463.7,585.5,462.5,584,462.5z"/>
	<path class="st0" d="M584,423.1c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S585.5,423.1,584,423.1z"/>
	<path class="st0" d="M584,383.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S585.5,383.8,584,383.8z"/>
	<path class="st0" d="M584,393.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C586.7,394.8,585.5,393.6,584,393.6z"/>
	<path class="st0" d="M584,413.3c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S585.5,413.3,584,413.3z"/>
	<path class="st0" d="M584,403.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C586.7,404.6,585.5,403.4,584,403.4z"/>
	<path class="st0" d="M544.6,354.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C547.2,355.4,546.1,354.2,544.6,354.2z"/>
	<path class="st0" d="M458.6,229c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6S458.6,230.4,458.6,229z"/>
	<path class="st0" d="M475.6,285.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C478.3,286.5,477.1,285.4,475.6,285.4z"/>
	<path class="st0" d="M475.6,275.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C478.3,276.7,477.1,275.5,475.6,275.5z"/>
	<path class="st0" d="M495.3,314.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C498,316.1,496.8,314.9,495.3,314.9z"/>
	<path class="st0" d="M495.3,334.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C498,335.7,496.8,334.6,495.3,334.6z"/>
	<path class="st0" d="M495.3,344.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C498,345.6,496.8,344.4,495.3,344.4z"/>
	<path class="st0" d="M495.3,373.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C498,375.1,496.8,373.9,495.3,373.9z"/>
	<path class="st0" d="M495.3,364.1c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C498,365.3,496.8,364.1,495.3,364.1z"/>
	<path class="st0" d="M495.3,354.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C498,355.4,496.8,354.2,495.3,354.2z"/>
	<path class="st0" d="M495.3,324.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C498,325.9,496.8,324.7,495.3,324.7z"/>
	<path class="st0" d="M485.5,261.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6S484,261.1,485.5,261.1z"/>
	<path class="st0" d="M465.8,373.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S467.2,373.9,465.8,373.9z"
		/>
	<circle class="st0" cx="465.8" cy="386.4" r="2.6"/>
	<path class="st0" d="M465.8,393.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C468.4,394.8,467.2,393.6,465.8,393.6z"/>
	<path class="st0" d="M465.8,403.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S467.2,403.4,465.8,403.4z"
		/>
	<path class="st0" d="M465.8,364.1c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C468.4,365.3,467.2,364.1,465.8,364.1z"/>
	<path class="st0" d="M465.8,354.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C468.4,355.4,467.2,354.2,465.8,354.2z"/>
	<path class="st0" d="M465.8,324.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C468.4,325.9,467.2,324.7,465.8,324.7z"/>
	<path class="st0" d="M465.8,334.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C468.4,335.7,467.2,334.6,465.8,334.6z"/>
	<circle class="st0" cx="465.8" cy="347" r="2.6"/>
	<path class="st0" d="M485.5,270.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6
		C482.9,269.8,484,270.9,485.5,270.9z"/>
	<path class="st0" d="M455.9,285.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C458.6,286.5,457.4,285.4,455.9,285.4z"/>
	<path class="st0" d="M463.2,278.2c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6
		C464.3,275.5,463.2,276.7,463.2,278.2z"/>
	<path class="st0" d="M465.8,290.6c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C463.2,289.4,464.3,290.6,465.8,290.6z"/>
	<path class="st0" d="M495.3,383.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S496.8,383.8,495.3,383.8z"/>
	<path class="st0" d="M485.5,280.8c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6
		C482.9,279.6,484,280.8,485.5,280.8z"/>
	<path class="st0" d="M544.6,492c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S546.1,492,544.6,492z"/>
	<path class="st0" d="M455.9,275.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C458.6,276.7,457.4,275.5,455.9,275.5z"/>
	<path class="st0" d="M485.5,246c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S486.9,246,485.5,246z"/>
	<path class="st7" d="M465.8,216.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S467.2,216.5,465.8,216.5z"
		/>
	<path class="st0" d="M544.6,344.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C547.2,345.6,546.1,344.4,544.6,344.4z"/>
	<path class="st0" d="M465.8,314.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S467.2,314.9,465.8,314.9z"
		/>
	<path class="st0" d="M465.8,305c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S467.2,305,465.8,305z"/>
	<path class="st0" d="M544.6,373.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C547.2,375.1,546.1,373.9,544.6,373.9z"/>
	<path class="st0" d="M544.6,383.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S546.1,383.8,544.6,383.8z"/>
	<path class="st0" d="M544.6,334.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C547.2,335.7,546.1,334.6,544.6,334.6z"/>
	<path class="st0" d="M524.9,413.3c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S526.4,413.3,524.9,413.3z"/>
	<path class="st0" d="M524.9,442.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S526.4,442.8,524.9,442.8z"/>
	<path class="st0" d="M544.6,324.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C547.2,325.9,546.1,324.7,544.6,324.7z"/>
	<path class="st0" d="M524.9,423.1c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S526.4,423.1,524.9,423.1z"/>
	<path class="st0" d="M515.1,423.1c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S516.5,423.1,515.1,423.1z"/>
	<path class="st0" d="M524.9,433c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S526.4,433,524.9,433z"/>
	<path class="st0" d="M544.6,364.1c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C547.2,365.3,546.1,364.1,544.6,364.1z"/>
	<path class="st0" d="M544.6,462.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C547.2,463.7,546.1,462.5,544.6,462.5z"/>
	<path class="st0" d="M544.6,452.6c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S546.1,452.6,544.6,452.6z"/>
	<path class="st0" d="M544.6,442.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S546.1,442.8,544.6,442.8z"/>
	<path class="st0" d="M544.6,472.3c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S546.1,472.3,544.6,472.3z"/>
	<path class="st0" d="M544.6,482.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C547.2,483.3,546.1,482.2,544.6,482.2z"/>
	<path class="st0" d="M544.6,433c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S546.1,433,544.6,433z"/>
	<path class="st0" d="M544.6,393.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C547.2,394.8,546.1,393.6,544.6,393.6z"/>
	<path class="st0" d="M544.6,413.3c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S546.1,413.3,544.6,413.3z"/>
	<path class="st0" d="M544.6,403.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C547.2,404.6,546.1,403.4,544.6,403.4z"/>
	<path class="st0" d="M544.6,423.1c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S546.1,423.1,544.6,423.1z"/>
	<path class="st0" d="M524.9,373.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S526.4,373.9,524.9,373.9z"
		/>
	<path class="st0" d="M505.2,344.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C507.8,345.6,506.7,344.4,505.2,344.4z"/>
	<path class="st0" d="M505.2,354.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C507.8,355.4,506.7,354.2,505.2,354.2z"/>
	<path class="st0" d="M505.2,364.1c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C507.8,365.3,506.7,364.1,505.2,364.1z"/>
	<path class="st0" d="M505.2,373.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C507.8,375.1,506.7,373.9,505.2,373.9z"/>
	<path class="st0" d="M505.2,334.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C507.8,335.7,506.7,334.6,505.2,334.6z"/>
	<path class="st0" d="M505.2,324.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C507.8,325.9,506.7,324.7,505.2,324.7z"/>
	<path class="st0" d="M505.2,393.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C507.8,394.8,506.7,393.6,505.2,393.6z"/>
	<path class="st0" d="M495.3,393.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C498,394.8,496.8,393.6,495.3,393.6z"/>
	<path class="st0" d="M505.2,305c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C507.8,306.2,506.7,305,505.2,305z"/>
	<path class="st0" d="M505.2,314.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C507.8,316.1,506.7,314.9,505.2,314.9z"/>
	<path class="st0" d="M524.9,383.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S526.4,383.8,524.9,383.8z"/>
	<path class="st0" d="M524.9,364.1c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C527.5,365.3,526.4,364.1,524.9,364.1z"/>
	<path class="st0" d="M524.9,403.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S526.4,403.4,524.9,403.4z"
		/>
	<path class="st0" d="M505.2,383.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S506.7,383.8,505.2,383.8z"/>
	<path class="st0" d="M524.9,393.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C527.5,394.8,526.4,393.6,524.9,393.6z"/>
	<path class="st0" d="M495.3,403.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C498,404.6,496.8,403.4,495.3,403.4z"/>
	<path class="st0" d="M524.9,324.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C527.5,325.9,526.4,324.7,524.9,324.7z"/>
	<path class="st0" d="M524.9,334.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C527.5,335.7,526.4,334.6,524.9,334.6z"/>
	<path class="st0" d="M524.9,354.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C527.5,355.4,526.4,354.2,524.9,354.2z"/>
	<path class="st0" d="M524.9,344.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S526.4,344.4,524.9,344.4z"
		/>
	<path class="st0" d="M840.2,206.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C842.9,207.8,841.7,206.6,840.2,206.6z"/>
	<path class="st0" d="M840.2,157.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S841.7,157.4,840.2,157.4z"/>
	<path class="st0" d="M840.2,167.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C842.9,168.5,841.7,167.3,840.2,167.3z"/>
	<path class="st0" d="M840.2,187c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6C842.9,188.1,841.7,187,840.2,187z"
		/>
	<path class="st0" d="M840.2,147.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C842.9,148.8,841.7,147.6,840.2,147.6z"/>
	<path class="st0" d="M840.2,177.1c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C842.9,178.3,841.7,177.1,840.2,177.1z"/>
	<path class="st0" d="M840.2,127.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C842.9,129.1,841.7,127.9,840.2,127.9z"/>
	<path class="st0" d="M840.2,118.1c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S841.7,118.1,840.2,118.1z"/>
	<path class="st0" d="M840.2,108.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C842.9,109.4,841.7,108.2,840.2,108.2z"/>
	<path class="st0" d="M840.2,196.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C842.9,198,841.7,196.8,840.2,196.8z"/>
	<path class="st0" d="M840.2,137.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C842.9,138.9,841.7,137.8,840.2,137.8z"/>
	<path class="st0" d="M840.2,255.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C842.9,257,841.7,255.8,840.2,255.8z"/>
	<path class="st0" d="M840.2,98.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S841.7,98.4,840.2,98.4z"/>
	<path class="st0" d="M840.2,265.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C842.9,266.9,841.7,265.7,840.2,265.7z"/>
	<path class="st0" d="M840.2,275.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C842.9,276.7,841.7,275.5,840.2,275.5z"/>
	<path class="st0" d="M613.6,300.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S612.1,300.5,613.6,300.5z"
		/>
	<path class="st0" d="M840.2,285.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C842.9,286.5,841.7,285.4,840.2,285.4z"/>
	<path class="st0" d="M840.2,295.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C842.9,296.4,841.7,295.2,840.2,295.2z"/>
	<path class="st0" d="M840.2,226.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C842.9,227.5,841.7,226.3,840.2,226.3z"/>
	<path class="st0" d="M840.2,216.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C842.9,217.7,841.7,216.5,840.2,216.5z"/>
	<path class="st0" d="M840.2,236.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C842.9,237.3,841.7,236.2,840.2,236.2z"/>
	<path class="st0" d="M840.2,246c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6C842.9,247.2,841.7,246,840.2,246z"
		/>
	<path class="st0" d="M869.8,314.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C872.4,316.1,871.3,314.9,869.8,314.9z"/>
	<path class="st0" d="M840.2,305c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6C842.9,306.2,841.7,305,840.2,305z"
		/>
	<path class="st0" d="M840.2,373.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C842.9,375.1,841.7,373.9,840.2,373.9z"/>
	<path class="st0" d="M869.8,324.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C872.4,325.9,871.3,324.7,869.8,324.7z"/>
	<path class="st0" d="M850.1,389c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S848.6,389,850.1,389z"/>
	<circle class="st0" cx="869.8" cy="307.7" r="2.6"/>
	<path class="st0" d="M869.8,285.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C872.4,286.5,871.3,285.4,869.8,285.4z"/>
	<path class="st0" d="M879.7,285.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C882.3,286.5,881.1,285.4,879.7,285.4z"/>
	<path class="st0" d="M869.8,295.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C872.4,296.4,871.3,295.2,869.8,295.2z"/>
	<path class="st0" d="M840.2,383.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S841.7,383.8,840.2,383.8z"/>
	<path class="st0" d="M840.2,393.6c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S841.7,393.6,840.2,393.6z"/>
	<path class="st0" d="M840.2,78.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6C842.9,79.9,841.7,78.7,840.2,78.7z
		"/>
	<path class="st0" d="M840.2,68.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6C842.9,70.1,841.7,68.9,840.2,68.9z
		"/>
	<path class="st0" d="M830.4,68.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S831.8,68.9,830.4,68.9z"/>
	<path class="st0" d="M833,415.9c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6S833,417.4,833,415.9z"/>
	<path class="st0" d="M840.2,88.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6C842.9,89.7,841.7,88.6,840.2,88.6z
		"/>
	<path class="st0" d="M820.5,182.4c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S819.1,182.4,820.5,182.4z"
		/>
	<path class="st0" d="M820.5,152.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S819.1,152.9,820.5,152.9z"
		/>
	<path class="st0" d="M820.5,162.7c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S819.1,162.7,820.5,162.7z"/>
	<path class="st0" d="M820.5,143c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S819.1,143,820.5,143z"/>
	<path class="st0" d="M820.5,172.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C817.9,171.4,819.1,172.5,820.5,172.5z"/>
	<path class="st0" d="M820.5,133.2c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C817.9,132,819.1,133.2,820.5,133.2z"/>
	<path class="st0" d="M938.8,108.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C941.4,109.4,940.2,108.2,938.8,108.2z"/>
	<path class="st0" d="M820.5,211.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S819.1,211.9,820.5,211.9z"
		/>
	<path class="st0" d="M820.5,202.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C817.9,200.9,819.1,202.1,820.5,202.1z"/>
	<path class="st0" d="M820.5,192.2c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S819.1,192.2,820.5,192.2z"
		/>
	<path class="st0" d="M820.5,123.3c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S819.1,123.3,820.5,123.3z"/>
	<path class="st0" d="M820.5,93.8c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C817.9,92.6,819.1,93.8,820.5,93.8z"/>
	<path class="st0" d="M830.4,379.2c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C827.8,378,828.9,379.2,830.4,379.2z"/>
	<path class="st0" d="M830.4,359.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C827.8,358.3,828.9,359.5,830.4,359.5z"/>
	<path class="st0" d="M840.2,359.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C837.6,358.3,838.8,359.5,840.2,359.5z"/>
	<path class="st0" d="M830.4,369.3c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C827.8,368.2,828.9,369.3,830.4,369.3z"/>
	<path class="st0" d="M840.2,369.3c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C837.6,368.2,838.8,369.3,840.2,369.3z"/>
	<path class="st0" d="M830.4,339.8c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C827.8,338.6,828.9,339.8,830.4,339.8z"/>
	<path class="st0" d="M830.4,349.7c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C827.8,348.5,828.9,349.7,830.4,349.7z"/>
	<path class="st0" d="M820.5,221.7c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C817.9,220.6,819.1,221.7,820.5,221.7z"/>
	<path class="st0" d="M820.5,84c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S819.1,84,820.5,84z"/>
	<path class="st0" d="M820.5,103.7c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S819.1,103.7,820.5,103.7z"/>
	<path class="st0" d="M820.5,113.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S819.1,113.5,820.5,113.5z"
		/>
	<path class="st0" d="M830.4,389c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S828.9,389,830.4,389z"/>
	<path class="st0" d="M840.2,314.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C842.9,316.1,841.7,314.9,840.2,314.9z"/>
	<path class="st0" d="M820.5,369.3c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C817.9,368.2,819.1,369.3,820.5,369.3z"/>
	<path class="st0" d="M820.5,379.2c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S819.1,379.2,820.5,379.2z"
		/>
	<path class="st0" d="M820.5,389c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S819.1,389,820.5,389z"/>
	<path class="st0" d="M820.5,408.7c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S819.1,408.7,820.5,408.7z"
		/>
	<path class="st0" d="M820.5,339.8c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C817.9,338.6,819.1,339.8,820.5,339.8z"/>
	<path class="st0" d="M820.5,359.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S819.1,359.5,820.5,359.5z"
		/>
	<path class="st0" d="M820.5,349.7c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S819.1,349.7,820.5,349.7z"
		/>
	<path class="st0" d="M840.2,344.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C842.9,345.6,841.7,344.4,840.2,344.4z"/>
	<path class="st0" d="M840.2,324.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C842.9,325.9,841.7,324.7,840.2,324.7z"/>
	<path class="st0" d="M840.2,334.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C842.9,335.7,841.7,334.6,840.2,334.6z"/>
	<path class="st0" d="M820.5,290.6c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S819.1,290.6,820.5,290.6z"
		/>
	<path class="st0" d="M820.5,261.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S819.1,261.1,820.5,261.1z"
		/>
	<path class="st0" d="M820.5,270.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C817.9,269.8,819.1,270.9,820.5,270.9z"/>
	<path class="st0" d="M820.5,330c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S819.1,330,820.5,330z"/>
	<path class="st0" d="M820.5,241.4c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S819.1,241.4,820.5,241.4z"
		/>
	<path class="st0" d="M820.5,251.3c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S819.1,251.3,820.5,251.3z"
		/>
	<path class="st0" d="M820.5,280.8c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C817.9,279.6,819.1,280.8,820.5,280.8z"/>
	<path class="st0" d="M820.5,231.6c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C817.9,230.4,819.1,231.6,820.5,231.6z"/>
	<path class="st0" d="M820.5,320.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C817.9,319,819.1,320.1,820.5,320.1z"/>
	<path class="st0" d="M820.5,310.3c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C817.9,309.1,819.1,310.3,820.5,310.3z"/>
	<path class="st0" d="M820.5,300.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S819.1,300.5,820.5,300.5z"
		/>
	<path class="st0" d="M830.4,330c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C827.8,328.8,828.9,330,830.4,330z"/>
	<path class="st0" d="M899.4,251.3c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C896.7,250.1,897.9,251.3,899.4,251.3z"/>
	<path class="st0" d="M899.4,261.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C896.7,259.9,897.9,261.1,899.4,261.1z"/>
	<path class="st0" d="M938.8,118.1c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S940.2,118.1,938.8,118.1z"/>
	<path class="st0" d="M899.4,241.4c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C896.7,240.2,897.9,241.4,899.4,241.4z"/>
	<path class="st0" d="M909.2,221.7c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C906.6,220.6,907.8,221.7,909.2,221.7z"/>
	<path class="st0" d="M909.2,241.4c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C906.6,240.2,907.8,241.4,909.2,241.4z"/>
	<path class="st0" d="M909.2,231.6c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C906.6,230.4,907.8,231.6,909.2,231.6z"/>
	<path class="st0" d="M909.2,202.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C906.6,200.9,907.8,202.1,909.2,202.1z"/>
	<path class="st0" d="M909.2,192.2c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C906.6,191,907.8,192.2,909.2,192.2z"/>
	<path class="st0" d="M919.1,202.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C916.5,200.9,917.6,202.1,919.1,202.1z"/>
	<path class="st0" d="M909.2,211.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C906.6,210.7,907.8,211.9,909.2,211.9z"/>
	<path class="st0" d="M899.4,231.6c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C896.7,230.4,897.9,231.6,899.4,231.6z"/>
	<path class="st0" d="M899.4,202.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C896.7,200.9,897.9,202.1,899.4,202.1z"/>
	<path class="st0" d="M899.4,192.2c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C896.7,191,897.9,192.2,899.4,192.2z"/>
	<path class="st0" d="M899.4,221.7c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C896.7,220.6,897.9,221.7,899.4,221.7z"/>
	<path class="st0" d="M909.2,251.3c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C906.6,250.1,907.8,251.3,909.2,251.3z"/>
	<path class="st0" d="M938.8,251.3c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S937.3,251.3,938.8,251.3z"
		/>
	<path class="st0" d="M938.8,241.4c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S937.3,241.4,938.8,241.4z"
		/>
	<path class="st0" d="M938.8,231.6c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C936.2,230.4,937.3,231.6,938.8,231.6z"/>
	<path class="st0" d="M938.8,221.7c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C936.2,220.6,937.3,221.7,938.8,221.7z"/>
	<path class="st0" d="M948.6,251.3c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S947.2,251.3,948.6,251.3z"
		/>
	<path class="st0" d="M899.4,211.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C896.7,210.7,897.9,211.9,899.4,211.9z"/>
	<path class="st0" d="M869.8,428.4c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S868.4,428.4,869.8,428.4z"/>
	<path class="st0" d="M869.8,438.2c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S868.4,438.2,869.8,438.2z"/>
	<path class="st0" d="M879.7,438.2c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S878.2,438.2,879.7,438.2z"/>
	<path class="st0" d="M889.5,438.2c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S888,438.2,889.5,438.2z"/>
	<path class="st0" d="M879.7,467.7c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S878.2,467.7,879.7,467.7z"/>
	<path class="st0" d="M919.1,241.4c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S917.6,241.4,919.1,241.4z"
		/>
	<path class="st0" d="M958.5,457.9c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S957,457.9,958.5,457.9z"/>
	<path class="st0" d="M917.7,191.8L917.7,191.8L917.7,191.8L917.7,191.8z"/>
	<path class="st0" d="M948.6,448.1c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S947.2,448.1,948.6,448.1z"/>
	<path class="st0" d="M938.8,448.1c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S937.3,448.1,938.8,448.1z"/>
	<path class="st0" d="M938.8,438.2c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S937.3,438.2,938.8,438.2z"
		/>
	<path class="st0" d="M928.9,438.2c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C926.3,437,927.5,438.2,928.9,438.2z"/>
	<path class="st0" d="M879.7,423.1c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S881.1,423.1,879.7,423.1z"/>
	<circle class="st0" cx="879.7" cy="415.9" r="2.6"/>
	<path class="st0" d="M938.8,78.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C941.4,79.9,940.2,78.7,938.8,78.7z"/>
	<path class="st0" d="M938.8,68.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C941.4,70.1,940.2,68.9,938.8,68.9z"/>
	<path class="st0" d="M938.8,59c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S940.2,59,938.8,59z"/>
	<path class="st0" d="M938.8,98.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S940.2,98.4,938.8,98.4z"/>
	<path class="st0" d="M938.8,88.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C941.4,89.7,940.2,88.6,938.8,88.6z"/>
	<path class="st0" d="M879.7,314.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S881.1,314.9,879.7,314.9z"
		/>
	<path class="st0" d="M879.7,330c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C877,328.8,878.2,330,879.7,330z"/>
	<circle class="st0" cx="879.7" cy="307.7" r="2.6"/>
	<circle class="st0" cx="919.1" cy="307.7" r="2.6"/>
	<path class="st0" d="M909.2,408.7c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C906.6,407.5,907.8,408.7,909.2,408.7z"/>
	<path class="st0" d="M899.4,398.9c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S897.9,398.9,899.4,398.9z"/>
	<path class="st0" d="M899.4,408.7c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C896.7,407.5,897.9,408.7,899.4,408.7z"/>
	<path class="st0" d="M909.2,398.9c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S907.8,398.9,909.2,398.9z"/>
	<path class="st0" d="M919.1,221.7c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C916.5,220.6,917.6,221.7,919.1,221.7z"/>
	<path class="st0" d="M919.1,211.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S917.6,211.9,919.1,211.9z"
		/>
	<path class="st0" d="M928.9,211.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C926.3,210.7,927.5,211.9,928.9,211.9z"/>
	<path class="st0" d="M928.9,202.1c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S927.5,202.1,928.9,202.1z"/>
	<path class="st0" d="M919.1,295.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C921.7,296.4,920.5,295.2,919.1,295.2z"/>
	<path class="st0" d="M929,295.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C931.6,296.4,930.4,295.2,929,295.2z"/>
	<path class="st0" d="M929,285.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C931.6,286.5,930.4,285.4,929,285.4z"/>
	<path class="st0" d="M938.8,290.6c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S937.3,290.6,938.8,290.6z"
		/>
	<path class="st8" d="M938.8,280.8c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C936.2,279.6,937.3,280.8,938.8,280.8z"/>
	<path class="st0" d="M938.8,270.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C936.2,269.8,937.3,270.9,938.8,270.9z"/>
	<path class="st0" d="M938.8,261.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S937.3,261.1,938.8,261.1z"
		/>
	<path class="st0" d="M919.1,231.6c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C916.5,230.4,917.6,231.6,919.1,231.6z"/>
	<circle class="st0" cx="860" cy="179.8" r="2.6"/>
	<path class="st0" d="M988.1,541.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C990.7,542.4,989.5,541.2,988.1,541.2z"/>
	<path class="st0" d="M997.9,64.3c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6S996.5,64.3,997.9,64.3z"/>
	<path class="st0" d="M1007.8,64.3c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S1006.3,64.3,1007.8,64.3z"/>
	<path class="st0" d="M800.8,108.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C803.5,109.4,802.3,108.2,800.8,108.2z"/>
	<path class="st0" d="M800.8,118.1c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S802.3,118.1,800.8,118.1z"/>
	<path class="st0" d="M800.8,359.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S799.4,359.5,800.8,359.5z"
		/>
	<path class="st0" d="M800.8,147.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C803.5,148.8,802.3,147.6,800.8,147.6z"/>
	<path class="st0" d="M800.8,98.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S802.3,98.4,800.8,98.4z"/>
	<path class="st0" d="M800.8,137.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C803.5,138.9,802.3,137.8,800.8,137.8z"/>
	<path class="st0" d="M800.8,68.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C803.5,70.1,802.3,68.9,800.8,68.9z"/>
	<path class="st0" d="M810.7,68.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S812.1,68.9,810.7,68.9z"/>
	<path class="st0" d="M810.7,29.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S812.1,29.5,810.7,29.5z"/>
	<path class="st0" d="M800.8,59c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S802.3,59,800.8,59z"/>
	<path class="st0" d="M791,59c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S792.4,59,791,59z"/>
	<path class="st0" d="M800.8,78.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C803.5,79.9,802.3,78.7,800.8,78.7z"/>
	<path class="st0" d="M800.8,88.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C803.5,89.7,802.3,88.6,800.8,88.6z"/>
	<path class="st0" d="M800.8,127.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C803.5,129.1,802.3,127.9,800.8,127.9z"/>
	<path class="st0" d="M879.7,541.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S881.1,541.2,879.7,541.2z"
		/>
	<path class="st0" d="M879.7,551c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S881.1,551,879.7,551z"/>
	<path class="st0" d="M968.4,580.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C971,581.7,969.8,580.6,968.4,580.6z"/>
	<path class="st0" d="M879.7,531.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S881.1,531.4,879.7,531.4z"/>
	<path class="st0" d="M869.8,531.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S871.3,531.4,869.8,531.4z"/>
	<path class="st0" d="M869.8,560.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C872.4,562.1,871.3,560.9,869.8,560.9z"/>
	<path class="st0" d="M879.7,560.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C882.3,562.1,881.1,560.9,879.7,560.9z"/>
	<path class="st0" d="M879.7,521.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S881.1,521.5,879.7,521.5z"
		/>
	<path class="st0" d="M869.8,551c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C872.4,552.2,871.3,551,869.8,551z"/>
	<path class="st0" d="M988.1,531.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S989.5,531.4,988.1,531.4z"/>
	<path class="st0" d="M978.2,551c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S979.7,551,978.2,551z"/>
	<path class="st0" d="M988.1,551c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6C990.7,552.2,989.5,551,988.1,551z"
		/>
	<path class="st0" d="M978.2,560.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C980.8,562.1,979.7,560.9,978.2,560.9z"/>
	<path class="st0" d="M988.1,521.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C990.7,522.7,989.5,521.5,988.1,521.5z"/>
	<path class="st0" d="M988.1,511.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C990.7,512.9,989.5,511.7,988.1,511.7z"/>
	<path class="st0" d="M978.2,531.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S979.7,531.4,978.2,531.4z"/>
	<path class="st0" d="M978.2,511.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S979.7,511.7,978.2,511.7z"
		/>
	<path class="st0" d="M978.2,501.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S979.7,501.8,978.2,501.8z"/>
	<path class="st0" d="M978.2,541.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S979.7,541.2,978.2,541.2z"
		/>
	<path class="st0" d="M978.2,521.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S979.7,521.5,978.2,521.5z"
		/>
	<path class="st0" d="M869.8,541.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C872.4,542.4,871.3,541.2,869.8,541.2z"/>
	<path class="st0" d="M800.8,157.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S802.3,157.4,800.8,157.4z"/>
	<path class="st0" d="M1034.7,622.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6
		C1035.9,619.9,1034.7,621.1,1034.7,622.6z"/>
	<path class="st0" d="M1044.6,612.7c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6
		C1045.7,610.1,1044.6,611.3,1044.6,612.7z"/>
	<path class="st0" d="M1054.4,612.7c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6
		C1055.6,610.1,1054.4,611.3,1054.4,612.7z"/>
	<circle class="st0" cx="1057" cy="602.9" r="2.6"/>
	<path class="st0" d="M1057,595.7c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S1055.6,595.7,1057,595.7z"/>
	<circle class="st0" cx="1057" cy="583.2" r="2.6"/>
	<circle class="st0" cx="1066.9" cy="602.9" r="2.6"/>
	<path class="st0" d="M1027.5,635c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C1024.8,633.9,1026,635,1027.5,635z"/>
	<path class="st0" d="M1017.6,635c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C1015,633.9,1016.2,635,1017.6,635z"/>
	<path class="st0" d="M1027.5,644.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C1024.8,643.7,1026,644.9,1027.5,644.9z"/>
	<path class="st0" d="M1037.3,635c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C1034.7,633.9,1035.9,635,1037.3,635z"/>
	<path class="st0" d="M800.8,206.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C803.5,207.8,802.3,206.6,800.8,206.6z"/>
	<path class="st0" d="M800.8,216.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C803.5,217.7,802.3,216.5,800.8,216.5z"/>
	<path class="st0" d="M800.8,255.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C803.5,257,802.3,255.8,800.8,255.8z"/>
	<path class="st0" d="M800.8,236.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C803.5,237.3,802.3,236.2,800.8,236.2z"/>
	<path class="st0" d="M800.8,246c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C803.5,247.2,802.3,246,800.8,246z"/>
	<path class="st0" d="M800.8,226.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C803.5,227.5,802.3,226.3,800.8,226.3z"/>
	<path class="st0" d="M800.8,177.1c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C803.5,178.3,802.3,177.1,800.8,177.1z"/>
	<path class="st0" d="M800.8,196.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C803.5,198,802.3,196.8,800.8,196.8z"/>
	<path class="st0" d="M800.8,167.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C803.5,168.5,802.3,167.3,800.8,167.3z"/>
	<path class="st0" d="M800.8,187c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C803.5,188.1,802.3,187,800.8,187z"/>
	<path class="st0" d="M830.4,320.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C827.8,319,828.9,320.1,830.4,320.1z"/>
	<path class="st0" d="M800.8,324.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C803.5,325.9,802.3,324.7,800.8,324.7z"/>
	<path class="st0" d="M800.8,314.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C803.5,316.1,802.3,314.9,800.8,314.9z"/>
	<path class="st0" d="M800.8,334.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C803.5,335.7,802.3,334.6,800.8,334.6z"/>
	<path class="st0" d="M800.8,285.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C803.5,286.5,802.3,285.4,800.8,285.4z"/>
	<path class="st0" d="M800.8,265.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C803.5,266.9,802.3,265.7,800.8,265.7z"/>
	<path class="st0" d="M800.8,305c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C803.5,306.2,802.3,305,800.8,305z"/>
	<path class="st0" d="M800.8,275.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C803.5,276.7,802.3,275.5,800.8,275.5z"/>
	<path class="st0" d="M800.8,295.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C803.5,296.4,802.3,295.2,800.8,295.2z"/>
	<path class="st0" d="M800.8,344.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C803.5,345.6,802.3,344.4,800.8,344.4z"/>
	<path class="st0" d="M860,433c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S861.4,433,860,433z"/>
	<path class="st0" d="M860,423.1c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S861.4,423.1,860,423.1z"/>
	<path class="st0" d="M830.4,93.8c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C827.8,92.6,828.9,93.8,830.4,93.8z"/>
	<path class="st0" d="M830.4,103.7c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S828.9,103.7,830.4,103.7z"/>
	<path class="st0" d="M830.4,84c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6C827.8,82.8,828.9,84,830.4,84z
		"/>
	<path class="st0" d="M919.1,103.7c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S917.6,103.7,919.1,103.7z"/>
	<path class="st0" d="M919.1,113.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S917.6,113.5,919.1,113.5z"
		/>
	<path class="st0" d="M919.1,74.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C916.5,73,917.6,74.1,919.1,74.1z"/>
	<path class="st0" d="M919.1,84c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S917.6,84,919.1,84z"/>
	<path class="st0" d="M919.1,93.8c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C916.5,92.6,917.6,93.8,919.1,93.8z"/>
	<path class="st0" d="M919.1,152.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S917.6,152.9,919.1,152.9z"
		/>
	<path class="st0" d="M919.1,143c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S917.6,143,919.1,143z"/>
	<path class="st0" d="M919.1,123.3c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S917.6,123.3,919.1,123.3z"/>
	<path class="st0" d="M830.4,192.2c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C827.8,191,828.9,192.2,830.4,192.2z"/>
	<path class="st0" d="M830.4,251.3c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C827.8,250.1,828.9,251.3,830.4,251.3z"/>
	<path class="st0" d="M830.4,261.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C827.8,259.9,828.9,261.1,830.4,261.1z"/>
	<path class="st0" d="M830.4,221.7c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C827.8,220.6,828.9,221.7,830.4,221.7z"/>
	<path class="st0" d="M830.4,270.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C827.8,269.8,828.9,270.9,830.4,270.9z"/>
	<path class="st0" d="M830.4,241.4c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C827.8,240.2,828.9,241.4,830.4,241.4z"/>
	<path class="st0" d="M830.4,231.6c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C827.8,230.4,828.9,231.6,830.4,231.6z"/>
	<path class="st0" d="M830.4,310.3c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C827.8,309.1,828.9,310.3,830.4,310.3z"/>
	<path class="st0" d="M830.4,280.8c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C827.8,279.6,828.9,280.8,830.4,280.8z"/>
	<path class="st0" d="M830.4,300.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C827.8,299.3,828.9,300.5,830.4,300.5z"/>
	<path class="st0" d="M830.4,290.6c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C827.8,289.4,828.9,290.6,830.4,290.6z"/>
	<path class="st0" d="M830.4,133.2c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C827.8,132,828.9,133.2,830.4,133.2z"/>
	<path class="st0" d="M830.4,162.7c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S828.9,162.7,830.4,162.7z"/>
	<path class="st0" d="M830.4,152.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C827.8,151.7,828.9,152.9,830.4,152.9z"/>
	<path class="st0" d="M830.4,143c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C827.8,141.8,828.9,143,830.4,143z"/>
	<path class="st0" d="M830.4,123.3c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S828.9,123.3,830.4,123.3z"/>
	<path class="st0" d="M830.4,113.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C827.8,112.3,828.9,113.5,830.4,113.5z"/>
	<path class="st0" d="M909.2,152.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C906.6,151.7,907.8,152.9,909.2,152.9z"/>
	<path class="st0" d="M830.4,211.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C827.8,210.7,828.9,211.9,830.4,211.9z"/>
	<path class="st0" d="M830.4,202.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C827.8,200.9,828.9,202.1,830.4,202.1z"/>
	<path class="st0" d="M830.4,172.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C827.8,171.4,828.9,172.5,830.4,172.5z"/>
	<path class="st0" d="M830.4,182.4c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C827.8,181.2,828.9,182.4,830.4,182.4z"/>
	<path class="st0" d="M958.5,448.1c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S957,448.1,958.5,448.1z"/>
	<path class="st0" d="M860,172.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C857.3,171.4,858.5,172.5,860,172.5z"/>
	<path class="st0" d="M860,192.2c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C857.3,191,858.5,192.2,860,192.2z"/>
	<circle class="st0" cx="868.7" cy="167.1" r="2.5"/>
	<path class="st0" d="M850.8,145.3c1.5,0,2.7-1.2,2.7-2.7s-1.2-2.7-2.7-2.7s-2.7,1.2-2.7,2.7S849.3,145.3,850.8,145.3z"/>
	<path class="st0" d="M860,143c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6C857.3,141.8,858.5,143,860,143z
		"/>
	<path class="st0" d="M860,231.6c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C857.3,230.4,858.5,231.6,860,231.6z"/>
	<circle class="st0" cx="860" cy="238.8" r="2.6"/>
	<path class="st0" d="M860,211.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C857.3,210.7,858.5,211.9,860,211.9z"/>
	<circle class="st0" cx="860" cy="199.4" r="2.6"/>
	<path class="st0" d="M860,221.7c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C857.3,220.6,858.5,221.7,860,221.7z"/>
	<path class="st0" d="M860,84c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6C857.3,82.8,858.5,84,860,84z"/>
	<path class="st0" d="M860,133.2c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C857.3,132,858.5,133.2,860,133.2z"/>
	<path class="st0" d="M860,64.3c1.5,0,2.6-1.2,2.6-2.6S861.4,59,860,59c-1.5,0-2.6,1.2-2.6,2.6S858.5,64.3,860,64.3z"/>
	<path class="st0" d="M860,54.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C857.3,53.3,858.5,54.5,860,54.5z"/>
	<path class="st0" d="M817.9,425.8c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6
		C819.1,423.1,817.9,424.3,817.9,425.8z"/>
	<path class="st0" d="M860,74.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6C857.3,73,858.5,74.1,860,74.1z
		"/>
	<path class="st0" d="M860,123.3c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S858.5,123.3,860,123.3z"/>
	<path class="st0" d="M860,113.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C857.3,112.3,858.5,113.5,860,113.5z"/>
	<path class="st0" d="M860,93.8c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C857.3,92.6,858.5,93.8,860,93.8z"/>
	<path class="st0" d="M860,103.7c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S858.5,103.7,860,103.7z"/>
	<path class="st0" d="M869.8,521.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C872.4,522.7,871.3,521.5,869.8,521.5z"/>
	<path class="st0" d="M909.2,74.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C906.6,73,907.8,74.1,909.2,74.1z"/>
	<path class="st0" d="M909.2,84c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6C906.6,82.8,907.8,84,909.2,84z
		"/>
	<circle class="st0" cx="860" cy="347" r="2.6"/>
	<circle class="st0" cx="869.8" cy="347" r="2.6"/>
	<circle class="st0" cx="889.5" cy="347" r="2.6"/>
	<path class="st0" d="M889.5,369.3c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C886.9,368.2,888.1,369.3,889.5,369.3z"/>
	<circle class="st0" cx="889.5" cy="376.6" r="2.6"/>
	<path class="st0" d="M860,251.3c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C857.3,250.1,858.5,251.3,860,251.3z"/>
	<path class="st0" d="M909.2,143c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C906.6,141.8,907.8,143,909.2,143z"/>
	<path class="st0" d="M909.2,123.3c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S907.8,123.3,909.2,123.3z"/>
	<path class="st0" d="M909.2,103.7c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S907.8,103.7,909.2,103.7z"/>
	<path class="st0" d="M909.2,133.2c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C906.6,132,907.8,133.2,909.2,133.2z"/>
	<path class="st0" d="M909.2,113.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C906.6,112.3,907.8,113.5,909.2,113.5z"/>
	<path class="st0" d="M909.2,93.8c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C906.6,92.6,907.8,93.8,909.2,93.8z"/>
	<circle class="st0" cx="860" cy="268.3" r="2.6"/>
	<path class="st0" d="M860,290.6c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C857.3,289.4,858.5,290.6,860,290.6z"/>
	<path class="st0" d="M860,280.8c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C857.3,279.6,858.5,280.8,860,280.8z"/>
	<path class="st0" d="M899.4,280.8c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6
		C896.7,279.6,897.9,280.8,899.4,280.8z"/>
	<path class="st0" d="M899.4,290.6c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6
		C896.7,289.4,897.9,290.6,899.4,290.6z"/>
	<path class="st0" d="M860,261.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C857.3,259.9,858.5,261.1,860,261.1z"/>
	<path class="st0" d="M860,339.8c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C857.3,338.6,858.5,339.8,860,339.8z"/>
	<path class="st0" d="M860,330c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6C857.3,328.8,858.5,330,860,330z
		"/>
	<path class="st0" d="M860,320.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C857.3,319,858.5,320.1,860,320.1z"/>
	<circle class="st0" cx="860" cy="307.7" r="2.6"/>
	<path class="st0" d="M860,300.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C857.3,299.3,858.5,300.5,860,300.5z"/>
	<path class="st0" d="M919.1,133.2c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C916.5,132,917.6,133.2,919.1,133.2z"/>
	<path class="st0" d="M919.1,501.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S920.5,501.8,919.1,501.8z"/>
	<path class="st0" d="M827.8,435.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6
		C828.9,433,827.8,434.1,827.8,435.6z"/>
	<path class="st0" d="M827.8,445.4c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6
		C828.9,442.8,827.8,444,827.8,445.4z"/>
	<path class="st0" d="M837.6,435.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6S837.6,434.1,837.6,435.6z"/>
	<path class="st0" d="M919.1,511.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C921.7,512.9,920.5,511.7,919.1,511.7z"/>
	<path class="st0" d="M958.5,560.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C961.1,562.1,960,560.9,958.5,560.9z"/>
	<path class="st0" d="M961.1,583.2c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6
		C960,585.8,961.1,584.6,961.1,583.2z"/>
	<path class="st0" d="M961.1,602.9c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6
		C960,605.5,961.1,604.3,961.1,602.9z"/>
	<path class="st0" d="M971,602.9c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6S971,604.3,971,602.9z"/>
	<path class="st0" d="M961.1,612.7c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6
		C960,615.3,961.1,614.2,961.1,612.7z"/>
	<path class="st0" d="M958.5,551c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S960,551,958.5,551z"/>
	<path class="st0" d="M919.1,521.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C921.7,522.7,920.5,521.5,919.1,521.5z"/>
	<path class="st0" d="M919.1,531.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S920.5,531.4,919.1,531.4z"/>
	<path class="st0" d="M919.1,541.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C921.7,542.4,920.5,541.2,919.1,541.2z"/>
	<path class="st0" d="M919.1,492c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S920.5,492,919.1,492z"/>
	<path class="st0" d="M968.4,482.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C971,483.3,969.8,482.2,968.4,482.2z"/>
	<path class="st0" d="M968.4,462.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S969.8,462.5,968.4,462.5z"
		/>
	<path class="st0" d="M988.1,462.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C990.7,463.7,989.5,462.5,988.1,462.5z"/>
	<path class="st0" d="M968.4,501.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S969.8,501.8,968.4,501.8z"/>
	<circle class="st0" cx="968.4" cy="494.6" r="2.6"/>
	<circle class="st0" cx="958.5" cy="494.6" r="2.6"/>
	<path class="st0" d="M968.4,511.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S969.8,511.7,968.4,511.7z"
		/>
	<path class="st0" d="M958.5,511.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S960,511.7,958.5,511.7z"/>
	<path class="st0" d="M958.5,501.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S960,501.8,958.5,501.8z"/>
	<path class="st0" d="M958.5,541.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S960,541.2,958.5,541.2z"/>
	<path class="st0" d="M968.4,521.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S969.8,521.5,968.4,521.5z"
		/>
	<path class="st0" d="M958.5,531.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S960,531.4,958.5,531.4z"/>
	<path class="st0" d="M968.4,570.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S969.8,570.7,968.4,570.7z"
		/>
	<path class="st0" d="M978.2,570.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S979.7,570.7,978.2,570.7z"
		/>
	<path class="st0" d="M958.5,521.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S960,521.5,958.5,521.5z"/>
	<path class="st0" d="M968.4,531.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S969.8,531.4,968.4,531.4z"/>
	<path class="st0" d="M968.4,560.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C971,562.1,969.8,560.9,968.4,560.9z"/>
	<path class="st0" d="M968.4,541.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S969.8,541.2,968.4,541.2z"
		/>
	<path class="st0" d="M968.4,551c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S969.8,551,968.4,551z"/>
	<path class="st0" d="M810.7,349.7c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C808.1,348.5,809.2,349.7,810.7,349.7z"/>
	<path class="st0" d="M810.7,103.7c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S809.2,103.7,810.7,103.7z"/>
	<path class="st0" d="M810.7,93.8c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C808.1,92.6,809.2,93.8,810.7,93.8z"/>
	<path class="st0" d="M810.7,84c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6C808.1,82.8,809.2,84,810.7,84z
		"/>
	<path class="st0" d="M820.5,29.5c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S822,29.5,820.5,29.5z"/>
	<path class="st0" d="M820.5,19.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C823.2,20.8,822,19.7,820.5,19.7z"/>
	<path class="st0" d="M840.2,19.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C842.9,20.8,841.7,19.7,840.2,19.7z"/>
	<path class="st0" d="M830.4,19.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C833,20.8,831.8,19.7,830.4,19.7z"/>
	<path class="st0" d="M810.7,143c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C808.1,141.8,809.2,143,810.7,143z"/>
	<path class="st0" d="M810.7,133.2c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C808.1,132,809.2,133.2,810.7,133.2z"/>
	<path class="st0" d="M810.7,113.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C808.1,112.3,809.2,113.5,810.7,113.5z"/>
	<path class="st0" d="M810.7,123.3c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S809.2,123.3,810.7,123.3z"/>
	<path class="st0" d="M850.1,108.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C852.7,109.4,851.6,108.2,850.1,108.2z"/>
	<path class="st0" d="M810.7,152.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C808.1,151.7,809.2,152.9,810.7,152.9z"/>
	<path class="st0" d="M850.1,88.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C852.7,89.7,851.6,88.6,850.1,88.6z"/>
	<path class="st0" d="M850.1,98.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S851.6,98.4,850.1,98.4z"/>
	<path class="st0" d="M850.1,127.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C852.7,129.1,851.6,127.9,850.1,127.9z"/>
	<path class="st0" d="M850.1,118.1c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S851.6,118.1,850.1,118.1z"/>
	<path class="st0" d="M850.1,68.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C852.7,70.1,851.6,68.9,850.1,68.9z"/>
	<path class="st0" d="M850.1,78.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C852.7,79.9,851.6,78.7,850.1,78.7z"/>
	<path class="st0" d="M850.1,59c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S851.6,59,850.1,59z"/>
	<path class="st0" d="M840.3,59c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S841.7,59,840.3,59z"/>
	<path class="st0" d="M850.1,49.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C852.7,50.4,851.6,49.2,850.1,49.2z"/>
	<path class="st0" d="M840.3,49.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C842.9,50.4,841.7,49.2,840.3,49.2z"/>
	<path class="st0" d="M810.7,192.2c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C808.1,191,809.2,192.2,810.7,192.2z"/>
	<path class="st0" d="M810.7,310.3c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C808.1,309.1,809.2,310.3,810.7,310.3z"/>
	<path class="st0" d="M810.7,300.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C808.1,299.3,809.2,300.5,810.7,300.5z"/>
	<path class="st0" d="M810.7,290.6c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C808.1,289.4,809.2,290.6,810.7,290.6z"/>
	<path class="st0" d="M810.7,320.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C808.1,319,809.2,320.1,810.7,320.1z"/>
	<path class="st0" d="M810.7,280.8c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C808.1,279.6,809.2,280.8,810.7,280.8z"/>
	<path class="st0" d="M810.7,330c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C808.1,328.8,809.2,330,810.7,330z"/>
	<path class="st0" d="M810.7,270.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C808.1,269.8,809.2,270.9,810.7,270.9z"/>
	<path class="st0" d="M810.7,162.7c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S809.2,162.7,810.7,162.7z"/>
	<path class="st0" d="M810.7,339.8c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C808.1,338.6,809.2,339.8,810.7,339.8z"/>
	<path class="st0" d="M810.7,359.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C808.1,358.3,809.2,359.5,810.7,359.5z"/>
	<path class="st0" d="M810.7,211.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C808.1,210.7,809.2,211.9,810.7,211.9z"/>
	<path class="st0" d="M810.7,261.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C808.1,259.9,809.2,261.1,810.7,261.1z"/>
	<path class="st0" d="M810.7,172.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C808.1,171.4,809.2,172.5,810.7,172.5z"/>
	<path class="st0" d="M810.7,182.4c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C808.1,181.2,809.2,182.4,810.7,182.4z"/>
	<path class="st0" d="M810.7,202.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C808.1,200.9,809.2,202.1,810.7,202.1z"/>
	<path class="st0" d="M810.7,251.3c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C808.1,250.1,809.2,251.3,810.7,251.3z"/>
	<path class="st0" d="M810.7,241.4c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C808.1,240.2,809.2,241.4,810.7,241.4z"/>
	<path class="st0" d="M810.7,231.6c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C808.1,230.4,809.2,231.6,810.7,231.6z"/>
	<path class="st0" d="M810.7,221.7c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C808.1,220.6,809.2,221.7,810.7,221.7z"/>
	<path class="st0" d="M938.8,531.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S940.2,531.4,938.8,531.4z"/>
	<path class="st0" d="M938.8,560.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C941.4,562.1,940.2,560.9,938.8,560.9z"/>
	<path class="st0" d="M938.8,570.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C941.4,571.9,940.2,570.7,938.8,570.7z"/>
	<path class="st0" d="M909.2,501.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S910.7,501.8,909.2,501.8z"/>
	<path class="st0" d="M938.8,551c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C941.4,552.2,940.2,551,938.8,551z"/>
	<path class="st0" d="M850.1,137.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C852.7,138.9,851.6,137.8,850.1,137.8z"/>
	<path class="st0" d="M938.8,482.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C941.4,483.3,940.2,482.2,938.8,482.2z"/>
	<path class="st0" d="M938.8,472.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C941.4,473.5,940.2,472.3,938.8,472.3z"/>
	<path class="st0" d="M928.9,472.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C931.6,473.5,930.4,472.3,928.9,472.3z"/>
	<path class="st0" d="M909.3,482.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C911.9,483.3,910.7,482.2,909.3,482.2z"/>
	<path class="st0" d="M938.8,492c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S940.2,492,938.8,492z"/>
	<path class="st0" d="M948.6,492c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S950.1,492,948.6,492z"/>
	<path class="st0" d="M938.8,521.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C941.4,522.7,940.2,521.5,938.8,521.5z"/>
	<path class="st0" d="M938.8,511.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C941.4,512.9,940.2,511.7,938.8,511.7z"/>
	<path class="st0" d="M938.8,501.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S940.2,501.8,938.8,501.8z"/>
	<path class="st0" d="M928.9,531.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S930.4,531.4,928.9,531.4z"/>
	<path class="st0" d="M928.9,521.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C931.6,522.7,930.4,521.5,928.9,521.5z"/>
	<path class="st0" d="M928.9,541.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C931.6,542.4,930.4,541.2,928.9,541.2z"/>
	<path class="st0" d="M909.2,492c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S910.7,492,909.2,492z"/>
	<path class="st0" d="M899.4,492c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S900.8,492,899.4,492z"/>
	<path class="st0" d="M928.9,556.3c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C926.3,555.1,927.5,556.3,928.9,556.3z"/>
	<path class="st0" d="M928.9,566.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C926.3,565,927.5,566.1,928.9,566.1z"/>
	<path class="st0" d="M928.9,511.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C931.6,512.9,930.4,511.7,928.9,511.7z"/>
	<path class="st0" d="M928.9,492c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S930.4,492,928.9,492z"/>
	<path class="st0" d="M928.9,482.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C931.6,483.3,930.4,482.2,928.9,482.2z"/>
	<path class="st0" d="M919.1,482.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C921.7,483.3,920.5,482.2,919.1,482.2z"/>
	<path class="st0" d="M928.9,501.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S930.4,501.8,928.9,501.8z"/>
	<path class="st0" d="M938.8,541.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C941.4,542.4,940.2,541.2,938.8,541.2z"/>
	<path class="st0" d="M1007.8,68.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C1010.4,70.1,1009.2,68.9,1007.8,68.9z"/>
	<path class="st0" d="M948.6,570.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C951.3,571.9,950.1,570.7,948.6,570.7z"/>
	<path class="st0" d="M948.6,541.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C951.3,542.4,950.1,541.2,948.6,541.2z"/>
	<path class="st0" d="M948.6,551c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C951.3,552.2,950.1,551,948.6,551z"/>
	<path class="st0" d="M948.6,560.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C951.3,562.1,950.1,560.9,948.6,560.9z"/>
	<path class="st0" d="M948.6,521.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C951.3,522.7,950.1,521.5,948.6,521.5z"/>
	<path class="st0" d="M948.6,511.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C951.3,512.9,950.1,511.7,948.6,511.7z"/>
	<path class="st0" d="M948.6,501.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S950.1,501.8,948.6,501.8z"/>
	<path class="st0" d="M948.6,531.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S950.1,531.4,948.6,531.4z"/>
	<path class="st0" d="M948.6,585.8c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S947.2,585.8,948.6,585.8z"
		/>
	<path class="st0" d="M810.7,369.3c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C808.1,368.2,809.2,369.3,810.7,369.3z"/>
	<path class="st0" d="M810.7,418.5c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S809.2,418.5,810.7,418.5z"/>
	<path class="st0" d="M820.5,418.5c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S819.1,418.5,820.5,418.5z"/>
	<path class="st0" d="M800.8,369.3c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6
		C798.2,368.2,799.4,369.3,800.8,369.3z"/>
	<path class="st0" d="M840.2,442.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S841.7,442.8,840.2,442.8z"/>
	<path class="st0" d="M869.8,442.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S871.3,442.8,869.8,442.8z"/>
	<path class="st0" d="M889.5,442.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S890.9,442.8,889.5,442.8z"/>
	<path class="st0" d="M889.5,462.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S890.9,462.5,889.5,462.5z"
		/>
	<path class="st0" d="M899.4,438.2c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6S897.9,438.2,899.4,438.2z"/>
	<path class="st0" d="M899.4,442.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S900.8,442.8,899.4,442.8z"/>
	<path class="st0" d="M840.2,452.6c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S841.7,452.6,840.2,452.6z"/>
	<path class="st0" d="M850.1,452.6c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S851.6,452.6,850.1,452.6z"/>
	<path class="st0" d="M860,452.6c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S861.4,452.6,860,452.6z"/>
	<path class="st0" d="M919.1,59c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S920.5,59,919.1,59z"/>
	<path class="st0" d="M909.2,59c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S910.7,59,909.2,59z"/>
	<path class="st0" d="M899.4,511.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S900.8,511.7,899.4,511.7z"
		/>
	<path class="st0" d="M899.4,501.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S900.8,501.8,899.4,501.8z"/>
	<path class="st0" d="M889.5,501.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S891,501.8,889.5,501.8z"/>
	<path class="st0" d="M899.4,556.3c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C896.7,555.1,897.9,556.3,899.4,556.3z"/>
	<path class="st0" d="M909.2,556.3c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C906.6,555.1,907.8,556.3,909.2,556.3z"/>
	<path class="st0" d="M919.1,556.3c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S917.6,556.3,919.1,556.3z"
		/>
	<path class="st0" d="M899.4,541.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S900.8,541.2,899.4,541.2z"
		/>
	<path class="st0" d="M909.2,511.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C911.9,512.9,910.7,511.7,909.2,511.7z"/>
	<path class="st0" d="M899.4,531.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S900.8,531.4,899.4,531.4z"/>
	<path class="st0" d="M909.2,541.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C911.9,542.4,910.7,541.2,909.2,541.2z"/>
	<path class="st0" d="M909.2,521.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C911.9,522.7,910.7,521.5,909.2,521.5z"/>
	<path class="st0" d="M909.2,531.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S910.7,531.4,909.2,531.4z"/>
	<path class="st0" d="M899.4,521.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S900.8,521.5,899.4,521.5z"
		/>
	<path class="st0" d="M889.5,531.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S891,531.4,889.5,531.4z"/>
	<path class="st0" d="M879.7,511.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S881.1,511.7,879.7,511.7z"
		/>
	<path class="st0" d="M889.5,541.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S891,541.2,889.5,541.2z"/>
	<path class="st0" d="M889.5,521.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S891,521.5,889.5,521.5z"/>
	<path class="st0" d="M889.5,556.3c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S888.1,556.3,889.5,556.3z"
		/>
	<path class="st0" d="M889.5,511.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S891,511.7,889.5,511.7z"/>
	<path class="st0" d="M869.8,511.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C872.4,512.9,871.3,511.7,869.8,511.7z"/>
	<path class="st0" d="M948.6,123.3c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S947.2,123.3,948.6,123.3z"/>
	<path class="st0" d="M948.6,113.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S947.2,113.5,948.6,113.5z"
		/>
	<path class="st0" d="M948.6,84c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S947.2,84,948.6,84z"/>
	<path class="st0" d="M850.1,147.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C852.7,148.8,851.6,147.6,850.1,147.6z"/>
	<path class="st0" d="M948.6,103.7c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S947.2,103.7,948.6,103.7z"/>
	<path class="st0" d="M948.6,93.8c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C946,92.6,947.2,93.8,948.6,93.8z"/>
	<path class="st0" d="M928.9,64.3c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S927.5,64.3,928.9,64.3z"/>
	<path class="st0" d="M928.9,74.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C926.3,73,927.5,74.1,928.9,74.1z"/>
	<path class="st0" d="M928.9,49.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C931.6,50.4,930.4,49.2,928.9,49.2z"/>
	<path class="st0" d="M889.5,64.3c1.5,0,2.6-1.2,2.6-2.6S891,59,889.5,59s-2.6,1.2-2.6,2.6S888.1,64.3,889.5,64.3z"/>
	<path class="st0" d="M958.5,84c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S957,84,958.5,84z"/>
	<path class="st0" d="M958.5,93.8c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C955.9,92.6,957,93.8,958.5,93.8z"/>
	<path class="st0" d="M958.5,54.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S957,54.5,958.5,54.5z"/>
	<path class="st0" d="M948.6,74.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C946,73,947.2,74.1,948.6,74.1z"/>
	<path class="st0" d="M958.5,74.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C955.9,73,957,74.1,958.5,74.1z"/>
	<path class="st0" d="M948.6,54.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S947.2,54.5,948.6,54.5z"/>
	<path class="st0" d="M958.5,113.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S957,113.5,958.5,113.5z"/>
	<path class="st0" d="M948.6,64.3c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S947.2,64.3,948.6,64.3z"/>
	<path class="st0" d="M899.4,143c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C896.7,141.8,897.9,143,899.4,143z"/>
	<path class="st0" d="M899.4,133.2c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C896.7,132,897.9,133.2,899.4,133.2z"/>
	<path class="st0" d="M899.4,123.3c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S897.9,123.3,899.4,123.3z"/>
	<path class="st0" d="M899.4,103.7c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S897.9,103.7,899.4,103.7z"/>
	<path class="st0" d="M899.4,113.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C896.7,112.3,897.9,113.5,899.4,113.5z"/>
	<path class="st0" d="M889.5,74.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C886.9,73,888.1,74.1,889.5,74.1z"/>
	<path class="st0" d="M899.4,172.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C896.7,171.4,897.9,172.5,899.4,172.5z"/>
	<path class="st0" d="M899.4,152.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C896.7,151.7,897.9,152.9,899.4,152.9z"/>
	<path class="st0" d="M899.4,93.8c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C896.7,92.6,897.9,93.8,899.4,93.8z"/>
	<path class="st0" d="M928.9,93.8c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C926.3,92.6,927.5,93.8,928.9,93.8z"/>
	<path class="st0" d="M899.4,162.7c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S897.9,162.7,899.4,162.7z"/>
	<path class="st0" d="M928.9,143c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C926.3,141.8,927.5,143,928.9,143z"/>
	<path class="st0" d="M938.8,143c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S937.3,143,938.8,143z"/>
	<path class="st0" d="M958.5,143c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S957,143,958.5,143z"/>
	<path class="st0" d="M899.4,84c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6C896.7,82.8,897.9,84,899.4,84z
		"/>
	<path class="st0" d="M928.9,133.2c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C926.3,132,927.5,133.2,928.9,133.2z"/>
	<path class="st0" d="M938.8,133.2c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C936.2,132,937.3,133.2,938.8,133.2z"/>
	<path class="st0" d="M928.9,103.7c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S927.5,103.7,928.9,103.7z"/>
	<path class="st0" d="M928.9,113.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C926.3,112.3,927.5,113.5,928.9,113.5z"/>
	<path class="st0" d="M928.9,123.3c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S927.5,123.3,928.9,123.3z"/>
	<path class="st0" d="M899.4,74.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C896.7,73,897.9,74.1,899.4,74.1z"/>
	<path class="st0" d="M899.4,64.3c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S897.9,64.3,899.4,64.3z"/>
	<path class="st0" d="M958.5,64.3c1.5,0,2.6-1.2,2.6-2.6S960,59,958.5,59s-2.6,1.2-2.6,2.6S957,64.3,958.5,64.3z"/>
	<path class="st0" d="M958.5,103.7c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S957,103.7,958.5,103.7z"/>
	<path class="st0" d="M978.2,123.3c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S976.8,123.3,978.2,123.3z"/>
	<path class="st0" d="M978.2,74.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C975.6,73,976.8,74.1,978.2,74.1z"/>
	<path class="st0" d="M997.9,74.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C995.3,73,996.4,74.1,997.9,74.1z"/>
	<path class="st0" d="M988,74.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6C985.4,73,986.6,74.1,988,74.1z
		"/>
	<path class="st0" d="M988.1,64.3c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6S986.6,64.3,988.1,64.3z"/>
	<path class="st0" d="M978.2,103.7c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S976.8,103.7,978.2,103.7z"/>
	<path class="st0" d="M978.2,84c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6C975.6,82.8,976.8,84,978.2,84z
		"/>
	<path class="st0" d="M988.1,84c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6C985.4,82.8,986.6,84,988.1,84z"/>
	<path class="st0" d="M978.2,113.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C975.6,112.3,976.8,113.5,978.2,113.5z"/>
	<path class="st0" d="M988.1,113.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6
		C985.4,112.3,986.6,113.5,988.1,113.5z"/>
	<path class="st0" d="M978.2,93.8c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C975.6,92.6,976.8,93.8,978.2,93.8z"/>
	<path class="st0" d="M988,93.8c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C985.4,92.6,986.6,93.8,988,93.8z"/>
	<path class="st0" d="M978.2,64.3c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S976.8,64.3,978.2,64.3z"/>
	<path class="st0" d="M978.2,54.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C975.6,53.3,976.8,54.5,978.2,54.5z"/>
	<path class="st0" d="M980.8,455.3c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6
		C979.7,457.9,980.8,456.7,980.8,455.3z"/>
	<circle class="st0" cx="988.1" cy="455.3" r="2.6"/>
	<circle class="st0" cx="968.4" cy="455.3" r="2.6"/>
	<circle class="st0" cx="968.4" cy="445.4" r="2.6"/>
	<path class="st0" d="M988.1,98.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S989.5,98.4,988.1,98.4z"/>
	<path class="st0" d="M997.9,98.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S999.4,98.4,997.9,98.4z"/>
	<path class="st0" d="M968.4,113.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C965.7,112.3,966.9,113.5,968.4,113.5z"/>
	<path class="st0" d="M968.4,172.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C965.7,171.4,966.9,172.5,968.4,172.5z"/>
	<path class="st0" d="M968.4,192.2c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C965.7,191,966.9,192.2,968.4,192.2z"/>
	<path class="st0" d="M958.5,172.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C955.9,171.4,957,172.5,958.5,172.5z"/>
	<path class="st0" d="M978.2,172.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C975.6,171.4,976.8,172.5,978.2,172.5z"/>
	<circle class="st0" cx="968.4" cy="179.8" r="2.6"/>
	<circle class="st0" cx="968.4" cy="160.1" r="2.6"/>
	<path class="st0" d="M978.2,162.7c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S976.8,162.7,978.2,162.7z"/>
	<path class="st0" d="M958.5,162.7c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S957,162.7,958.5,162.7z"/>
	<path class="st0" d="M968.4,123.3c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S966.9,123.3,968.4,123.3z"/>
	<path class="st0" d="M968.4,74.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C965.7,73,966.9,74.1,968.4,74.1z"/>
	<circle class="st0" cx="968.4" cy="51.8" r="2.6"/>
	<path class="st0" d="M968.4,64.3c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S966.9,64.3,968.4,64.3z"/>
	<path class="st0" d="M968.4,84c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6C965.7,82.8,966.9,84,968.4,84z
		"/>
	<path class="st0" d="M968.4,103.7c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S966.9,103.7,968.4,103.7z"/>
	<path class="st0" d="M968.4,93.8c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C965.7,92.6,966.9,93.8,968.4,93.8z"/>
	<path class="st0" d="M968.4,133.2c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C965.7,132,966.9,133.2,968.4,133.2z"/>
	<path class="st0" d="M968.4,143c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C965.7,141.8,966.9,143,968.4,143z"/>
	<path class="st0" d="M958.5,152.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S957,152.9,958.5,152.9z"/>
	<path class="st0" d="M968.4,152.8c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C965.7,151.7,966.9,152.8,968.4,152.8z"/>
	<path class="st0" d="M928.9,84c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6C926.3,82.8,927.5,84,928.9,84z
		"/>
	<path class="st0" d="M869.8,211.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S868.4,211.9,869.8,211.9z"
		/>
	<circle class="st0" cx="869.8" cy="199.4" r="2.6"/>
	<path class="st0" d="M869.8,221.7c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C867.2,220.6,868.4,221.7,869.8,221.7z"/>
	<circle class="st0" cx="869.8" cy="179.8" r="2.6"/>
	<path class="st0" d="M869.8,192.2c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S868.4,192.2,869.8,192.2z"
		/>
	<circle class="st0" cx="869.8" cy="268.3" r="2.6"/>
	<path class="st0" d="M869.8,261.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S868.4,261.1,869.8,261.1z"
		/>
	<path class="st0" d="M869.8,231.6c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C867.2,230.4,868.4,231.6,869.8,231.6z"/>
	<path class="st0" d="M869.8,172.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C867.2,171.4,868.4,172.5,869.8,172.5z"/>
	<circle class="st0" cx="869.8" cy="238.8" r="2.6"/>
	<path class="st0" d="M869.8,251.3c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S868.4,251.3,869.8,251.3z"
		/>
	<path class="st0" d="M869.8,74.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C867.2,73,868.4,74.1,869.8,74.1z"/>
	<path class="st0" d="M869.8,93.8c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C867.2,92.6,868.4,93.8,869.8,93.8z"/>
	<path class="st0" d="M869.8,103.7c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S868.4,103.7,869.8,103.7z"/>
	<path class="st0" d="M869.8,64.3c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S868.4,64.3,869.8,64.3z"/>
	<path class="st0" d="M869.8,84c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S868.4,84,869.8,84z"/>
	<path class="st0" d="M869.8,123.3c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S868.4,123.3,869.8,123.3z"/>
	<path class="st0" d="M869.8,143c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S868.4,143,869.8,143z"/>
	<circle class="st0" cx="869.8" cy="160.1" r="2.6"/>
	<path class="st0" d="M869.8,152.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S868.4,152.9,869.8,152.9z"
		/>
	<path class="st0" d="M869.8,133.2c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C867.2,132,868.4,133.2,869.8,133.2z"/>
	<path class="st0" d="M869.8,113.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S868.4,113.5,869.8,113.5z"
		/>
	<path class="st0" d="M850.1,206.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C852.7,207.8,851.6,206.6,850.1,206.6z"/>
	<path class="st0" d="M850.1,216.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C852.7,217.7,851.6,216.5,850.1,216.5z"/>
	<path class="st0" d="M850.1,226.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C852.7,227.5,851.6,226.3,850.1,226.3z"/>
	<path class="st0" d="M869.8,339.8c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C867.2,338.6,868.4,339.8,869.8,339.8z"/>
	<path class="st0" d="M879.7,339.8c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C877,338.6,878.2,339.8,879.7,339.8z"/>
	<path class="st0" d="M850.1,236.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C852.7,237.3,851.6,236.2,850.1,236.2z"/>
	<path class="st0" d="M850.1,196.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C852.7,198,851.6,196.8,850.1,196.8z"/>
	<path class="st0" d="M850.1,157.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S851.6,157.4,850.1,157.4z"/>
	<path class="st0" d="M889.5,84c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S888.1,84,889.5,84z"/>
	<path class="st0" d="M850.1,167.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C852.7,168.5,851.6,167.3,850.1,167.3z"/>
	<path class="st0" d="M850.1,177.1c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C852.7,178.3,851.6,177.1,850.1,177.1z"/>
	<path class="st0" d="M850.1,246c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C852.7,247.2,851.6,246,850.1,246z"/>
	<path class="st0" d="M850.1,324.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C852.7,325.9,851.6,324.7,850.1,324.7z"/>
	<path class="st0" d="M850.1,305c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C852.7,306.2,851.6,305,850.1,305z"/>
	<path class="st0" d="M850.1,314.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C852.7,316.1,851.6,314.9,850.1,314.9z"/>
	<path class="st0" d="M852.7,347c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6
		C851.6,349.7,852.7,348.5,852.7,347z"/>
	<path class="st0" d="M850.1,334.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C852.7,335.7,851.6,334.6,850.1,334.6z"/>
	<path class="st0" d="M850.1,285.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C852.7,286.5,851.6,285.4,850.1,285.4z"/>
	<path class="st0" d="M850.1,275.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C852.7,276.7,851.6,275.5,850.1,275.5z"/>
	<path class="st0" d="M850.1,265.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C852.7,266.9,851.6,265.7,850.1,265.7z"/>
	<path class="st0" d="M850.1,255.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C852.7,257,851.6,255.8,850.1,255.8z"/>
	<path class="st0" d="M850.1,295.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C852.7,296.4,851.6,295.2,850.1,295.2z"/>
	<path class="st0" d="M850.1,187c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C852.7,188.1,851.6,187,850.1,187z"/>
	<path class="st0" d="M889.5,231.6c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C886.9,230.4,888.1,231.6,889.5,231.6z"/>
	<path class="st0" d="M889.5,221.7c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C886.9,220.6,888.1,221.7,889.5,221.7z"/>
	<circle class="st0" cx="889.5" cy="238.8" r="2.6"/>
	<path class="st0" d="M889.5,192.2c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S888.1,192.2,889.5,192.2z"
		/>
	<path class="st0" d="M889.5,211.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S888.1,211.9,889.5,211.9z"
		/>
	<circle class="st0" cx="889.5" cy="199.4" r="2.6"/>
	<circle class="st0" cx="889.5" cy="268.3" r="2.6"/>
	<circle class="st9" cx="899.4" cy="268.3" r="2.6"/>
	<path class="st0" d="M889.5,251.3c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S888.1,251.3,889.5,251.3z"
		/>
	<path class="st0" d="M889.5,261.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S888.1,261.1,889.5,261.1z"
		/>
	<path class="st0" d="M889.5,143c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S888.1,143,889.5,143z"/>
	<path class="st0" d="M889.5,123.3c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S888.1,123.3,889.5,123.3z"/>
	<path class="st0" d="M889.5,133.2c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C886.9,132,888.1,133.2,889.5,133.2z"/>
	<path class="st0" d="M889.5,113.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S888.1,113.5,889.5,113.5z"
		/>
	<path class="st0" d="M889.5,103.7c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S888.1,103.7,889.5,103.7z"/>
	<path class="st0" d="M889.5,93.8c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C886.9,92.6,888.1,93.8,889.5,93.8z"/>
	<path class="st0" d="M889.5,172.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C886.9,171.4,888.1,172.5,889.5,172.5z"/>
	<circle class="st0" cx="889.5" cy="160.1" r="2.6"/>
	<path class="st0" d="M879.7,74.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C877,73,878.2,74.1,879.7,74.1z"/>
	<circle class="st0" cx="889.5" cy="179.8" r="2.6"/>
	<path class="st0" d="M889.5,152.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S888.1,152.9,889.5,152.9z"
		/>
	<path class="st0" d="M879.7,113.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C877,112.3,878.2,113.5,879.7,113.5z"/>
	<path class="st0" d="M879.7,211.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C877,210.7,878.2,211.9,879.7,211.9z"/>
	<path class="st0" d="M879.7,64.3c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S878.2,64.3,879.7,64.3z"/>
	<circle class="st0" cx="879.7" cy="179.8" r="2.6"/>
	<path class="st0" d="M879.7,172.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C877,171.4,878.2,172.5,879.7,172.5z"/>
	<path class="st0" d="M879.7,221.7c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C877,220.6,878.2,221.7,879.7,221.7z"/>
	<path class="st0" d="M879.7,192.2c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C877,191,878.2,192.2,879.7,192.2z"/>
	<path class="st0" d="M879.7,251.3c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C877,250.1,878.2,251.3,879.7,251.3z"/>
	<path class="st0" d="M879.7,261.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C877,259.9,878.2,261.1,879.7,261.1z"/>
	<path class="st0" d="M879.7,231.6c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C877,230.4,878.2,231.6,879.7,231.6z"/>
	<circle class="st0" cx="879.7" cy="238.8" r="2.6"/>
	<circle class="st0" cx="879.7" cy="199.4" r="2.6"/>
	<circle class="st0" cx="879.7" cy="160.1" r="2.6"/>
	<circle class="st0" cx="879.7" cy="268.3" r="2.6"/>
	<path class="st0" d="M879.7,93.8c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C877,92.6,878.2,93.8,879.7,93.8z"/>
	<path class="st0" d="M879.7,84c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6C877,82.8,878.2,84,879.7,84z"
		/>
	<path class="st0" d="M879.7,103.7c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S878.2,103.7,879.7,103.7z"/>
	<path class="st0" d="M879.7,143c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C877,141.8,878.2,143,879.7,143z"/>
	<path class="st0" d="M879.7,123.3c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S878.2,123.3,879.7,123.3z"/>
	<path class="st0" d="M879.7,152.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C877,151.7,878.2,152.9,879.7,152.9z"/>
	<path class="st0" d="M879.7,133.2c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C877,132,878.2,133.2,879.7,133.2z"/>
	<path class="st0" d="M958.5,570.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S960,570.7,958.5,570.7z"/>
	<path class="st0" d="M249,127.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6C251.6,129.1,250.4,127.9,249,127.9z
		"/>
	<path class="st0" d="M239.1,137.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C241.8,138.9,240.6,137.8,239.1,137.8z"/>
	<path class="st0" d="M209.6,221.7c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C206.9,220.6,208.1,221.7,209.6,221.7z"/>
	<path class="st0" d="M239.1,147.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C241.8,148.8,240.6,147.6,239.1,147.6z"/>
	<path class="st0" d="M239.1,157.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C241.8,158.6,240.6,157.4,239.1,157.4z"/>
	<circle class="st0" cx="268.7" cy="179.8" r="2.6"/>
	<path class="st0" d="M268.7,187c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6C271.3,188.1,270.1,187,268.7,187z"
		/>
	<path class="st0" d="M268.7,206.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C271.3,207.8,270.1,206.6,268.7,206.6z"/>
	<circle class="st0" cx="268.7" cy="199.4" r="2.6"/>
	<path class="st0" d="M258.8,98.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S260.3,98.4,258.8,98.4z"/>
	<path class="st0" d="M258.8,88.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C261.5,89.7,260.3,88.6,258.8,88.6z"/>
	<path class="st0" d="M268.7,88.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6C271.3,89.7,270.1,88.6,268.7,88.6z
		"/>
	<path class="st0" d="M268.7,123.3c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6S267.2,123.3,268.7,123.3z"/>
	<path class="st0" d="M258.8,108.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C261.5,109.4,260.3,108.2,258.8,108.2z"/>
	<path class="st0" d="M258.8,118.1c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S260.3,118.1,258.8,118.1z"/>
	<path class="st0" d="M209.6,211.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C206.9,210.7,208.1,211.9,209.6,211.9z"/>
	<path class="st0" d="M199.7,300.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C197.1,299.3,198.3,300.5,199.7,300.5z"/>
	<path class="st0" d="M268.7,113.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6S267.2,113.5,268.7,113.5z"/>
	<path class="st0" d="M199.7,290.6c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C197.1,289.4,198.3,290.6,199.7,290.6z"/>
	<path class="st0" d="M199.7,270.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C197.1,269.8,198.3,270.9,199.7,270.9z"/>
	<path class="st0" d="M199.7,261.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C197.1,259.9,198.3,261.1,199.7,261.1z"/>
	<path class="st0" d="M199.7,280.8c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C197.1,279.6,198.3,280.8,199.7,280.8z"/>
	<circle class="st0" cx="209.6" cy="199.4" r="2.6"/>
	<path class="st0" d="M268.7,226.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C271.3,227.5,270.1,226.3,268.7,226.3z"/>
	<path class="st0" d="M199.7,310.3c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C197.1,309.1,198.3,310.3,199.7,310.3z"/>
	<path class="st0" d="M199.7,251.3c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C197.1,250.1,198.3,251.3,199.7,251.3z"/>
	<path class="st0" d="M239.1,93.8c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6C236.5,92.6,237.7,93.8,239.1,93.8z
		"/>
	<path class="st0" d="M239.1,84c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6S237.7,84,239.1,84z"/>
	<path class="st0" d="M249,84c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6S247.5,84,249,84z"/>
	<path class="st0" d="M278.6,84c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6C275.9,82.8,277.1,84,278.6,84z
		"/>
	<path class="st0" d="M249,74.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6C246.4,73,247.5,74.1,249,74.1z"/>
	<path class="st0" d="M197.1,189.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6S197.1,188.1,197.1,189.6z"/>
	<path class="st0" d="M268.7,98.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S270.1,98.4,268.7,98.4z"/>
	<path class="st0" d="M199.7,202.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C197.1,200.9,198.3,202.1,199.7,202.1z"/>
	<path class="st0" d="M199.7,231.6c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C197.1,230.4,198.3,231.6,199.7,231.6z"/>
	<path class="st0" d="M199.7,221.7c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C197.1,220.6,198.3,221.7,199.7,221.7z"/>
	<path class="st0" d="M199.7,241.4c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C197.1,240.2,198.3,241.4,199.7,241.4z"/>
	<path class="st0" d="M199.7,211.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C197.1,210.7,198.3,211.9,199.7,211.9z"/>
	<path class="st0" d="M170.2,206.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S171.6,206.6,170.2,206.6z"
		/>
	<path class="st0" d="M170.2,226.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S171.6,226.3,170.2,226.3z"
		/>
	<path class="st0" d="M268.7,216.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C271.3,217.7,270.1,216.5,268.7,216.5z"/>
	<path class="st0" d="M170.2,246c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S171.6,246,170.2,246z"/>
	<path class="st0" d="M170.2,236.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C172.8,237.3,171.6,236.2,170.2,236.2z"/>
	<path class="st0" d="M170.2,196.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S171.6,196.8,170.2,196.8z"
		/>
	<path class="st0" d="M170.2,157.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S171.6,157.4,170.2,157.4z"/>
	<path class="st0" d="M170.2,167.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S171.6,167.3,170.2,167.3z"
		/>
	<path class="st0" d="M170.2,255.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S171.6,255.8,170.2,255.8z"
		/>
	<path class="st0" d="M170.2,187c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C172.8,188.1,171.6,187,170.2,187z"/>
	<path class="st0" d="M170.2,177.1c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S171.6,177.1,170.2,177.1z"
		/>
	<path class="st0" d="M170.2,305c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S171.6,305,170.2,305z"/>
	<path class="st0" d="M170.2,265.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C172.8,266.9,171.6,265.7,170.2,265.7z"/>
	<path class="st0" d="M160.3,118.1c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S161.8,118.1,160.3,118.1z"/>
	<path class="st0" d="M160.3,108.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C162.9,109.4,161.8,108.2,160.3,108.2z"/>
	<path class="st0" d="M170.2,88.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C172.8,89.7,171.6,88.6,170.2,88.6z"/>
	<path class="st0" d="M170.2,314.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S171.6,314.9,170.2,314.9z"
		/>
	<path class="st0" d="M170.2,285.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C172.8,286.5,171.6,285.4,170.2,285.4z"/>
	<path class="st0" d="M170.2,275.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S171.6,275.5,170.2,275.5z"
		/>
	<path class="st0" d="M170.2,295.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C172.8,296.4,171.6,295.2,170.2,295.2z"/>
	<path class="st0" d="M170.2,216.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S171.6,216.5,170.2,216.5z"
		/>
	<path class="st0" d="M170.2,147.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S171.6,147.6,170.2,147.6z"
		/>
	<path class="st0" d="M199.7,84c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6C197.1,82.8,198.3,84,199.7,84z
		"/>
	<path class="st0" d="M236.5,366.7c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6S236.5,365.3,236.5,366.7z"/>
	<path class="st0" d="M246.4,366.7c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6S246.4,365.3,246.4,366.7z"/>
	<path class="st0" d="M199.7,103.7c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S198.3,103.7,199.7,103.7z"/>
	<path class="st0" d="M199.7,113.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C197.1,112.3,198.3,113.5,199.7,113.5z"/>
	<path class="st0" d="M199.7,93.8c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C197.1,92.6,198.3,93.8,199.7,93.8z"/>
	<path class="st0" d="M170.2,118.1c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S171.6,118.1,170.2,118.1z"/>
	<path class="st0" d="M170.2,137.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C172.8,138.9,171.6,137.8,170.2,137.8z"/>
	<path class="st0" d="M170.2,127.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C172.8,129.1,171.6,127.9,170.2,127.9z"/>
	<path class="st0" d="M275.9,258.5c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6S275.9,257,275.9,258.5z"/>
	<path class="st0" d="M285.8,258.5c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6S285.8,257,285.8,258.5z"/>
	<path class="st0" d="M219.4,290.6c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C216.8,289.4,218,290.6,219.4,290.6z"/>
	<path class="st0" d="M268.7,255.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C271.3,257,270.1,255.8,268.7,255.8z"/>
	<path class="st0" d="M229.3,364.1c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C231.9,365.3,230.7,364.1,229.3,364.1z"/>
	<path class="st0" d="M199.7,320.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C197.1,319,198.3,320.1,199.7,320.1z"/>
	<path class="st0" d="M239.1,226.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C241.8,227.5,240.6,226.3,239.1,226.3z"/>
	<path class="st0" d="M239.1,246c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6C241.8,247.2,240.6,246,239.1,246z"
		/>
	<path class="st0" d="M199.7,162.7c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S198.3,162.7,199.7,162.7z"/>
	<path class="st0" d="M239.1,265.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C241.8,266.9,240.6,265.7,239.1,265.7z"/>
	<path class="st0" d="M239.1,255.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C241.8,257,240.6,255.8,239.1,255.8z"/>
	<circle class="st0" cx="209.6" cy="160.1" r="2.6"/>
	<path class="st0" d="M209.6,137.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C212.2,138.9,211,137.8,209.6,137.8z"/>
	<path class="st0" d="M209.6,147.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C212.2,148.8,211,147.6,209.6,147.6z"/>
	<path class="st0" d="M199.7,127.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C202.3,129.1,201.2,127.9,199.7,127.9z"/>
	<path class="st0" d="M199.7,172.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C197.1,171.4,198.3,172.5,199.7,172.5z"/>
	<path class="st0" d="M209.6,127.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C212.2,129.1,211,127.9,209.6,127.9z"/>
	<path class="st0" d="M239.1,236.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C241.8,237.3,240.6,236.2,239.1,236.2z"/>
	<path class="st0" d="M258.8,206.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C261.5,207.8,260.3,206.6,258.8,206.6z"/>
	<path class="st0" d="M258.8,196.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C261.5,198,260.3,196.8,258.8,196.8z"/>
	<path class="st0" d="M258.8,216.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C261.5,217.7,260.3,216.5,258.8,216.5z"/>
	<path class="st0" d="M258.8,236.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C261.5,237.3,260.3,236.2,258.8,236.2z"/>
	<path class="st0" d="M268.7,236.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C271.3,237.3,270.1,236.2,268.7,236.2z"/>
	<path class="st0" d="M239.1,216.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C241.8,217.7,240.6,216.5,239.1,216.5z"/>
	<path class="st0" d="M258.8,177.1c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C261.5,178.3,260.3,177.1,258.8,177.1z"/>
	<path class="st0" d="M268.7,246c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6C271.3,247.2,270.1,246,268.7,246z"
		/>
	<path class="st0" d="M258.8,187c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C261.5,188.1,260.3,187,258.8,187z"/>
	<path class="st0" d="M258.8,255.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C261.5,257,260.3,255.8,258.8,255.8z"/>
	<path class="st0" d="M258.8,226.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C261.5,227.5,260.3,226.3,258.8,226.3z"/>
	<path class="st0" d="M249,226.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S250.4,226.3,249,226.3z"/>
	<path class="st0" d="M249,236.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6C251.6,237.3,250.4,236.2,249,236.2z
		"/>
	<path class="st0" d="M249,246c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S250.4,246,249,246z"/>
	<path class="st0" d="M258.8,246c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C261.5,247.2,260.3,246,258.8,246z"/>
	<path class="st0" d="M249,265.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6C251.6,266.9,250.4,265.7,249,265.7z
		"/>
	<path class="st0" d="M249,255.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S250.4,255.8,249,255.8z"/>
	<path class="st0" d="M249,196.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S250.4,196.8,249,196.8z"/>
	<path class="st0" d="M249,187c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6C251.6,188.1,250.4,187,249,187z"/>
	<path class="st0" d="M249,216.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S250.4,216.5,249,216.5z"/>
	<path class="st0" d="M249,206.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S250.4,206.6,249,206.6z"/>
	<path class="st0" d="M219.4,241.4c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C216.8,240.2,218,241.4,219.4,241.4z"/>
	<path class="st0" d="M219.4,251.3c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C216.8,250.1,218,251.3,219.4,251.3z"/>
	<path class="st0" d="M219.4,211.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C216.8,210.7,218,211.9,219.4,211.9z"/>
	<path class="st0" d="M219.4,231.6c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C216.8,230.4,218,231.6,219.4,231.6z"/>
	<path class="st0" d="M209.6,231.6c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C206.9,230.4,208.1,231.6,209.6,231.6z"/>
	<path class="st0" d="M160.3,127.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C162.9,129.1,161.8,127.9,160.3,127.9z"/>
	<path class="st0" d="M219.4,300.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C216.8,299.3,218,300.5,219.4,300.5z"/>
	<path class="st0" d="M219.4,221.7c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C216.8,220.6,218,221.7,219.4,221.7z"/>
	<path class="st0" d="M219.4,280.8c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C216.8,279.6,218,280.8,219.4,280.8z"/>
	<path class="st0" d="M199.7,143c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C197.1,141.8,198.3,143,199.7,143z"/>
	<path class="st0" d="M219.4,261.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C216.8,259.9,218,261.1,219.4,261.1z"/>
	<circle class="st0" cx="209.6" cy="268.3" r="2.6"/>
	<path class="st0" d="M209.6,261.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C206.9,259.9,208.1,261.1,209.6,261.1z"/>
	<path class="st0" d="M209.6,280.8c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C206.9,279.6,208.1,280.8,209.6,280.8z"/>
	<path class="st0" d="M209.6,251.3c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C206.9,250.1,208.1,251.3,209.6,251.3z"/>
	<circle class="st0" cx="209.6" cy="238.8" r="2.6"/>
	<path class="st0" d="M209.6,290.6c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C206.9,289.4,208.1,290.6,209.6,290.6z"/>
	<circle class="st0" cx="209.6" cy="307.7" r="2.6"/>
	<circle class="st0" cx="209.6" cy="317.5" r="2.6"/>
	<path class="st0" d="M209.6,330c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C206.9,328.8,208.1,330,209.6,330z"/>
	<circle class="st0" cx="209.6" cy="337.2" r="2.6"/>
	<circle class="st0" cx="209.6" cy="356.9" r="2.6"/>
	<path class="st0" d="M229.3,359.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C226.6,358.3,227.8,359.5,229.3,359.5z"/>
	<path class="st0" d="M209.6,300.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C206.9,299.3,208.1,300.5,209.6,300.5z"/>
	<path class="st0" d="M219.4,270.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C216.8,269.8,218,270.9,219.4,270.9z"/>
	<path class="st0" d="M229.3,143c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C226.6,141.8,227.8,143,229.3,143z"/>
	<path class="st0" d="M229.3,152.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C226.6,151.7,227.8,152.9,229.3,152.9z"/>
	<path class="st0" d="M229.3,123.3c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S227.8,123.3,229.3,123.3z"/>
	<path class="st0" d="M249,123.3c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S247.6,123.3,249,123.3z"/>
	<path class="st0" d="M229.3,133.2c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C226.6,132,227.8,133.2,229.3,133.2z"/>
	<path class="st0" d="M199.7,152.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C197.1,151.7,198.3,152.9,199.7,152.9z"/>
	<path class="st0" d="M229.3,113.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C226.6,112.3,227.8,113.5,229.3,113.5z"/>
	<path class="st10" d="M229.3,285.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C231.9,286.5,230.7,285.4,229.3,285.4z"/>
	<path class="st0" d="M229.3,246c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C231.9,247.2,230.7,246,229.3,246z"/>
	<path class="st0" d="M229.3,103.7c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S227.8,103.7,229.3,103.7z"/>
	<path class="st0" d="M229.3,265.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C231.9,266.9,230.7,265.7,229.3,265.7z"/>
	<path class="st0" d="M229.3,236.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C231.9,237.3,230.7,236.2,229.3,236.2z"/>
	<path class="st0" d="M229.3,275.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C231.9,276.7,230.7,275.5,229.3,275.5z"/>
	<path class="st11" d="M239.1,275.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C241.8,276.7,240.6,275.5,239.1,275.5z"/>
	<path class="st0" d="M229.3,255.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C231.9,257,230.7,255.8,229.3,255.8z"/>
	<path class="st0" d="M229.3,84c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6C226.6,82.8,227.8,84,229.3,84z
		"/>
	<path class="st0" d="M229.3,93.8c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C226.6,92.6,227.8,93.8,229.3,93.8z"/>
	<path class="st0" d="M229.3,226.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C231.9,227.5,230.7,226.3,229.3,226.3z"/>
	<path class="st0" d="M219.4,359.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C216.8,358.3,218,359.5,219.4,359.5z"/>
	<path class="st0" d="M258.8,551c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C261.5,552.2,260.3,551,258.8,551z"/>
	<path class="st0" d="M258.8,541.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C261.5,542.4,260.3,541.2,258.8,541.2z"/>
	<path class="st0" d="M258.8,560.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C261.5,562.1,260.3,560.9,258.8,560.9z"/>
	<path class="st0" d="M258.8,570.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C261.5,571.9,260.3,570.7,258.8,570.7z"/>
	<path class="st0" d="M258.8,531.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S260.3,531.4,258.8,531.4z"/>
	<path class="st0" d="M258.8,492c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S260.3,492,258.8,492z"/>
	<path class="st0" d="M258.8,501.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S260.3,501.8,258.8,501.8z"/>
	<path class="st0" d="M258.8,580.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C261.5,581.7,260.3,580.6,258.8,580.6z"/>
	<path class="st0" d="M268.7,580.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C271.3,581.7,270.1,580.6,268.7,580.6z"/>
	<path class="st0" d="M258.8,521.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C261.5,522.7,260.3,521.5,258.8,521.5z"/>
	<path class="st0" d="M258.8,511.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C261.5,512.9,260.3,511.7,258.8,511.7z"/>
	<path class="st0" d="M288.4,452.6c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S289.9,452.6,288.4,452.6z"/>
	<path class="st0" d="M288.4,423.1c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S289.9,423.1,288.4,423.1z"/>
	<path class="st0" d="M288.4,433c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S289.9,433,288.4,433z"/>
	<path class="st0" d="M288.4,442.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S289.9,442.8,288.4,442.8z"/>
	<path class="st0" d="M258.8,590.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C261.5,591.6,260.3,590.4,258.8,590.4z"/>
	<path class="st0" d="M268.7,590.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C271.3,591.6,270.1,590.4,268.7,590.4z"/>
	<path class="st0" d="M258.8,629.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C261.5,630.9,260.3,629.8,258.8,629.8z"/>
	<path class="st0" d="M258.8,482.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C261.5,483.3,260.3,482.2,258.8,482.2z"/>
	<path class="st0" d="M258.8,610.1c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C261.5,611.3,260.3,610.1,258.8,610.1z"/>
	<path class="st0" d="M258.8,600.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C261.5,601.4,260.3,600.2,258.8,600.2z"/>
	<path class="st0" d="M258.8,639.6c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S260.3,639.6,258.8,639.6z"/>
	<path class="st0" d="M268.7,639.6c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S270.1,639.6,268.7,639.6z"/>
	<path class="st0" d="M268.7,649.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S270.1,649.4,268.7,649.4z"/>
	<circle class="st0" cx="278.6" cy="652.1" r="2.6"/>
	<path class="st0" d="M288.4,462.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S289.9,462.5,288.4,462.5z"/>
	<path class="st0" d="M258.8,619.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C261.5,621.1,260.3,619.9,258.8,619.9z"/>
	<path class="st0" d="M239.1,462.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C241.8,463.7,240.6,462.5,239.1,462.5z"/>
	<path class="st0" d="M239.1,413.3c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S240.6,413.3,239.1,413.3z"/>
	<path class="st0" d="M239.1,433c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S240.6,433,239.1,433z"/>
	<path class="st0" d="M239.1,442.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S240.6,442.8,239.1,442.8z"/>
	<path class="st0" d="M239.1,452.6c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S240.6,452.6,239.1,452.6z"/>
	<path class="st0" d="M239.1,423.1c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S240.6,423.1,239.1,423.1z"/>
	<path class="st0" d="M160.3,137.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C162.9,138.9,161.8,137.8,160.3,137.8z"/>
	<path class="st0" d="M229.3,472.3c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S230.7,472.3,229.3,472.3z"/>
	<path class="st0" d="M219.4,472.3c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S220.9,472.3,219.4,472.3z"/>
	<path class="st0" d="M239.1,403.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C241.8,404.6,240.6,403.4,239.1,403.4z"/>
	<path class="st0" d="M239.1,393.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C241.8,394.8,240.6,393.6,239.1,393.6z"/>
	<path class="st0" d="M258.8,393.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C261.5,394.8,260.3,393.6,258.8,393.6z"/>
	<path class="st0" d="M258.8,433c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S260.3,433,258.8,433z"/>
	<path class="st0" d="M258.8,442.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S260.3,442.8,258.8,442.8z"/>
	<path class="st0" d="M258.8,452.6c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S260.3,452.6,258.8,452.6z"/>
	<path class="st0" d="M258.8,462.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C261.5,463.7,260.3,462.5,258.8,462.5z"/>
	<path class="st0" d="M258.8,423.1c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S260.3,423.1,258.8,423.1z"/>
	<path class="st0" d="M258.8,413.3c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S260.3,413.3,258.8,413.3z"/>
	<path class="st0" d="M239.1,472.3c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S240.6,472.3,239.1,472.3z"/>
	<path class="st0" d="M258.8,472.3c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S260.3,472.3,258.8,472.3z"/>
	<path class="st0" d="M258.8,403.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C261.5,404.6,260.3,403.4,258.8,403.4z"/>
	<path class="st0" d="M239.1,482.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C241.8,483.3,240.6,482.2,239.1,482.2z"/>
	<path class="st0" d="M327.8,452.6c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S329.3,452.6,327.8,452.6z"/>
	<path class="st0" d="M318,24.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6C315.3,23.8,316.5,24.9,318,24.9z"/>
	<path class="st0" d="M327.8,442.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S329.3,442.8,327.8,442.8z"/>
	<path class="st0" d="M327.8,462.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C330.5,463.7,329.3,462.5,327.8,462.5z"/>
	<path class="st0" d="M229.3,462.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C231.9,463.7,230.7,462.5,229.3,462.5z"/>
	<path class="st0" d="M318,15.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6C315.3,13.9,316.5,15.1,318,15.1z"/>
	<path class="st0" d="M318,5.3c1.5,0,2.6-1.2,2.6-2.6S319.4,0,318,0s-2.6,1.2-2.6,2.6S316.5,5.3,318,5.3z"/>
	<path class="st0" d="M327.8,5.3c1.5,0,2.6-1.2,2.6-2.6S329.3,0,327.8,0s-2.6,1.2-2.6,2.6S326.4,5.3,327.8,5.3z"/>
	<path class="st0" d="M327.8,472.3c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S329.3,472.3,327.8,472.3z"/>
	<path class="st0" d="M327.8,15.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6C325.2,13.9,326.4,15.1,327.8,15.1z
		"/>
	<path class="st0" d="M318,34.8c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6C315.4,33.6,316.5,34.8,318,34.8z"/>
	<path class="st0" d="M327.8,482.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C330.5,483.3,329.3,482.2,327.8,482.2z"/>
	<path class="st0" d="M327.8,24.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6C325.2,23.8,326.4,24.9,327.8,24.9z
		"/>
	<path class="st0" d="M337.7,24.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6C335,23.8,336.2,24.9,337.7,24.9z"
		/>
	<path class="st0" d="M347.5,24.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C344.9,23.8,346.1,24.9,347.5,24.9z"/>
	<path class="st0" d="M347.5,9.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6C350.2,11,349,9.8,347.5,9.8z"
		/>
	<path class="st0" d="M337.7,15.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6S335,11,335,12.5C335,13.9,336.2,15.1,337.7,15.1z"/>
	<path class="st0" d="M337.7,5.3c1.5,0,2.6-1.2,2.6-2.6S339.1,0,337.7,0S335,1.2,335,2.6S336.2,5.3,337.7,5.3z"/>
	<path class="st0" d="M327.8,492c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S329.3,492,327.8,492z"/>
	<path class="st0" d="M327.8,501.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S329.3,501.8,327.8,501.8z"/>
	<path class="st0" d="M298.3,462.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S299.7,462.5,298.3,462.5z"
		/>
	<path class="st0" d="M298.3,452.6c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S299.7,452.6,298.3,452.6z"/>
	<path class="st0" d="M288.4,541.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S289.9,541.2,288.4,541.2z"/>
	<path class="st0" d="M298.3,442.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S299.7,442.8,298.3,442.8z"/>
	<path class="st0" d="M298.3,433c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S299.7,433,298.3,433z"/>
	<path class="st0" d="M288.4,521.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S289.9,521.5,288.4,521.5z"/>
	<path class="st0" d="M288.4,531.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S289.9,531.4,288.4,531.4z"/>
	<path class="st0" d="M288.4,482.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C291,483.3,289.9,482.2,288.4,482.2z"/>
	<path class="st0" d="M327.8,34.8c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6C325.2,33.6,326.4,34.8,327.8,34.8z"/>
	<path class="st0" d="M288.4,501.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S289.9,501.8,288.4,501.8z"/>
	<path class="st0" d="M288.4,511.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S289.9,511.7,288.4,511.7z"/>
	<path class="st0" d="M288.4,492c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S289.9,492,288.4,492z"/>
	<path class="st0" d="M298.3,521.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S299.7,521.5,298.3,521.5z"
		/>
	<path class="st0" d="M298.3,541.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S299.7,541.2,298.3,541.2z"
		/>
	<path class="st0" d="M298.3,531.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S299.7,531.4,298.3,531.4z"/>
	<path class="st0" d="M298.3,511.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S299.7,511.7,298.3,511.7z"
		/>
	<path class="st0" d="M298.3,472.3c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S299.7,472.3,298.3,472.3z"/>
	<path class="st0" d="M288.4,472.3c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S289.9,472.3,288.4,472.3z"/>
	<path class="st0" d="M298.3,482.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C300.9,483.3,299.7,482.2,298.3,482.2z"/>
	<path class="st0" d="M298.3,492c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S299.7,492,298.3,492z"/>
	<path class="st0" d="M298.3,501.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S299.7,501.8,298.3,501.8z"/>
	<path class="st0" d="M229.3,482.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C231.9,483.3,230.7,482.2,229.3,482.2z"/>
	<path class="st0" d="M180,226.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S181.5,226.3,180,226.3z"/>
	<path class="st0" d="M180,255.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S181.5,255.8,180,255.8z"/>
	<path class="st0" d="M180,216.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S181.5,216.5,180,216.5z"/>
	<path class="st0" d="M180,246c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S181.5,246,180,246z"/>
	<path class="st0" d="M180,157.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S181.5,157.4,180,157.4z"/>
	<path class="st0" d="M180,206.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S181.5,206.6,180,206.6z"/>
	<path class="st0" d="M180,167.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S181.5,167.3,180,167.3z"/>
	<path class="st0" d="M180,177.1c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S181.5,177.1,180,177.1z"/>
	<path class="st0" d="M180,196.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S181.5,196.8,180,196.8z"/>
	<path class="st0" d="M180,187c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6C182.6,188.1,181.5,187,180,187z
		"/>
	<path class="st0" d="M180,236.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C182.6,237.3,181.5,236.2,180,236.2z"/>
	<circle class="st0" cx="268.7" cy="415.9" r="2.6"/>
	<path class="st0" d="M180,305c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S181.5,305,180,305z"/>
	<path class="st0" d="M268.7,403.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C271.3,404.6,270.1,403.4,268.7,403.4z"/>
	<path class="st0" d="M180,295.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C182.6,296.4,181.5,295.2,180,295.2z"/>
	<path class="st0" d="M180,275.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S181.5,275.5,180,275.5z"/>
	<path class="st0" d="M180,285.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C182.6,286.5,181.5,285.4,180,285.4z"/>
	<path class="st0" d="M268.7,423.1c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S270.1,423.1,268.7,423.1z"/>
	<path class="st0" d="M180,314.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S181.5,314.9,180,314.9z"/>
	<path class="st0" d="M180,265.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C182.6,266.9,181.5,265.7,180,265.7z"/>
	<path class="st0" d="M160.3,196.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C162.9,198,161.8,196.8,160.3,196.8z"/>
	<path class="st0" d="M160.3,206.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C162.9,207.8,161.8,206.6,160.3,206.6z"/>
	<path class="st0" d="M160.3,187c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C162.9,188.1,161.8,187,160.3,187z"/>
	<path class="st0" d="M160.3,216.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C162.9,217.7,161.8,216.5,160.3,216.5z"/>
	<path class="st0" d="M160.3,226.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C162.9,227.5,161.8,226.3,160.3,226.3z"/>
	<path class="st0" d="M180,147.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S181.5,147.6,180,147.6z"/>
	<path class="st0" d="M160.3,147.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C162.9,148.8,161.8,147.6,160.3,147.6z"/>
	<path class="st0" d="M160.3,157.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S161.8,157.4,160.3,157.4z"/>
	<path class="st0" d="M160.3,167.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C162.9,168.5,161.8,167.3,160.3,167.3z"/>
	<path class="st0" d="M160.3,236.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C162.9,237.3,161.8,236.2,160.3,236.2z"/>
	<path class="st0" d="M160.3,177.1c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C162.9,178.3,161.8,177.1,160.3,177.1z"/>
	<path class="st0" d="M160.3,295.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C162.9,296.4,161.8,295.2,160.3,295.2z"/>
	<path class="st0" d="M160.3,246c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C162.9,247.2,161.8,246,160.3,246z"/>
	<path class="st0" d="M180,118.1c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S181.5,118.1,180,118.1z"/>
	<path class="st0" d="M180,137.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C182.6,138.9,181.5,137.8,180,137.8z"/>
	<path class="st0" d="M180,127.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C182.6,129.1,181.5,127.9,180,127.9z"/>
	<path class="st0" d="M160.3,305c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C162.9,306.2,161.8,305,160.3,305z"/>
	<path class="st0" d="M160.3,255.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C162.9,257,161.8,255.8,160.3,255.8z"/>
	<path class="st0" d="M160.3,265.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C162.9,266.9,161.8,265.7,160.3,265.7z"/>
	<path class="st0" d="M160.3,275.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C162.9,276.7,161.8,275.5,160.3,275.5z"/>
	<path class="st0" d="M160.3,285.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C162.9,286.5,161.8,285.4,160.3,285.4z"/>
	<path class="st0" d="M229.3,403.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C231.9,404.6,230.7,403.4,229.3,403.4z"/>
	<path class="st0" d="M271.3,61.7c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6S271.3,63.1,271.3,61.7z"/>
	<path class="st0" d="M288.4,34.8c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6C285.8,33.6,287,34.8,288.4,34.8z"/>
	<path class="st0" d="M288.4,15.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6S287,15.1,288.4,15.1z"/>
	<path class="st0" d="M278.6,64.3c1.5,0,2.6-1.2,2.6-2.6S280,59,278.6,59s-2.6,1.2-2.6,2.6S277.1,64.3,278.6,64.3z"/>
	<path class="st0" d="M278.6,54.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6C275.9,53.3,277.1,54.5,278.6,54.5z
		"/>
	<path class="st0" d="M278.6,44.6c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6S276,40.6,276,42C275.9,43.5,277.1,44.6,278.6,44.6z"
		/>
	<path class="st0" d="M288.4,24.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6C285.8,23.8,287,24.9,288.4,24.9z"
		/>
	<path class="st0" d="M288.4,44.6c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6C285.8,43.4,287,44.6,288.4,44.6z"
		/>
	<path class="st0" d="M288.4,64.3c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6S287,64.3,288.4,64.3z"/>
	<path class="st0" d="M288.4,54.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6S287,54.5,288.4,54.5z"/>
	<path class="st0" d="M229.3,393.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C231.9,394.8,230.7,393.6,229.3,393.6z"/>
	<path class="st0" d="M229.3,452.6c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S230.7,452.6,229.3,452.6z"/>
	<path class="st0" d="M229.3,413.3c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S230.7,413.3,229.3,413.3z"/>
	<path class="st0" d="M229.3,423.1c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S230.7,423.1,229.3,423.1z"/>
	<path class="st0" d="M229.3,433c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S230.7,433,229.3,433z"/>
	<path class="st0" d="M229.3,442.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S230.7,442.8,229.3,442.8z"/>
	<path class="st0" d="M305.5,248.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6S305.5,247.2,305.5,248.6z"/>
	<path class="st0" d="M192.5,110.9c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6c0,0.3,0.1,0.6,0.1,0.9l4.4,0.9
		C192.2,112.2,192.5,111.5,192.5,110.9z"/>
	<path class="st0" d="M268.7,482.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C271.3,483.3,270.1,482.2,268.7,482.2z"/>
	<path class="st0" d="M268.7,531.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S270.1,531.4,268.7,531.4z"/>
	<path class="st0" d="M268.7,501.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S270.1,501.8,268.7,501.8z"/>
	<path class="st0" d="M268.7,511.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C271.3,512.9,270.1,511.7,268.7,511.7z"/>
	<path class="st0" d="M268.7,521.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C271.3,522.7,270.1,521.5,268.7,521.5z"/>
	<circle class="st0" cx="268.7" cy="494.6" r="2.6"/>
	<circle class="st0" cx="268.7" cy="475" r="2.6"/>
	<circle class="st0" cx="268.7" cy="455.3" r="2.6"/>
	<path class="st0" d="M268.7,442.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S270.1,442.8,268.7,442.8z"/>
	<path class="st0" d="M268.7,462.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C271.3,463.7,270.1,462.5,268.7,462.5z"/>
	<path class="st0" d="M298.3,216.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S299.7,216.5,298.3,216.5z"
		/>
	<path class="st0" d="M298.3,226.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S299.7,226.3,298.3,226.3z"
		/>
	<path class="st0" d="M298.3,246c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S299.7,246,298.3,246z"/>
	<path class="st0" d="M298.3,236.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C300.9,237.3,299.7,236.2,298.3,236.2z"/>
	<path class="st0" d="M268.7,541.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C271.3,542.4,270.1,541.2,268.7,541.2z"/>
	<path class="st0" d="M268.7,433c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S270.1,433,268.7,433z"/>
	<path class="st0" d="M268.7,551c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6C271.3,552.2,270.1,551,268.7,551z"
		/>
	<path class="st0" d="M268.7,570.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C271.3,571.9,270.1,570.7,268.7,570.7z"/>
	<path class="st0" d="M268.7,560.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C271.3,562.1,270.1,560.9,268.7,560.9z"/>
	<circle class="st0" cx="209.6" cy="51.8" r="2.6"/>
	<path class="st0" d="M308.1,462.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S309.6,462.5,308.1,462.5z"
		/>
	<path class="st0" d="M281.2,229c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6S281.2,230.4,281.2,229z"/>
	<path class="st0" d="M160.3,74.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C157.7,73,158.8,74.1,160.3,74.1z"/>
	<path class="st0" d="M219.4,49.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C222.1,50.4,220.9,49.2,219.4,49.2z"/>
	<path class="st0" d="M308.1,433c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S309.6,433,308.1,433z"/>
	<path class="st0" d="M308.1,442.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S309.6,442.8,308.1,442.8z"/>
	<path class="st0" d="M308.1,452.6c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S309.6,452.6,308.1,452.6z"/>
	<circle class="st0" cx="278.6" cy="32.2" r="2.6"/>
	<circle class="st0" cx="258.8" cy="32.2" r="2.6"/>
	<circle class="st0" cx="249" cy="32.2" r="2.6"/>
	<circle class="st0" cx="229.3" cy="32.2" r="2.6"/>
	<circle class="st0" cx="219.4" cy="32.2" r="2.6"/>
	<path class="st0" d="M209.6,39.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C212.2,40.5,211,39.4,209.6,39.4z"/>
	<path class="st0" d="M140.6,236.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C143.2,237.3,142,236.2,140.6,236.2z"/>
	<path class="st0" d="M140.6,226.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C143.2,227.5,142,226.3,140.6,226.3z"/>
	<path class="st0" d="M140.6,216.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C143.2,217.7,142,216.5,140.6,216.5z"/>
	<path class="st0" d="M140.6,255.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C143.2,257,142,255.8,140.6,255.8z"/>
	<path class="st0" d="M140.6,265.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C143.2,266.9,142,265.7,140.6,265.7z"/>
	<path class="st0" d="M140.6,246c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C143.2,247.2,142,246,140.6,246z"/>
	<path class="st0" d="M140.6,177.1c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C143.2,178.3,142,177.1,140.6,177.1z"/>
	<path class="st0" d="M140.6,187c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C143.2,188.1,142,187,140.6,187z"/>
	<path class="st0" d="M140.6,206.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C143.2,207.8,142,206.6,140.6,206.6z"/>
	<path class="st0" d="M140.6,196.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C143.2,198,142,196.8,140.6,196.8z"/>
	<path class="st0" d="M140.6,334.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C143.2,335.7,142,334.6,140.6,334.6z"/>
	<path class="st0" d="M140.6,354.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C143.2,355.4,142,354.2,140.6,354.2z"/>
	<path class="st0" d="M140.6,324.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C143.2,325.9,142,324.7,140.6,324.7z"/>
	<path class="st0" d="M140.6,344.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C143.2,345.6,142,344.4,140.6,344.4z"/>
	<path class="st0" d="M140.6,314.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C143.2,316.1,142,314.9,140.6,314.9z"/>
	<path class="st0" d="M140.6,285.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C143.2,286.5,142,285.4,140.6,285.4z"/>
	<path class="st0" d="M140.6,295.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C143.2,296.4,142,295.2,140.6,295.2z"/>
	<path class="st0" d="M140.6,305c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C143.2,306.2,142,305,140.6,305z"/>
	<path class="st0" d="M140.6,275.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C143.2,276.7,142,275.5,140.6,275.5z"/>
	<path class="st0" d="M249,462.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S250.4,462.5,249,462.5z"/>
	<path class="st0" d="M308.1,472.3c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S309.6,472.3,308.1,472.3z"/>
	<path class="st0" d="M278.6,428.4c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6S277.1,428.4,278.6,428.4z"/>
	<path class="st0" d="M278.6,448.1c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6S277.1,448.1,278.6,448.1z"/>
	<path class="st0" d="M278.6,438.2c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6S277.1,438.2,278.6,438.2z"/>
	<circle class="st0" cx="278.6" cy="415.9" r="2.6"/>
	<path class="st0" d="M249,442.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S250.4,442.8,249,442.8z"/>
	<circle class="st0" cx="278.6" cy="455.3" r="2.6"/>
	<path class="st0" d="M249,433c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S250.4,433,249,433z"/>
	<path class="st0" d="M249,452.6c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S250.4,452.6,249,452.6z"/>
	<path class="st0" d="M249,423.1c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S250.4,423.1,249,423.1z"/>
	<path class="st0" d="M140.6,167.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C143.2,168.5,142,167.3,140.6,167.3z"/>
	<path class="st0" d="M249,413.3c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S250.4,413.3,249,413.3z"/>
	<path class="st0" d="M249,393.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6C251.6,394.8,250.4,393.6,249,393.6z
		"/>
	<path class="st0" d="M249,403.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S250.4,403.4,249,403.4z"/>
	<path class="st0" d="M308.1,521.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S309.6,521.5,308.1,521.5z"
		/>
	<path class="st0" d="M308.1,531.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S309.6,531.4,308.1,531.4z"/>
	<path class="st0" d="M278.6,576c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6C275.9,574.8,277.1,576,278.6,576z"
		/>
	<path class="st0" d="M278.6,403.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S280,403.4,278.6,403.4z"/>
	<path class="st0" d="M308.1,492c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S309.6,492,308.1,492z"/>
	<path class="st0" d="M308.1,482.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C310.7,483.3,309.6,482.2,308.1,482.2z"/>
	<path class="st0" d="M308.1,501.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S309.6,501.8,308.1,501.8z"/>
	<path class="st0" d="M249,472.3c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S250.4,472.3,249,472.3z"/>
	<path class="st0" d="M278.6,556.3c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6
		C275.9,555.1,277.1,556.3,278.6,556.3z"/>
	<path class="st0" d="M278.6,507.1c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6S277.1,507.1,278.6,507.1z"/>
	<path class="st0" d="M278.6,566.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6
		C275.9,565,277.1,566.1,278.6,566.1z"/>
	<circle class="st0" cx="278.6" cy="494.6" r="2.6"/>
	<circle class="st0" cx="278.6" cy="475" r="2.6"/>
	<path class="st0" d="M278.6,487.4c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6
		C275.9,486.2,277.1,487.4,278.6,487.4z"/>
	<path class="st0" d="M278.6,516.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6
		C275.9,515.8,277.1,516.9,278.6,516.9z"/>
	<path class="st0" d="M278.6,467.7c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6
		C275.9,466.6,277.1,467.7,278.6,467.7z"/>
	<path class="st0" d="M278.6,546.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6
		C275.9,545.3,277.1,546.5,278.6,546.5z"/>
	<path class="st0" d="M278.6,536.6c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6S277.1,536.6,278.6,536.6z"/>
	<path class="st0" d="M278.6,526.8c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6
		C275.9,525.6,277.1,526.8,278.6,526.8z"/>
	<path class="st0" d="M308.1,511.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S309.6,511.7,308.1,511.7z"
		/>
	<path class="st0" d="M347.5,467.7c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C344.9,466.6,346.1,467.7,347.5,467.7z"/>
	<path class="st0" d="M347.5,477.6c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S346.1,477.6,347.5,477.6z"
		/>
	<path class="st0" d="M347.5,452.6c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S349,452.6,347.5,452.6z"/>
	<path class="st0" d="M337.7,442.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S339.1,442.8,337.7,442.8z"/>
	<path class="st0" d="M12.5,137.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C15.1,138.9,13.9,137.8,12.5,137.8z"/>
	<path class="st0" d="M9.9,110.9c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-0.4,0-0.8,0.1-1.2,0.3
		l-1.2,1.4C9.9,110.2,9.9,110.5,9.9,110.9z"/>
	<path class="st0" d="M318,452.6c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S319.4,452.6,318,452.6z"/>
	<path class="st0" d="M318,442.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S319.4,442.8,318,442.8z"/>
	<path class="st0" d="M318,472.3c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S319.4,472.3,318,472.3z"/>
	<path class="st0" d="M318,462.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S319.4,462.5,318,462.5z"/>
	<path class="st0" d="M337.7,497.3c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6S336.2,497.3,337.7,497.3z"/>
	<path class="st0" d="M337.7,477.6c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6S336.2,477.6,337.7,477.6z"/>
	<path class="st0" d="M337.7,487.4c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6
		C335,486.2,336.2,487.4,337.7,487.4z"/>
	<path class="st0" d="M337.7,457.9c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6S336.2,457.9,337.7,457.9z"/>
	<path class="st0" d="M337.7,467.7c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6
		C335,466.6,336.2,467.7,337.7,467.7z"/>
	<path class="st0" d="M12.5,147.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S13.9,147.6,12.5,147.6z"/>
	<path class="st0" d="M22.3,127.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C25,129.1,23.8,127.9,22.3,127.9z"/>
	<path class="st0" d="M22.3,118.1c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S23.8,118.1,22.3,118.1z"/>
	<path class="st0" d="M22.3,88.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6C25,89.7,23.8,88.6,22.3,88.6z
		"/>
	<path class="st0" d="M12.5,127.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C15.1,129.1,13.9,127.9,12.5,127.9z"/>
	<path class="st0" d="M22.3,143c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6C19.7,141.8,20.9,143,22.3,143z
		"/>
	<path class="st0" d="M22.3,98.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S23.8,98.4,22.3,98.4z"/>
	<path class="st0" d="M22.3,108.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C25,109.4,23.8,108.2,22.3,108.2z"/>
	<path class="st0" d="M2.6,118.1c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S4.1,118.1,2.6,118.1z"/>
	<path class="st0" d="M2.6,127.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6C5.3,129.1,4.1,127.9,2.6,127.9z"/>
	<path class="st0" d="M12.5,118.1c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S13.9,118.1,12.5,118.1z"/>
	<path class="st0" d="M2.6,143c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6S0,138.9,0,140.4C0,141.8,1.2,143,2.6,143z"/>
	<path class="st0" d="M2.6,162.7c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6S0,158.6,0,160.1C0,161.5,1.2,162.7,2.6,162.7z"/>
	<path class="st0" d="M140.6,157.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S142,157.4,140.6,157.4z"/>
	<path class="st0" d="M140.6,118.1c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S142,118.1,140.6,118.1z"/>
	<path class="st0" d="M140.6,127.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C143.2,129.1,142,127.9,140.6,127.9z"/>
	<path class="st0" d="M140.6,108.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C143.2,109.4,142,108.2,140.6,108.2z"/>
	<path class="st0" d="M140.6,137.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C143.2,138.9,142,137.8,140.6,137.8z"/>
	<path class="st0" d="M140.6,147.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C143.2,148.8,142,147.6,140.6,147.6z"/>
	<path class="st0" d="M140.6,369.3c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C138,368.2,139.1,369.3,140.6,369.3z"/>
	<path class="st0" d="M140.6,98.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S142,98.4,140.6,98.4z"/>
	<path class="st0" d="M140.6,88.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C143.2,89.7,142,88.6,140.6,88.6z"/>
	<path class="st0" d="M298.3,423.1c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S299.7,423.1,298.3,423.1z"/>
	<path class="st0" d="M318,501.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S319.4,501.8,318,501.8z"/>
	<path class="st0" d="M318,492c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S319.4,492,318,492z"/>
	<path class="st0" d="M320.6,514.3c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6S320.6,515.8,320.6,514.3z"/>
	<path class="st0" d="M318,482.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6C320.6,483.3,319.4,482.2,318,482.2z
		"/>
	<path class="st0" d="M288.4,413.3c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S289.9,413.3,288.4,413.3z"/>
	<path class="st0" d="M288.4,560.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C291,562.1,289.9,560.9,288.4,560.9z"/>
	<path class="st0" d="M249,635c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.4,0-2.6,1.2-2.6,2.6l0.2,1.1
		C247,634.4,247.9,635,249,635z"/>
	<path class="st0" d="M288.4,216.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S289.9,216.5,288.4,216.5z"/>
	<path class="st0" d="M291,229c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6S291,230.4,291,229z"/>
	<path class="st0" d="M189.9,187c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C192.5,188.1,191.3,187,189.9,187z"/>
	<path class="st0" d="M288.4,206.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S289.9,206.6,288.4,206.6z"/>
	<path class="st0" d="M288.4,196.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S289.9,196.8,288.4,196.8z"/>
	<path class="st0" d="M288.4,187c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6C291,188.1,289.9,187,288.4,187z"/>
	<path class="st0" d="M189.9,137.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C192.5,138.9,191.3,137.8,189.9,137.8z"/>
	<path class="st0" d="M189.9,157.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S191.3,157.4,189.9,157.4z"/>
	<path class="st0" d="M189.9,147.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S191.3,147.6,189.9,147.6z"
		/>
	<path class="st0" d="M189.9,167.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S191.3,167.3,189.9,167.3z"
		/>
	<path class="st0" d="M189.9,177.1c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S191.3,177.1,189.9,177.1z"
		/>
	<path class="st0" d="M291,169.9c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6S291,171.4,291,169.9z"/>
	<path class="st0" d="M189.9,320.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C187.2,319,188.4,320.1,189.9,320.1z"/>
	<path class="st0" d="M189.9,127.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C192.5,129.1,191.3,127.9,189.9,127.9z"/>
	<path class="st0" d="M189.9,118.1c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S191.3,118.1,189.9,118.1z"/>
	<path class="st0" d="M308.1,15.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S306.7,15.1,308.1,15.1z"/>
	<path class="st0" d="M308.1,24.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C305.5,23.8,306.7,24.9,308.1,24.9z"/>
	<path class="st0" d="M305.5,150.2c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6
		C306.7,147.6,305.5,148.8,305.5,150.2z"/>
	<path class="st0" d="M308.1,34.8c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6C305.5,33.6,306.7,34.8,308.1,34.8
		z"/>
	<path class="st0" d="M308.1,44.6c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C305.5,43.4,306.7,44.6,308.1,44.6z"/>
	<path class="st0" d="M298.3,15.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C295.6,13.9,296.8,15.1,298.3,15.1z"/>
	<path class="st0" d="M298.3,34.8c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6C295.7,33.6,296.8,34.8,298.3,34.8
		z"/>
	<path class="st0" d="M298.3,24.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C295.6,23.8,296.8,24.9,298.3,24.9z"/>
	<path class="st0" d="M298.3,157.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S299.7,157.4,298.3,157.4z"/>
	<path class="st0" d="M298.3,137.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C300.9,138.9,299.7,137.8,298.3,137.8z"/>
	<path class="st0" d="M288.4,127.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C291,129.1,289.9,127.9,288.4,127.9z"/>
	<path class="st0" d="M298.3,127.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C300.9,129.1,299.7,127.9,298.3,127.9z"/>
	<path class="st0" d="M288.4,147.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S289.9,147.6,288.4,147.6z"/>
	<path class="st0" d="M288.4,108.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C291,109.4,289.9,108.2,288.4,108.2z"/>
	<path class="st0" d="M288.4,137.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C291,138.9,289.9,137.8,288.4,137.8z"/>
	<path class="st0" d="M288.4,118.1c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S289.9,118.1,288.4,118.1z"/>
	<path class="st0" d="M288.4,157.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S289.9,157.4,288.4,157.4z"/>
	<path class="st0" d="M298.3,147.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S299.7,147.6,298.3,147.6z"
		/>
	<path class="st0" d="M278.6,98.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S280,98.4,278.6,98.4z"/>
	<path class="st0" d="M189.9,196.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S191.3,196.8,189.9,196.8z"
		/>
	<path class="st0" d="M258.8,74.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C256.2,73,257.4,74.1,258.8,74.1z"/>
	<path class="st0" d="M268.7,74.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C266.1,73,267.2,74.1,268.7,74.1z"/>
	<path class="st0" d="M258.8,64.3c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S257.4,64.3,258.8,64.3z"/>
	<path class="st0" d="M278.6,127.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C281.2,129.1,280,127.9,278.6,127.9z"/>
	<path class="st0" d="M278.6,137.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C281.2,138.9,280,137.8,278.6,137.8z"/>
	<circle class="st0" cx="278.6" cy="160.1" r="2.6"/>
	<circle class="st0" cx="278.6" cy="199.4" r="2.6"/>
	<path class="st0" d="M278.6,206.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S280,206.6,278.6,206.6z"/>
	<path class="st0" d="M278.6,216.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S280,216.5,278.6,216.5z"/>
	<path class="st0" d="M278.6,147.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S280,147.6,278.6,147.6z"/>
	<path class="st0" d="M268.7,147.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C271.3,148.8,270.1,147.6,268.7,147.6z"/>
	<path class="st0" d="M278.6,118.1c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S280,118.1,278.6,118.1z"/>
	<path class="st0" d="M278.6,108.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C281.2,109.4,280,108.2,278.6,108.2z"/>
	<path class="st0" d="M219.4,123.3c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S218,123.3,219.4,123.3z"/>
	<path class="st0" d="M298.3,44.6c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C295.6,43.4,296.8,44.6,298.3,44.6z"/>
	<path class="st0" d="M189.9,265.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C192.5,266.9,191.3,265.7,189.9,265.7z"/>
	<path class="st0" d="M189.9,305c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S191.3,305,189.9,305z"/>
	<path class="st0" d="M189.9,275.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S191.3,275.5,189.9,275.5z"
		/>
	<path class="st0" d="M189.9,285.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C192.5,286.5,191.3,285.4,189.9,285.4z"/>
	<path class="st0" d="M189.9,246c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S191.3,246,189.9,246z"/>
	<path class="st0" d="M189.9,255.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S191.3,255.8,189.9,255.8z"
		/>
	<path class="st0" d="M189.9,216.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S191.3,216.5,189.9,216.5z"
		/>
	<path class="st0" d="M189.9,236.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C192.5,237.3,191.3,236.2,189.9,236.2z"/>
	<path class="st0" d="M189.9,206.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S191.3,206.6,189.9,206.6z"
		/>
	<path class="st0" d="M219.4,113.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C216.8,112.3,218,113.5,219.4,113.5z"/>
	<path class="st0" d="M189.9,226.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S191.3,226.3,189.9,226.3z"
		/>
	<path class="st0" d="M189.9,295.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C192.5,296.4,191.3,295.2,189.9,295.2z"/>
	<path class="st0" d="M219.4,133.2c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C216.8,132,218,133.2,219.4,133.2z"/>
	<path class="st0" d="M219.4,143c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C216.8,141.8,218,143,219.4,143z"/>
	<path class="st0" d="M209.6,78.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C212.2,79.9,211,78.7,209.6,78.7z"/>
	<path class="st0" d="M219.4,152.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C216.8,151.7,218,152.9,219.4,152.9z"/>
	<path class="st0" d="M189.9,93.8c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C187.2,92.6,188.4,93.8,189.9,93.8z"/>
	<path class="st0" d="M189.9,103.7c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S188.4,103.7,189.9,103.7z"/>
	<path class="st0" d="M189.9,84c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6C187.2,82.8,188.4,84,189.9,84z
		"/>
	<path class="st0" d="M209.6,433c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S211,433,209.6,433z"/>
	<path class="st0" d="M189.9,383.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S191.3,383.8,189.9,383.8z"/>
	<path class="st0" d="M189.9,393.6c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S191.3,393.6,189.9,393.6z"/>
	<path class="st0" d="M199.7,393.6c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S201.2,393.6,199.7,393.6z"/>
	<path class="st0" d="M199.7,403.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S201.2,403.4,199.7,403.4z"
		/>
	<circle class="st0" cx="209.6" cy="396.2" r="2.6"/>
	<circle class="st0" cx="209.6" cy="406.1" r="2.6"/>
	<path class="st0" d="M170.2,373.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S171.6,373.9,170.2,373.9z"
		/>
	<path class="st0" d="M180,373.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S181.4,373.9,180,373.9z"/>
	<path class="st0" d="M189.9,373.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S191.3,373.9,189.9,373.9z"
		/>
	<path class="st0" d="M160.3,373.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C162.9,375.1,161.8,373.9,160.3,373.9z"/>
	<circle class="st0" cx="209.6" cy="455.3" r="2.6"/>
	<path class="st0" d="M209.6,442.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S211,442.8,209.6,442.8z"/>
	<path class="st0" d="M249,482.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6C251.6,483.3,250.4,482.2,249,482.2z
		"/>
	<path class="st0" d="M170.2,364.1c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C172.8,365.3,171.6,364.1,170.2,364.1z"/>
	<path class="st0" d="M150.4,320.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6C147.8,319,149,320.1,150.4,320.1z
		"/>
	<path class="st0" d="M150.4,300.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6
		C147.8,299.3,149,300.5,150.4,300.5z"/>
	<path class="st0" d="M219.4,452.6c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S220.9,452.6,219.4,452.6z"/>
	<path class="st0" d="M150.4,310.3c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6
		C147.8,309.1,149,310.3,150.4,310.3z"/>
	<path class="st0" d="M150.4,290.6c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6
		C147.8,289.4,149,290.6,150.4,290.6z"/>
	<path class="st0" d="M150.4,280.8c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6
		C147.8,279.6,149,280.8,150.4,280.8z"/>
	<path class="st0" d="M150.4,369.3c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6
		C147.8,368.2,149,369.3,150.4,369.3z"/>
	<path class="st0" d="M150.4,330c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6C147.8,328.8,149,330,150.4,330z"/>
	<path class="st0" d="M249,541.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S250.4,541.2,249,541.2z"/>
	<path class="st0" d="M249,551c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S250.4,551,249,551z"/>
	<path class="st0" d="M150.4,270.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6
		C147.8,269.8,149,270.9,150.4,270.9z"/>
	<path class="st0" d="M249,531.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S250.4,531.4,249,531.4z"/>
	<path class="st0" d="M249,570.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S250.4,570.7,249,570.7z"/>
	<path class="st0" d="M249,560.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6C251.6,562.1,250.4,560.9,249,560.9z
		"/>
	<path class="st0" d="M249,501.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S250.4,501.8,249,501.8z"/>
	<path class="st0" d="M249,492c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S250.4,492,249,492z"/>
	<path class="st0" d="M239.1,492c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S240.6,492,239.1,492z"/>
	<path class="st0" d="M249,580.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6C251.6,581.7,250.4,580.6,249,580.6z
		"/>
	<path class="st0" d="M249,521.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S250.4,521.5,249,521.5z"/>
	<path class="st0" d="M249,511.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S250.4,511.7,249,511.7z"/>
	<path class="st0" d="M219.4,403.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C222.1,404.6,220.9,403.4,219.4,403.4z"/>
	<path class="st0" d="M219.4,462.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C222.1,463.7,220.9,462.5,219.4,462.5z"/>
	<path class="st0" d="M219.4,423.1c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S220.9,423.1,219.4,423.1z"/>
	<path class="st0" d="M219.4,413.3c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S220.9,413.3,219.4,413.3z"/>
	<path class="st0" d="M219.4,442.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S220.9,442.8,219.4,442.8z"/>
	<path class="st0" d="M249,590.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6C251.6,591.6,250.4,590.4,249,590.4z
		"/>
	<path class="st0" d="M219.4,433c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S220.9,433,219.4,433z"/>
	<path class="st0" d="M249,600.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S250.4,600.2,249,600.2z"/>
	<path class="st0" d="M249,619.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6C251.6,621.1,250.4,619.9,249,619.9z
		"/>
	<path class="st0" d="M249,610.1c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S250.4,610.1,249,610.1z"/>
	<path class="st0" d="M150.4,103.7c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6S149,103.7,150.4,103.7z"/>
	<path class="st0" d="M180,64.3c1.5,0,2.6-1.2,2.6-2.6S181.5,59,180,59s-2.6,1.2-2.6,2.6S178.6,64.3,180,64.3z"/>
	<path class="st0" d="M199.7,64.3c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S198.3,64.3,199.7,64.3z"/>
	<path class="st0" d="M209.6,64.3c1.5,0,2.6-1.2,2.6-2.6S211,59,209.6,59c-1.5,0-2.6,1.2-2.6,2.6S208.1,64.3,209.6,64.3z"/>
	<path class="st0" d="M219.5,64.3c1.5,0,2.6-1.2,2.6-2.6S221,59,219.5,59s-2.6,1.2-2.6,2.6S218.1,64.3,219.5,64.3z"/>
	<path class="st0" d="M180,84c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S178.6,84,180,84z"/>
	<path class="st0" d="M180,74.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6C177.4,73,178.6,74.1,180,74.1z
		"/>
	<path class="st0" d="M189.8,74.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C187.2,73,188.4,74.1,189.8,74.1z"/>
	<path class="st0" d="M199.7,44.6c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C197.1,43.4,198.3,44.6,199.7,44.6z"/>
	<path class="st0" d="M189.9,44.6c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C187.3,43.4,188.4,44.6,189.9,44.6z"/>
	<path class="st0" d="M170.2,74.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C167.5,73,168.7,74.1,170.2,74.1z"/>
	<path class="st0" d="M180,103.7c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S178.6,103.7,180,103.7z"/>
	<path class="st0" d="M180,93.8c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C177.4,92.6,178.6,93.8,180,93.8z"/>
	<path class="st0" d="M172.8,101c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6c0,0.2,0,0.4,0.1,0.6l1.6,1.8c0.3,0.1,0.6,0.2,1,0.2
		C171.6,103.7,172.8,102.5,172.8,101z"/>
	<path class="st0" d="M199.7,54.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C197.1,53.3,198.3,54.5,199.7,54.5z"/>
	<path class="st0" d="M239.1,59c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S240.6,59,239.1,59z"/>
	<path class="st0" d="M239.1,49.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6C241.8,50.4,240.6,49.2,239.1,49.2z
		"/>
	<path class="st0" d="M150.4,261.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6
		C147.8,259.9,149,261.1,150.4,261.1z"/>
	<path class="st0" d="M150.4,192.2c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6C147.8,191,149,192.2,150.4,192.2z
		"/>
	<path class="st0" d="M150.4,162.7c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6S149,162.7,150.4,162.7z"/>
	<path class="st0" d="M150.4,182.4c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6
		C147.8,181.2,149,182.4,150.4,182.4z"/>
	<path class="st0" d="M150.4,172.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6
		C147.8,171.4,149,172.5,150.4,172.5z"/>
	<path class="st0" d="M150.4,211.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6
		C147.8,210.7,149,211.9,150.4,211.9z"/>
	<path class="st0" d="M150.4,202.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6
		C147.8,200.9,149,202.1,150.4,202.1z"/>
	<path class="st0" d="M150.4,251.3c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6
		C147.8,250.1,149,251.3,150.4,251.3z"/>
	<path class="st0" d="M150.4,241.4c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6
		C147.8,240.2,149,241.4,150.4,241.4z"/>
	<path class="st0" d="M150.4,231.6c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6
		C147.8,230.4,149,231.6,150.4,231.6z"/>
	<path class="st0" d="M170.2,64.3c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S168.7,64.3,170.2,64.3z"/>
	<path class="st0" d="M150.4,221.7c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6
		C147.8,220.6,149,221.7,150.4,221.7z"/>
	<path class="st0" d="M150.4,113.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6
		C147.8,112.3,149,113.5,150.4,113.5z"/>
	<path class="st0" d="M160.3,320.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C157.7,319,158.8,320.1,160.3,320.1z"/>
	<path class="st0" d="M150.4,152.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6
		C147.8,151.7,149,152.9,150.4,152.9z"/>
	<path class="st0" d="M150.4,133.2c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6C147.8,132,149,133.2,150.4,133.2z
		"/>
	<path class="st0" d="M150.4,143c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6C147.8,141.8,149,143,150.4,143z"/>
	<path class="st0" d="M298.3,54.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C295.6,53.3,296.8,54.5,298.3,54.5z"/>
	<path class="st0" d="M150.4,123.3c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6S149,123.3,150.4,123.3z"/>
	<path class="st0" d="M653,231.6c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C650.4,230.4,651.6,231.6,653,231.6z"/>
	<path class="st0" d="M653,300.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S651.6,300.5,653,300.5z"/>
	<path class="st0" d="M653,221.7c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C650.4,220.6,651.6,221.7,653,221.7z"/>
	<path class="st0" d="M653,251.3c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S651.6,251.3,653,251.3z"/>
	<path class="st0" d="M662.9,147.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S664.3,147.6,662.9,147.6z"/>
	<path class="st0" d="M653,280.8c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C650.4,279.6,651.6,280.8,653,280.8z"/>
	<path class="st0" d="M653,261.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S651.6,261.1,653,261.1z"/>
	<circle class="st0" cx="653" cy="199.4" r="2.6"/>
	<path class="st0" d="M653,290.6c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S651.6,290.6,653,290.6z"/>
	<circle class="st0" cx="653" cy="268.3" r="2.6"/>
	<circle class="st0" cx="653" cy="238.8" r="2.6"/>
	<path class="st0" d="M653,143c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S651.6,143,653,143z"/>
	<path class="st0" d="M653,133.2c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S651.6,133.2,653,133.2z"/>
	<path class="st0" d="M662.9,108.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C665.5,109.4,664.3,108.2,662.9,108.2z"/>
	<path class="st0" d="M665.5,120.7c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6S665.5,122.2,665.5,120.7z"/>
	<path class="st0" d="M662.9,137.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C665.5,138.9,664.3,137.8,662.9,137.8z"/>
	<path class="st0" d="M653,172.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C650.4,171.4,651.6,172.5,653,172.5z"/>
	<path class="st0" d="M653,192.2c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S651.6,192.2,653,192.2z"/>
	<circle class="st0" cx="653" cy="179.8" r="2.6"/>
	<path class="st0" d="M653,152.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S651.6,152.9,653,152.9z"/>
	<circle class="st0" cx="653" cy="160.1" r="2.6"/>
	<path class="st0" d="M653,211.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S651.6,211.9,653,211.9z"/>
	<path class="st0" d="M574.2,152.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6
		C571.5,151.7,572.7,152.9,574.2,152.9z"/>
	<path class="st0" d="M574.2,162.7c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6S572.7,162.7,574.2,162.7z"/>
	<path class="st0" d="M534.8,49.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6C537.4,50.4,536.2,49.2,534.8,49.2z
		"/>
	<path class="st0" d="M613.6,310.3c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C611,309.1,612.1,310.3,613.6,310.3z"/>
	<circle class="st0" cx="653" cy="307.7" r="2.6"/>
	<path class="st0" d="M495.3,270.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C492.7,269.8,493.9,270.9,495.3,270.9z"/>
	<path class="st0" d="M515.1,74.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C512.4,73,513.6,74.1,515.1,74.1z"/>
	<path class="st0" d="M524.9,84c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6C522.3,82.8,523.5,84,524.9,84z
		"/>
	<path class="st0" d="M515.1,84c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6C512.4,82.8,513.6,84,515.1,84z
		"/>
	<path class="st0" d="M515.1,59c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S516.5,59,515.1,59z"/>
	<path class="st0" d="M505.2,59c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S506.7,59,505.2,59z"/>
	<path class="st0" d="M653,320.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C650.4,319,651.6,320.1,653,320.1z"/>
	<path class="st0" d="M653,330c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S651.6,330,653,330z"/>
	<path class="st0" d="M633.3,501.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S634.8,501.8,633.3,501.8z"/>
	<path class="st0" d="M643.2,501.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S644.6,501.8,643.2,501.8z"/>
	<path class="st0" d="M613.6,383.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S615,383.8,613.6,383.8z"/>
	<path class="st0" d="M613.6,403.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C616.2,404.6,615,403.4,613.6,403.4z"/>
	<path class="st0" d="M633.3,492c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S634.8,492,633.3,492z"/>
	<path class="st0" d="M633.3,511.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C635.9,512.9,634.8,511.7,633.3,511.7z"/>
	<path class="st0" d="M613.6,393.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C616.2,394.8,615,393.6,613.6,393.6z"/>
	<path class="st0" d="M593.9,364.1c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C596.5,365.3,595.3,364.1,593.9,364.1z"/>
	<path class="st0" d="M593.9,383.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S595.3,383.8,593.9,383.8z"/>
	<path class="st0" d="M613.6,413.3c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S615,413.3,613.6,413.3z"/>
	<path class="st0" d="M593.9,403.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S595.3,403.4,593.9,403.4z"
		/>
	<path class="st0" d="M593.9,393.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C596.5,394.8,595.3,393.6,593.9,393.6z"/>
	<path class="st0" d="M593.9,373.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S595.3,373.9,593.9,373.9z"
		/>
	<path class="st0" d="M613.6,433c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S615,433,613.6,433z"/>
	<path class="st0" d="M613.6,442.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S615,442.8,613.6,442.8z"/>
	<path class="st0" d="M613.6,423.1c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S615,423.1,613.6,423.1z"/>
	<path class="st0" d="M593.9,354.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C596.5,355.4,595.3,354.2,593.9,354.2z"/>
	<path class="st0" d="M593.9,344.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S595.3,344.4,593.9,344.4z"
		/>
	<path class="st12" d="M643.2,334.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C645.8,335.7,644.6,334.6,643.2,334.6z"/>
	<path class="st0" d="M662.9,216.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S664.3,216.5,662.9,216.5z"/>
	<path class="st0" d="M662.9,255.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S664.3,255.8,662.9,255.8z"/>
	<path class="st0" d="M662.9,206.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S664.3,206.6,662.9,206.6z"/>
	<path class="st0" d="M662.9,246c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S664.3,246,662.9,246z"/>
	<path class="st0" d="M662.9,226.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S664.3,226.3,662.9,226.3z"/>
	<path class="st0" d="M662.9,167.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S664.3,167.3,662.9,167.3z"/>
	<path class="st0" d="M662.9,177.1c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S664.3,177.1,662.9,177.1z"/>
	<path class="st0" d="M662.9,196.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S664.3,196.8,662.9,196.8z"/>
	<path class="st0" d="M662.9,187c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6C665.5,188.1,664.3,187,662.9,187z"
		/>
	<path class="st0" d="M662.9,236.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C665.5,237.3,664.3,236.2,662.9,236.2z"/>
	<path class="st0" d="M662.9,314.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S664.3,314.9,662.9,314.9z"/>
	<path class="st0" d="M662.9,324.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C665.5,325.9,664.3,324.7,662.9,324.7z"/>
	<path class="st0" d="M662.9,157.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S664.3,157.4,662.9,157.4z"/>
	<path class="st0" d="M662.9,285.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C665.5,286.5,664.3,285.4,662.9,285.4z"/>
	<path class="st0" d="M662.9,275.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S664.3,275.5,662.9,275.5z"/>
	<path class="st0" d="M662.9,265.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C665.5,266.9,664.3,265.7,662.9,265.7z"/>
	<path class="st0" d="M662.9,305c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S664.3,305,662.9,305z"/>
	<path class="st0" d="M662.9,295.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C665.5,296.4,664.3,295.2,662.9,295.2z"/>
	<path class="st0" d="M534.8,344.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C537.4,345.6,536.2,344.4,534.8,344.4z"/>
	<path class="st0" d="M534.8,511.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C537.4,512.9,536.2,511.7,534.8,511.7z"/>
	<path class="st0" d="M534.8,492c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S536.2,492,534.8,492z"/>
	<path class="st0" d="M534.8,482.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C537.4,483.3,536.2,482.2,534.8,482.2z"/>
	<path class="st0" d="M534.8,501.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S536.2,501.8,534.8,501.8z"/>
	<path class="st0" d="M534.8,433c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S536.2,433,534.8,433z"/>
	<path class="st0" d="M534.8,452.6c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S536.2,452.6,534.8,452.6z"/>
	<path class="st0" d="M524.9,452.6c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S526.4,452.6,524.9,452.6z"/>
	<path class="st0" d="M534.8,442.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S536.2,442.8,534.8,442.8z"/>
	<path class="st0" d="M534.8,462.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C537.4,463.7,536.2,462.5,534.8,462.5z"/>
	<path class="st0" d="M534.8,472.3c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S536.2,472.3,534.8,472.3z"/>
	<path class="st0" d="M515.1,438.2c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S513.6,438.2,515.1,438.2z"/>
	<path class="st0" d="M505.2,408.7c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S503.7,408.7,505.2,408.7z"
		/>
	<path class="st0" d="M524.9,482.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C527.5,483.3,526.4,482.2,524.9,482.2z"/>
	<path class="st0" d="M524.9,492c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S526.4,492,524.9,492z"/>
	<path class="st0" d="M574.2,521.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S575.6,521.5,574.2,521.5z"/>
	<path class="st0" d="M564.3,324.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C567,325.9,565.8,324.7,564.3,324.7z"/>
	<path class="st0" d="M574.2,531.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S575.6,531.4,574.2,531.4z"/>
	<path class="st0" d="M534.8,526.8c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6
		C532.1,525.6,533.3,526.8,534.8,526.8z"/>
	<path class="st0" d="M534.8,324.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C537.4,325.9,536.2,324.7,534.8,324.7z"/>
	<path class="st0" d="M534.8,383.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S536.2,383.8,534.8,383.8z"/>
	<path class="st0" d="M534.8,423.1c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S536.2,423.1,534.8,423.1z"/>
	<path class="st0" d="M534.8,393.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C537.4,394.8,536.2,393.6,534.8,393.6z"/>
	<path class="st0" d="M534.8,413.3c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S536.2,413.3,534.8,413.3z"/>
	<path class="st0" d="M534.8,403.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C537.4,404.6,536.2,403.4,534.8,403.4z"/>
	<path class="st0" d="M534.8,373.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C537.4,375.1,536.2,373.9,534.8,373.9z"/>
	<path class="st0" d="M534.8,334.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C537.4,335.7,536.2,334.6,534.8,334.6z"/>
	<path class="st0" d="M593.9,413.3c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S595.3,413.3,593.9,413.3z"/>
	<path class="st0" d="M534.8,354.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C537.4,355.4,536.2,354.2,534.8,354.2z"/>
	<path class="st0" d="M534.8,364.1c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C537.4,365.3,536.2,364.1,534.8,364.1z"/>
	<path class="st0" d="M446.1,344.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S447.5,344.4,446.1,344.4z"
		/>
	<path class="st0" d="M446.1,334.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C448.7,335.7,447.5,334.6,446.1,334.6z"/>
	<path class="st0" d="M429,356.9c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6S429,358.3,429,356.9z"/>
	<path class="st0" d="M436.2,373.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C438.8,375.1,437.7,373.9,436.2,373.9z"/>
	<path class="st0" d="M446.1,354.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C448.7,355.4,447.5,354.2,446.1,354.2z"/>
	<path class="st0" d="M495.3,305c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C498,306.2,496.8,305,495.3,305z"/>
	<path class="st0" d="M436.2,383.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S437.7,383.8,436.2,383.8z"/>
	<path class="st0" d="M436.2,393.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C438.8,394.8,437.7,393.6,436.2,393.6z"/>
	<path class="st0" d="M446.1,403.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S447.5,403.4,446.1,403.4z"
		/>
	<path class="st0" d="M465.8,211.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C463.2,210.7,464.3,211.9,465.8,211.9z"/>
	<path class="st0" d="M446.1,364.1c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C448.7,365.3,447.5,364.1,446.1,364.1z"/>
	<path class="st0" d="M475.6,226.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C478.3,227.5,477.1,226.3,475.6,226.3z"/>
	<path class="st0" d="M478.3,238.8c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c0.1,0,0.2,0,0.3,0l0.5-0.1
		C477.5,241,478.3,240,478.3,238.8z"/>
	<path class="st0" d="M478.3,258.5c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c0.1,0,0.2,0,0.3,0l0.5-0.1
		C477.5,260.6,478.3,259.6,478.3,258.5z"/>
	<path class="st0" d="M478.3,248.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c0.1,0,0.2,0,0.3,0l0.5-0.1
		C477.5,250.8,478.3,249.8,478.3,248.6z"/>
	<path class="st0" d="M468.4,238.8c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c0.1,0,0.2,0,0.3,0l0.5-0.1
		C467.6,241,468.4,240,468.4,238.8z"/>
	<path class="st0" d="M446.1,393.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C448.7,394.8,447.5,393.6,446.1,393.6z"/>
	<path class="st0" d="M446.1,383.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S447.5,383.8,446.1,383.8z"/>
	<path class="st0" d="M446.1,373.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S447.5,373.9,446.1,373.9z"
		/>
	<path class="st0" d="M436.2,354.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C438.8,355.4,437.7,354.2,436.2,354.2z"/>
	<path class="st0" d="M436.2,364.1c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C438.8,365.3,437.7,364.1,436.2,364.1z"/>
	<path class="st0" d="M426.4,364.1c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C429,365.3,427.8,364.1,426.4,364.1z"/>
	<path class="st0" d="M455.9,334.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C458.6,335.7,457.4,334.6,455.9,334.6z"/>
	<path class="st0" d="M455.9,364.1c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C458.6,365.3,457.4,364.1,455.9,364.1z"/>
	<path class="st0" d="M455.9,314.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C458.6,316.1,457.4,314.9,455.9,314.9z"/>
	<circle class="st0" cx="455.9" cy="347" r="2.6"/>
	<path class="st0" d="M455.9,354.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C458.6,355.4,457.4,354.2,455.9,354.2z"/>
	<path class="st0" d="M436.2,344.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C438.8,345.6,437.7,344.4,436.2,344.4z"/>
	<path class="st0" d="M455.9,408.7c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S454.5,408.7,455.9,408.7z"
		/>
	<path class="st0" d="M455.9,324.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C458.6,325.9,457.4,324.7,455.9,324.7z"/>
	<path class="st0" d="M426.4,373.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S427.8,373.9,426.4,373.9z"
		/>
	<path class="st0" d="M426.4,172.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C423.7,171.4,424.9,172.5,426.4,172.5z"/>
	<path class="st0" d="M416.5,162.6c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C413.9,161.5,415.1,162.6,416.5,162.6z"/>
	<path class="st0" d="M436.2,172.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C433.6,171.4,434.7,172.5,436.2,172.5z"/>
	<path class="st0" d="M426.4,162.6c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C423.7,161.5,424.9,162.6,426.4,162.6z"/>
	<path class="st0" d="M426.4,389c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S424.9,389,426.4,389z"/>
	<path class="st0" d="M455.9,393.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C458.6,394.8,457.4,393.6,455.9,393.6z"/>
	<circle class="st0" cx="455.9" cy="386.4" r="2.6"/>
	<path class="st0" d="M455.9,373.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C458.6,375.1,457.4,373.9,455.9,373.9z"/>
	<path class="st0" d="M593.9,275.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S595.3,275.5,593.9,275.5z"
		/>
	<path class="st0" d="M633.3,157.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S634.8,157.4,633.3,157.4z"/>
	<path class="st0" d="M643.2,295.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C645.8,296.4,644.6,295.2,643.2,295.2z"/>
	<path class="st0" d="M643.2,314.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S644.6,314.9,643.2,314.9z"
		/>
	<path class="st0" d="M633.3,137.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C635.9,138.9,634.8,137.8,633.3,137.8z"/>
	<path class="st0" d="M633.3,147.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C635.9,148.8,634.8,147.6,633.3,147.6z"/>
	<path class="st0" d="M643.2,265.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C645.8,266.9,644.6,265.7,643.2,265.7z"/>
	<path class="st0" d="M643.2,255.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S644.6,255.8,643.2,255.8z"
		/>
	<path class="st0" d="M643.2,285.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C645.8,286.5,644.6,285.4,643.2,285.4z"/>
	<path class="st0" d="M633.3,265.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C635.9,266.9,634.8,265.7,633.3,265.7z"/>
	<path class="st0" d="M643.2,305c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S644.6,305,643.2,305z"/>
	<path class="st0" d="M633.3,216.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C635.9,217.7,634.8,216.5,633.3,216.5z"/>
	<path class="st0" d="M633.3,226.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C635.9,227.5,634.8,226.3,633.3,226.3z"/>
	<path class="st0" d="M633.3,167.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C635.9,168.5,634.8,167.3,633.3,167.3z"/>
	<path class="st0" d="M633.3,255.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C635.9,257,634.8,255.8,633.3,255.8z"/>
	<path class="st0" d="M633.3,246c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C635.9,247.2,634.8,246,633.3,246z"/>
	<path class="st0" d="M633.3,236.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C635.9,237.3,634.8,236.2,633.3,236.2z"/>
	<path class="st0" d="M633.3,187c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C635.9,188.1,634.8,187,633.3,187z"/>
	<path class="st0" d="M633.3,177.1c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C635.9,178.3,634.8,177.1,633.3,177.1z"/>
	<path class="st0" d="M633.3,206.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C635.9,207.8,634.8,206.6,633.3,206.6z"/>
	<path class="st0" d="M633.3,196.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C635.9,198,634.8,196.8,633.3,196.8z"/>
	<path class="st0" d="M643.2,275.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S644.6,275.5,643.2,275.5z"
		/>
	<path class="st0" d="M643.2,364.1c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C645.8,365.3,644.6,364.1,643.2,364.1z"/>
	<path class="st0" d="M645.8,376.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6S645.8,378,645.8,376.6z"/>
	<path class="st0" d="M633.3,403.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C635.9,404.6,634.8,403.4,633.3,403.4z"/>
	<path class="st0" d="M633.3,413.3c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S634.8,413.3,633.3,413.3z"/>
	<path class="st0" d="M633.3,423.1c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S634.8,423.1,633.3,423.1z"/>
	<path class="st0" d="M643.2,413.3c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S644.6,413.3,643.2,413.3z"/>
	<path class="st0" d="M643.2,354.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C645.8,355.4,644.6,354.2,643.2,354.2z"/>
	<path class="st0" d="M643.2,344.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S644.6,344.4,643.2,344.4z"
		/>
	<path class="st0" d="M643.2,157.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S644.6,157.4,643.2,157.4z"/>
	<path class="st0" d="M643.2,196.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S644.6,196.8,643.2,196.8z"
		/>
	<path class="st0" d="M643.2,206.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S644.6,206.6,643.2,206.6z"
		/>
	<path class="st0" d="M643.2,216.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S644.6,216.5,643.2,216.5z"
		/>
	<path class="st0" d="M643.2,226.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S644.6,226.3,643.2,226.3z"
		/>
	<path class="st0" d="M643.2,187c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C645.8,188.1,644.6,187,643.2,187z"/>
	<path class="st0" d="M643.2,167.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S644.6,167.3,643.2,167.3z"
		/>
	<path class="st0" d="M643.2,236.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C645.8,237.3,644.6,236.2,643.2,236.2z"/>
	<path class="st0" d="M643.2,147.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S644.6,147.6,643.2,147.6z"
		/>
	<path class="st0" d="M643.2,137.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C645.8,138.9,644.6,137.8,643.2,137.8z"/>
	<path class="st0" d="M643.2,127.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C645.8,129.1,644.6,127.9,643.2,127.9z"/>
	<path class="st0" d="M643.2,177.1c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S644.6,177.1,643.2,177.1z"
		/>
	<path class="st0" d="M643.2,246c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S644.6,246,643.2,246z"/>
	<path class="st0" d="M623.5,192.2c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C620.8,191,622,192.2,623.5,192.2z"/>
	<path class="st0" d="M623.5,211.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C620.8,210.7,622,211.9,623.5,211.9z"/>
	<path class="st0" d="M623.5,202.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C620.8,200.9,622,202.1,623.5,202.1z"/>
	<path class="st0" d="M623.5,172.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C620.8,171.4,622,172.5,623.5,172.5z"/>
	<path class="st0" d="M623.5,182.4c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C620.8,181.2,622,182.4,623.5,182.4z"/>
	<path class="st0" d="M623.5,261.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C620.8,259.9,622,261.1,623.5,261.1z"/>
	<path class="st0" d="M623.5,162.7c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S622,162.7,623.5,162.7z"/>
	<path class="st0" d="M623.5,221.7c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C620.8,220.6,622,221.7,623.5,221.7z"/>
	<path class="st0" d="M623.5,241.4c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C620.8,240.2,622,241.4,623.5,241.4z"/>
	<path class="st0" d="M623.5,231.6c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C620.8,230.4,622,231.6,623.5,231.6z"/>
	<path class="st0" d="M633.3,275.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C635.9,276.7,634.8,275.5,633.3,275.5z"/>
	<path class="st13" d="M613.6,339.8c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C611,338.6,612.1,339.8,613.6,339.8z"/>
	<path class="st0" d="M613.6,349.7c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S612.1,349.7,613.6,349.7z"
		/>
	<path class="st0" d="M613.6,320.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C611,319,612.1,320.1,613.6,320.1z"/>
	<path class="st0" d="M613.6,330c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S612.1,330,613.6,330z"/>
	<path class="st0" d="M623.5,152.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C620.8,151.7,622,152.9,623.5,152.9z"/>
	<path class="st0" d="M623.5,143c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C620.8,141.8,622,143,623.5,143z"/>
	<path class="st0" d="M623.5,123.3c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S622,123.3,623.5,123.3z"/>
	<path class="st0" d="M623.5,113.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C620.8,112.3,622,113.5,623.5,113.5z"/>
	<path class="st0" d="M623.5,103.7c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S622,103.7,623.5,103.7z"/>
	<path class="st0" d="M623.5,93.8c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C620.8,92.6,622,93.8,623.5,93.8z"/>
	<path class="st0" d="M623.5,84c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6C620.8,82.8,622,84,623.5,84z"
		/>
	<path class="st0" d="M633.3,84c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S631.9,84,633.3,84z"/>
	<path class="st0" d="M633.3,74.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C630.7,73,631.9,74.1,633.3,74.1z"/>
	<path class="st0" d="M643.2,74.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C640.5,73,641.7,74.1,643.2,74.1z"/>
	<path class="st0" d="M623.5,251.3c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C620.8,250.1,622,251.3,623.5,251.3z"/>
	<path class="st0" d="M633.3,334.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C635.9,335.7,634.8,334.6,633.3,334.6z"/>
	<path class="st14" d="M633.3,344.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C635.9,345.6,634.8,344.4,633.3,344.4z"/>
	<path class="st0" d="M633.3,324.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C635.9,325.9,634.8,324.7,633.3,324.7z"/>
	<path class="st0" d="M633.3,354.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C635.9,355.4,634.8,354.2,633.3,354.2z"/>
	<path class="st0" d="M633.3,373.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C635.9,375.1,634.8,373.9,633.3,373.9z"/>
	<path class="st0" d="M633.3,364.1c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C635.9,365.3,634.8,364.1,633.3,364.1z"/>
	<path class="st0" d="M623.5,270.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C620.8,269.8,622,270.9,623.5,270.9z"/>
	<path class="st0" d="M633.3,285.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C635.9,286.5,634.8,285.4,633.3,285.4z"/>
	<path class="st0" d="M633.3,295.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C635.9,296.4,634.8,295.2,633.3,295.2z"/>
	<path class="st0" d="M633.3,305c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C635.9,306.2,634.8,305,633.3,305z"/>
	<path class="st0" d="M633.3,314.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C635.9,316.1,634.8,314.9,633.3,314.9z"/>
	<path class="st0" d="M623.5,300.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C620.8,299.3,622,300.5,623.5,300.5z"/>
	<path class="st0" d="M623.5,320.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C620.8,319,622,320.1,623.5,320.1z"/>
	<path class="st0" d="M623.5,310.3c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C620.8,309.1,622,310.3,623.5,310.3z"/>
	<path class="st0" d="M623.5,290.6c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C620.8,289.4,622,290.6,623.5,290.6z"/>
	<path class="st0" d="M623.5,280.8c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C620.8,279.6,622,280.8,623.5,280.8z"/>
	<path class="st0" d="M623.5,349.7c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C620.8,348.5,622,349.7,623.5,349.7z"/>
	<path class="st0" d="M623.5,369.3c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C620.8,368.2,622,369.3,623.5,369.3z"/>
	<path class="st0" d="M623.5,359.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C620.8,358.3,622,359.5,623.5,359.5z"/>
	<path class="st0" d="M623.5,339.8c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C620.8,338.6,622,339.8,623.5,339.8z"/>
	<path class="st0" d="M623.5,330c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C620.8,328.8,622,330,623.5,330z"/>
	<circle class="st0" cx="574.2" cy="238.8" r="2.6"/>
	<path class="st0" d="M603.7,255.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C606.4,257,605.2,255.8,603.7,255.8z"/>
	<path class="st0" d="M603.7,305c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C606.4,306.2,605.2,305,603.7,305z"/>
	<path class="st0" d="M593.9,177.1c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S595.3,177.1,593.9,177.1z"
		/>
	<path class="st0" d="M603.7,285.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C606.4,286.5,605.2,285.4,603.7,285.4z"/>
	<path class="st0" d="M603.7,295.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C606.4,296.4,605.2,295.2,603.7,295.2z"/>
	<path class="st0" d="M584,295.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6C586.7,296.4,585.5,295.2,584,295.2z
		"/>
	<path class="st0" d="M593.9,295.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C596.5,296.4,595.3,295.2,593.9,295.2z"/>
	<path class="st0" d="M603.7,265.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C606.4,266.9,605.2,265.7,603.7,265.7z"/>
	<path class="st0" d="M603.7,216.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C606.4,217.7,605.2,216.5,603.7,216.5z"/>
	<path class="st0" d="M603.7,226.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C606.4,227.5,605.2,226.3,603.7,226.3z"/>
	<path class="st0" d="M603.7,236.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C606.4,237.3,605.2,236.2,603.7,236.2z"/>
	<path class="st0" d="M603.7,206.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C606.4,207.8,605.2,206.6,603.7,206.6z"/>
	<path class="st0" d="M603.7,246c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C606.4,247.2,605.2,246,603.7,246z"/>
	<path class="st0" d="M593.9,290.6c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C591.3,289.4,592.4,290.6,593.9,290.6z"/>
	<path class="st0" d="M593.9,167.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S595.3,167.3,593.9,167.3z"
		/>
	<path class="st0" d="M593.9,157.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C596.5,158.6,595.3,157.4,593.9,157.4z"/>
	<path class="st0" d="M584,290.6c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S582.6,290.6,584,290.6z"/>
	<path class="st0" d="M593.9,330c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C591.3,328.8,592.4,330,593.9,330z"/>
	<path class="st0" d="M603.7,324.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C606.4,325.9,605.2,324.7,603.7,324.7z"/>
	<path class="st0" d="M603.7,334.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C606.4,335.7,605.2,334.6,603.7,334.6z"/>
	<path class="st0" d="M603.7,314.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C606.4,316.1,605.2,314.9,603.7,314.9z"/>
	<path class="st0" d="M603.7,275.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C606.4,276.7,605.2,275.5,603.7,275.5z"/>
	<path class="st0" d="M593.9,482.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C596.5,483.3,595.3,482.2,593.9,482.2z"/>
	<path class="st0" d="M593.9,492c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S595.3,492,593.9,492z"/>
	<path class="st0" d="M603.7,492c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S605.2,492,603.7,492z"/>
	<path class="st0" d="M593.9,501.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S595.3,501.8,593.9,501.8z"/>
	<path class="st0" d="M593.9,472.3c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S595.3,472.3,593.9,472.3z"/>
	<path class="st0" d="M593.9,511.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S595.3,511.7,593.9,511.7z"
		/>
	<path class="st0" d="M593.9,452.6c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S595.3,452.6,593.9,452.6z"/>
	<path class="st0" d="M593.9,442.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S595.3,442.8,593.9,442.8z"/>
	<path class="st0" d="M593.9,462.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S595.3,462.5,593.9,462.5z"
		/>
	<path class="st0" d="M593.9,433c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S595.3,433,593.9,433z"/>
	<path class="st0" d="M603.7,157.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S605.2,157.4,603.7,157.4z"/>
	<path class="st0" d="M603.7,147.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C606.4,148.8,605.2,147.6,603.7,147.6z"/>
	<path class="st0" d="M603.7,167.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C606.4,168.5,605.2,167.3,603.7,167.3z"/>
	<path class="st0" d="M603.7,177.1c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C606.4,178.3,605.2,177.1,603.7,177.1z"/>
	<path class="st0" d="M603.7,187c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C606.4,188.1,605.2,187,603.7,187z"/>
	<path class="st0" d="M603.7,196.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C606.4,198,605.2,196.8,603.7,196.8z"/>
	<path class="st0" d="M584,152.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S582.6,152.9,584,152.9z"/>
	<path class="st0" d="M574.2,226.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S575.6,226.3,574.2,226.3z"/>
	<path class="st0" d="M593.9,187c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C596.5,188.1,595.3,187,593.9,187z"/>
	<circle class="st0" cx="574.2" cy="179.8" r="2.6"/>
	<path class="st0" d="M574.2,216.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S575.6,216.5,574.2,216.5z"/>
	<path class="st0" d="M574.2,206.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S575.6,206.6,574.2,206.6z"/>
	<path class="st0" d="M584,265.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C586.7,266.9,585.5,265.7,584,265.7z"/>
	<path class="st0" d="M574.2,167.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S575.6,167.3,574.2,167.3z"/>
	<path class="st0" d="M574.2,187c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6C576.8,188.1,575.6,187,574.2,187z"
		/>
	<path class="st0" d="M613.6,152.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.2,0-2.3,0.8-2.5,2v0.4H611c0,0.1,0,0.2,0,0.3
		C611,151.7,612.1,152.9,613.6,152.9z"/>
	<path class="st0" d="M574.2,285.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C576.8,286.5,575.6,285.4,574.2,285.4z"/>
	<path class="st0" d="M574.2,295.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C576.8,296.4,575.6,295.2,574.2,295.2z"/>
	<path class="st0" d="M593.9,423.1c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S595.3,423.1,593.9,423.1z"/>
	<path class="st0" d="M574.2,255.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S575.6,255.8,574.2,255.8z"/>
	<path class="st0" d="M574.2,246c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S575.6,246,574.2,246z"/>
	<circle class="st0" cx="574.2" cy="268.3" r="2.6"/>
	<path class="st0" d="M574.2,275.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S575.6,275.5,574.2,275.5z"/>
	<circle class="st0" cx="574.2" cy="199.4" r="2.6"/>
	<path class="st0" d="M593.9,255.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S595.3,255.8,593.9,255.8z"
		/>
	<path class="st0" d="M593.9,246c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S595.3,246,593.9,246z"/>
	<path class="st0" d="M593.9,236.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C596.5,237.3,595.3,236.2,593.9,236.2z"/>
	<path class="st0" d="M574.2,511.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S575.6,511.7,574.2,511.7z"/>
	<path class="st0" d="M584,275.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C586.7,276.7,585.5,275.5,584,275.5z"/>
	<path class="st0" d="M593.9,196.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S595.3,196.8,593.9,196.8z"
		/>
	<path class="st0" d="M593.9,226.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S595.3,226.3,593.9,226.3z"
		/>
	<path class="st0" d="M593.9,206.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S595.3,206.6,593.9,206.6z"
		/>
	<path class="st0" d="M593.9,216.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S595.3,216.5,593.9,216.5z"
		/>
	<path class="st0" d="M593.9,265.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C596.5,266.9,595.3,265.7,593.9,265.7z"/>
	<path class="st0" d="M584,236.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C586.7,237.3,585.5,236.2,584,236.2z"/>
	<path class="st0" d="M584,246c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6C586.7,247.2,585.5,246,584,246z
		"/>
	<path class="st0" d="M584,255.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C586.7,257,585.5,255.8,584,255.8z"/>
	<path class="st0" d="M584,226.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C586.7,227.5,585.5,226.3,584,226.3z"/>
	<path class="st0" d="M584,206.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C586.7,207.8,585.5,206.6,584,206.6z"/>
	<path class="st0" d="M584,196.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C586.7,198,585.5,196.8,584,196.8z"/>
	<path class="st0" d="M584,216.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C586.7,217.7,585.5,216.5,584,216.5z"/>
	<path class="st0" d="M584,187c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6C586.7,188.1,585.5,187,584,187z
		"/>
	<path class="st0" d="M584,177.1c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C586.7,178.3,585.5,177.1,584,177.1z"/>
	<path class="st0" d="M111,108.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C113.7,109.4,112.5,108.2,111,108.2z"/>
	<path class="st0" d="M111,305c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6C113.7,306.2,112.5,305,111,305z
		"/>
	<path class="st0" d="M111,314.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C113.7,316.1,112.5,314.9,111,314.9z"/>
	<path class="st0" d="M111,295.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C113.7,296.4,112.5,295.2,111,295.2z"/>
	<path class="st0" d="M113.7,327.4c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6S113.7,328.8,113.7,327.4z"
		/>
	<path class="st0" d="M111,285.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C113.7,286.5,112.5,285.4,111,285.4z"/>
	<circle class="st0" cx="101.2" cy="307.7" r="2.6"/>
	<path class="st0" d="M111,255.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C113.7,257,112.5,255.8,111,255.8z"/>
	<path class="st0" d="M111,265.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C113.7,266.9,112.5,265.7,111,265.7z"/>
	<path class="st0" d="M111,275.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C113.7,276.7,112.5,275.5,111,275.5z"/>
	<path class="st0" d="M101.2,300.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C98.5,299.3,99.7,300.5,101.2,300.5z"/>
	<circle class="st0" cx="101.2" cy="238.8" r="2.6"/>
	<path class="st0" d="M101.2,231.6c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C98.5,230.4,99.7,231.6,101.2,231.6z"/>
	<path class="st0" d="M101.2,211.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C98.5,210.7,99.7,211.9,101.2,211.9z"/>
	<path class="st0" d="M101.2,221.7c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C98.5,220.6,99.7,221.7,101.2,221.7z"/>
	<path class="st0" d="M101.2,251.3c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C98.5,250.1,99.7,251.3,101.2,251.3z"/>
	<path class="st0" d="M101.2,280.8c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C98.5,279.6,99.7,280.8,101.2,280.8z"/>
	<path class="st0" d="M101.2,290.6c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C98.5,289.4,99.7,290.6,101.2,290.6z"/>
	<path class="st0" d="M111,246c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6C113.7,247.2,112.5,246,111,246z
		"/>
	<path class="st0" d="M101.2,261.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C98.5,259.9,99.7,261.1,101.2,261.1z"/>
	<circle class="st0" cx="101.2" cy="268.3" r="2.6"/>
	<path class="st0" d="M111,236.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C113.7,237.3,112.5,236.2,111,236.2z"/>
	<path class="st0" d="M111,98.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S112.5,98.4,111,98.4z"/>
	<path class="st0" d="M111,118.1c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S112.5,118.1,111,118.1z"/>
	<path class="st0" d="M91.3,285.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C93.9,286.5,92.8,285.4,91.3,285.4z"/>
	<path class="st0" d="M91.3,275.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S92.8,275.5,91.3,275.5z"/>
	<path class="st0" d="M91.3,265.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C93.9,266.9,92.8,265.7,91.3,265.7z"/>
	<path class="st0" d="M91.3,295.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C93.9,296.4,92.8,295.2,91.3,295.2z"/>
	<path class="st0" d="M91.3,255.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S92.8,255.8,91.3,255.8z"/>
	<circle class="st0" cx="101.2" cy="199.4" r="2.6"/>
	<path class="st0" d="M91.3,246c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S92.8,246,91.3,246z"/>
	<path class="st0" d="M111,206.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C113.7,207.8,112.5,206.6,111,206.6z"/>
	<path class="st0" d="M111,187c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6C113.7,188.1,112.5,187,111,187z
		"/>
	<path class="st0" d="M111,196.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C113.7,198,112.5,196.8,111,196.8z"/>
	<path class="st0" d="M111,226.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C113.7,227.5,112.5,226.3,111,226.3z"/>
	<path class="st0" d="M111,216.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C113.7,217.7,112.5,216.5,111,216.5z"/>
	<path class="st0" d="M111,157.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S112.5,157.4,111,157.4z"/>
	<path class="st0" d="M111,147.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C113.7,148.8,112.5,147.6,111,147.6z"/>
	<path class="st0" d="M111,177.1c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C113.7,178.3,112.5,177.1,111,177.1z"/>
	<path class="st0" d="M574.2,501.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S575.6,501.8,574.2,501.8z"/>
	<path class="st0" d="M111,167.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C113.7,168.5,112.5,167.3,111,167.3z"/>
	<path class="st0" d="M111,127.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C113.7,129.1,112.5,127.9,111,127.9z"/>
	<path class="st0" d="M81.5,167.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S82.9,167.3,81.5,167.3z"/>
	<path class="st0" d="M81.5,157.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S82.9,157.4,81.5,157.4z"/>
	<path class="st0" d="M81.5,177.1c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S82.9,177.1,81.5,177.1z"/>
	<path class="st0" d="M81.5,187c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6C84.1,188.1,82.9,187,81.5,187z
		"/>
	<path class="st0" d="M81.5,196.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S82.9,196.8,81.5,196.8z"/>
	<path class="st0" d="M81.5,127.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C84.1,129.1,82.9,127.9,81.5,127.9z"/>
	<path class="st0" d="M81.5,147.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S82.9,147.6,81.5,147.6z"/>
	<path class="st0" d="M81.5,118.1c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S82.9,118.1,81.5,118.1z"/>
	<path class="st0" d="M81.5,137.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C84.1,138.9,82.9,137.8,81.5,137.8z"/>
	<path class="st0" d="M81.5,275.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S82.9,275.5,81.5,275.5z"/>
	<path class="st0" d="M81.5,108.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C84.1,109.4,82.9,108.2,81.5,108.2z"/>
	<path class="st0" d="M81.5,285.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C84.1,286.5,82.9,285.4,81.5,285.4z"/>
	<path class="st0" d="M81.5,265.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C84.1,266.9,82.9,265.7,81.5,265.7z"/>
	<path class="st0" d="M71.6,152.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S70.2,152.9,71.6,152.9z"/>
	<path class="st0" d="M81.5,255.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S82.9,255.8,81.5,255.8z"/>
	<path class="st0" d="M81.5,246c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S82.9,246,81.5,246z"/>
	<path class="st0" d="M101.2,172.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C98.5,171.4,99.7,172.5,101.2,172.5z"/>
	<path class="st0" d="M101.2,123.3c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S99.7,123.3,101.2,123.3z"/>
	<path class="st0" d="M101.2,133.2c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C98.5,132,99.7,133.2,101.2,133.2z"/>
	<path class="st0" d="M101.2,103.7c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S99.7,103.7,101.2,103.7z"/>
	<path class="st0" d="M101.2,143c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C98.5,141.8,99.7,143,101.2,143z"/>
	<path class="st0" d="M101.2,113.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C98.5,112.3,99.7,113.5,101.2,113.5z"/>
	<circle class="st0" cx="101.2" cy="179.8" r="2.6"/>
	<path class="st0" d="M101.2,152.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C98.5,151.7,99.7,152.9,101.2,152.9z"/>
	<path class="st0" d="M101.2,192.2c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C98.5,191,99.7,192.2,101.2,192.2z"/>
	<path class="st0" d="M81.5,98.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S82.9,98.4,81.5,98.4z"/>
	<circle class="st0" cx="101.2" cy="160.1" r="2.6"/>
	<path class="st0" d="M101.2,84c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6C98.5,82.8,99.7,84,101.2,84z"
		/>
	<path class="st0" d="M71.6,162.7c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S70.2,162.7,71.6,162.7z"/>
	<path class="st0" d="M81.5,88.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C84.1,89.7,82.9,88.6,81.5,88.6z"/>
	<path class="st0" d="M81.5,78.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C84.1,79.9,82.9,78.7,81.5,78.7z"/>
	<path class="st0" d="M81.5,68.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S82.9,68.9,81.5,68.9z"/>
	<path class="st0" d="M101.2,93.8c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C98.5,92.6,99.7,93.8,101.2,93.8z"/>
	<path class="st0" d="M130.7,295.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C133.4,296.4,132.2,295.2,130.7,295.2z"/>
	<path class="st0" d="M130.7,285.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C133.4,286.5,132.2,285.4,130.7,285.4z"/>
	<path class="st0" d="M130.7,275.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C133.4,276.7,132.2,275.5,130.7,275.5z"/>
	<path class="st0" d="M71.6,143c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S70.2,143,71.6,143z"/>
	<path class="st0" d="M130.7,314.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C133.4,316.1,132.2,314.9,130.7,314.9z"/>
	<path class="st0" d="M130.7,305c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C133.4,306.2,132.2,305,130.7,305z"/>
	<path class="st0" d="M130.7,265.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C133.4,266.9,132.2,265.7,130.7,265.7z"/>
	<path class="st0" d="M130.7,226.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C133.4,227.5,132.2,226.3,130.7,226.3z"/>
	<path class="st0" d="M130.7,236.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C133.4,237.3,132.2,236.2,130.7,236.2z"/>
	<path class="st0" d="M130.7,216.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C133.4,217.7,132.2,216.5,130.7,216.5z"/>
	<path class="st0" d="M130.7,246c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C133.4,247.2,132.2,246,130.7,246z"/>
	<path class="st0" d="M130.7,255.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C133.4,257,132.2,255.8,130.7,255.8z"/>
	<path class="st0" d="M120.9,98.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S122.3,98.4,120.9,98.4z"/>
	<path class="st0" d="M120.9,349.7c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S119.4,349.7,120.9,349.7z"
		/>
	<path class="st0" d="M130.7,324.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C133.4,325.9,132.2,324.7,130.7,324.7z"/>
	<path class="st0" d="M120.9,108.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C123.5,109.4,122.3,108.2,120.9,108.2z"/>
	<path class="st0" d="M120.9,118.1c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S122.3,118.1,120.9,118.1z"/>
	<path class="st0" d="M130.7,344.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C133.4,345.6,132.2,344.4,130.7,344.4z"/>
	<path class="st0" d="M120.9,88.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C123.5,89.7,122.3,88.6,120.9,88.6z"/>
	<path class="st0" d="M130.7,334.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C133.4,335.7,132.2,334.6,130.7,334.6z"/>
	<path class="st0" d="M130.7,359.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C128.1,358.3,129.3,359.5,130.7,359.5z"/>
	<path class="st0" d="M130.7,98.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S132.2,98.4,130.7,98.4z"/>
	<path class="st0" d="M130.7,88.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C133.4,89.7,132.2,88.6,130.7,88.6z"/>
	<path class="st0" d="M278.6,24.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-0.8,0-1.5,0.4-2,0.9l-0.5,1.1
		c0,0.2-0.1,0.4-0.1,0.6C275.9,23.8,277.1,24.9,278.6,24.9z"/>
	<path class="st0" d="M120.9,127.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C123.5,129.1,122.3,127.9,120.9,127.9z"/>
	<path class="st0" d="M130.7,177.1c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C133.4,178.3,132.2,177.1,130.7,177.1z"/>
	<path class="st0" d="M130.7,206.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C133.4,207.8,132.2,206.6,130.7,206.6z"/>
	<path class="st0" d="M130.7,108.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C133.4,109.4,132.2,108.2,130.7,108.2z"/>
	<path class="st0" d="M130.7,167.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C133.4,168.5,132.2,167.3,130.7,167.3z"/>
	<path class="st0" d="M130.7,187c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C133.4,188.1,132.2,187,130.7,187z"/>
	<path class="st0" d="M130.7,196.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C133.4,198,132.2,196.8,130.7,196.8z"/>
	<path class="st0" d="M130.7,157.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S132.2,157.4,130.7,157.4z"/>
	<path class="st0" d="M130.7,147.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C133.4,148.8,132.2,147.6,130.7,147.6z"/>
	<path class="st0" d="M130.7,118.1c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S132.2,118.1,130.7,118.1z"/>
	<path class="st0" d="M130.7,127.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C133.4,129.1,132.2,127.9,130.7,127.9z"/>
	<path class="st0" d="M130.7,137.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C133.4,138.9,132.2,137.8,130.7,137.8z"/>
	<path class="st0" d="M120.9,305c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S122.3,305,120.9,305z"/>
	<path class="st0" d="M91.3,98.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S92.8,98.4,91.3,98.4z"/>
	<path class="st0" d="M91.3,88.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C93.9,89.7,92.8,88.6,91.3,88.6z"/>
	<path class="st0" d="M91.3,78.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C93.9,79.9,92.8,78.7,91.3,78.7z"/>
	<path class="st0" d="M91.3,118.1c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S92.8,118.1,91.3,118.1z"/>
	<path class="st0" d="M91.3,108.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C93.9,109.4,92.8,108.2,91.3,108.2z"/>
	<path class="st0" d="M91.3,68.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S92.8,68.9,91.3,68.9z"/>
	<path class="st0" d="M91.3,127.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C93.9,129.1,92.8,127.9,91.3,127.9z"/>
	<path class="st0" d="M91.3,310.3c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C88.7,309.1,89.9,310.3,91.3,310.3z"/>
	<path class="st0" d="M91.3,147.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S92.8,147.6,91.3,147.6z"/>
	<path class="st0" d="M91.3,206.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S92.8,206.6,91.3,206.6z"/>
	<path class="st0" d="M91.3,196.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S92.8,196.8,91.3,196.8z"/>
	<path class="st0" d="M91.3,226.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S92.8,226.3,91.3,226.3z"/>
	<path class="st0" d="M91.3,216.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S92.8,216.5,91.3,216.5z"/>
	<path class="st0" d="M91.3,187c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6C93.9,188.1,92.8,187,91.3,187z
		"/>
	<path class="st0" d="M91.3,167.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S92.8,167.3,91.3,167.3z"/>
	<path class="st0" d="M91.3,157.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S92.8,157.4,91.3,157.4z"/>
	<path class="st0" d="M91.3,177.1c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S92.8,177.1,91.3,177.1z"/>
	<path class="st0" d="M111,88.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C113.7,89.7,112.5,88.6,111,88.6z"/>
	<path class="st0" d="M120.9,137.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C123.5,138.9,122.3,137.8,120.9,137.8z"/>
	<path class="st0" d="M91.3,137.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C93.9,138.9,92.8,137.8,91.3,137.8z"/>
	<path class="st0" d="M120.9,187c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C123.5,188.1,122.3,187,120.9,187z"/>
	<path class="st0" d="M120.9,206.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S122.3,206.6,120.9,206.6z"
		/>
	<path class="st0" d="M120.9,196.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S122.3,196.8,120.9,196.8z"
		/>
	<path class="st0" d="M120.9,216.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S122.3,216.5,120.9,216.5z"
		/>
	<path class="st0" d="M120.9,226.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S122.3,226.3,120.9,226.3z"
		/>
	<path class="st0" d="M120.9,147.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S122.3,147.6,120.9,147.6z"
		/>
	<path class="st0" d="M120.9,157.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S122.3,157.4,120.9,157.4z"/>
	<path class="st0" d="M120.9,236.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C123.5,237.3,122.3,236.2,120.9,236.2z"/>
	<path class="st0" d="M120.9,177.1c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S122.3,177.1,120.9,177.1z"
		/>
	<path class="st0" d="M120.9,167.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S122.3,167.3,120.9,167.3z"
		/>
	<path class="st0" d="M120.9,334.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C123.5,335.7,122.3,334.6,120.9,334.6z"/>
	<path class="st0" d="M120.9,314.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S122.3,314.9,120.9,314.9z"
		/>
	<path class="st0" d="M91.3,236.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C93.9,237.3,92.8,236.2,91.3,236.2z"/>
	<path class="st0" d="M120.9,246c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S122.3,246,120.9,246z"/>
	<path class="st0" d="M120.9,295.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C123.5,296.4,122.3,295.2,120.9,295.2z"/>
	<path class="st0" d="M120.9,324.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C123.5,325.9,122.3,324.7,120.9,324.7z"/>
	<path class="st0" d="M120.9,255.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S122.3,255.8,120.9,255.8z"
		/>
	<path class="st0" d="M120.9,265.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C123.5,266.9,122.3,265.7,120.9,265.7z"/>
	<path class="st0" d="M120.9,285.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C123.5,286.5,122.3,285.4,120.9,285.4z"/>
	<path class="st0" d="M120.9,275.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S122.3,275.5,120.9,275.5z"
		/>
	<path class="st0" d="M111,137.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C113.7,138.9,112.5,137.8,111,137.8z"/>
	<path class="st0" d="M731.8,265.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C734.5,266.9,733.3,265.7,731.8,265.7z"/>
	<path class="st0" d="M731.8,226.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C734.5,227.5,733.3,226.3,731.8,226.3z"/>
	<path class="st0" d="M731.8,246c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C734.5,247.2,733.3,246,731.8,246z"/>
	<path class="st0" d="M731.8,216.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C734.5,217.7,733.3,216.5,731.8,216.5z"/>
	<path class="st0" d="M741.7,305c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S743.2,305,741.7,305z"/>
	<path class="st0" d="M731.8,236.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C734.5,237.3,733.3,236.2,731.8,236.2z"/>
	<path class="st0" d="M731.8,187c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C734.5,188.1,733.3,187,731.8,187z"/>
	<path class="st0" d="M731.8,177.1c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C734.5,178.3,733.3,177.1,731.8,177.1z"/>
	<path class="st0" d="M731.8,196.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C734.5,198,733.3,196.8,731.8,196.8z"/>
	<path class="st0" d="M731.8,206.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C734.5,207.8,733.3,206.6,731.8,206.6z"/>
	<path class="st0" d="M731.8,275.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C734.5,276.7,733.3,275.5,731.8,275.5z"/>
	<path class="st0" d="M731.8,334.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C734.5,335.7,733.3,334.6,731.8,334.6z"/>
	<path class="st0" d="M731.8,167.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C734.5,168.5,733.3,167.3,731.8,167.3z"/>
	<path class="st0" d="M731.8,344.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C734.5,345.6,733.3,344.4,731.8,344.4z"/>
	<path class="st0" d="M731.8,324.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C734.5,325.9,733.3,324.7,731.8,324.7z"/>
	<path class="st0" d="M731.8,354.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C734.5,355.4,733.3,354.2,731.8,354.2z"/>
	<path class="st0" d="M722,354.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C724.6,355.4,723.4,354.2,722,354.2z"/>
	<path class="st0" d="M731.8,314.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C734.5,316.1,733.3,314.9,731.8,314.9z"/>
	<path class="st0" d="M731.8,295.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C734.5,296.4,733.3,295.2,731.8,295.2z"/>
	<path class="st0" d="M731.8,285.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C734.5,286.5,733.3,285.4,731.8,285.4z"/>
	<path class="st0" d="M731.8,305c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C734.5,306.2,733.3,305,731.8,305z"/>
	<path class="st0" d="M731.8,78.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C734.5,79.9,733.3,78.7,731.8,78.7z"/>
	<path class="st0" d="M741.7,354.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C744.3,355.4,743.2,354.2,741.7,354.2z"/>
	<path class="st0" d="M741.7,373.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S743.2,373.9,741.7,373.9z"/>
	<path class="st0" d="M751.6,373.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C754.2,375.1,753,373.9,751.6,373.9z"/>
	<path class="st0" d="M731.8,373.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C734.5,375.1,733.3,373.9,731.8,373.9z"/>
	<path class="st0" d="M741.7,364.1c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C744.3,365.3,743.2,364.1,741.7,364.1z"/>
	<path class="st0" d="M731.8,49.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C734.5,50.4,733.3,49.2,731.8,49.2z"/>
	<path class="st0" d="M731.8,369.3c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C729.2,368.2,730.4,369.3,731.8,369.3z"/>
	<path class="st0" d="M741.7,344.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S743.2,344.4,741.7,344.4z"/>
	<path class="st0" d="M741.7,314.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S743.2,314.9,741.7,314.9z"/>
	<path class="st0" d="M741.7,324.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C744.3,325.9,743.2,324.7,741.7,324.7z"/>
	<path class="st0" d="M741.7,334.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C744.3,335.7,743.2,334.6,741.7,334.6z"/>
	<path class="st0" d="M731.8,137.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C734.5,138.9,733.3,137.8,731.8,137.8z"/>
	<path class="st0" d="M731.8,127.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C734.5,129.1,733.3,127.9,731.8,127.9z"/>
	<path class="st0" d="M731.8,118.1c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S733.3,118.1,731.8,118.1z"/>
	<path class="st0" d="M731.8,157.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S733.3,157.4,731.8,157.4z"/>
	<path class="st0" d="M731.8,108.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C734.5,109.4,733.3,108.2,731.8,108.2z"/>
	<path class="st0" d="M731.8,147.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C734.5,148.8,733.3,147.6,731.8,147.6z"/>
	<path class="st0" d="M731.8,88.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C734.5,89.7,733.3,88.6,731.8,88.6z"/>
	<path class="st0" d="M731.8,98.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S733.3,98.4,731.8,98.4z"/>
	<path class="st0" d="M731.8,68.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C734.5,70.1,733.3,68.9,731.8,68.9z"/>
	<path class="st0" d="M731.8,59c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S733.3,59,731.8,59z"/>
	<path class="st0" d="M731.8,255.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C734.5,257,733.3,255.8,731.8,255.8z"/>
	<path class="st0" d="M574.2,364.1c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C576.8,365.3,575.6,364.1,574.2,364.1z"/>
	<circle class="st0" cx="574.2" cy="347" r="2.6"/>
	<path class="st0" d="M574.2,354.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C576.8,355.4,575.6,354.2,574.2,354.2z"/>
	<path class="st0" d="M574.2,373.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S575.6,373.9,574.2,373.9z"/>
	<circle class="st0" cx="574.2" cy="386.4" r="2.6"/>
	<path class="st0" d="M574.2,393.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C576.8,394.8,575.6,393.6,574.2,393.6z"/>
	<path class="st0" d="M574.2,334.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C576.8,335.7,575.6,334.6,574.2,334.6z"/>
	<path class="st0" d="M574.2,324.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C576.8,325.9,575.6,324.7,574.2,324.7z"/>
	<path class="st0" d="M574.2,546.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6
		C571.5,545.3,572.7,546.5,574.2,546.5z"/>
	<path class="st0" d="M574.2,403.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S575.6,403.4,574.2,403.4z"/>
	<circle class="st0" cx="574.2" cy="475" r="2.6"/>
	<circle class="st0" cx="574.2" cy="494.6" r="2.6"/>
	<circle class="st0" cx="574.2" cy="455.3" r="2.6"/>
	<path class="st0" d="M574.2,482.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C576.8,483.3,575.6,482.2,574.2,482.2z"/>
	<path class="st0" d="M574.2,462.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S575.6,462.5,574.2,462.5z"/>
	<circle class="st0" cx="574.2" cy="415.9" r="2.6"/>
	<path class="st0" d="M574.2,423.1c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S575.6,423.1,574.2,423.1z"/>
	<path class="st0" d="M574.2,442.8c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S575.6,442.8,574.2,442.8z"/>
	<path class="st0" d="M574.2,433c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S575.6,433,574.2,433z"/>
	<path class="st0" d="M672.7,359.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C670.1,358.3,671.3,359.5,672.7,359.5z"/>
	<path class="st0" d="M672.7,349.7c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C670.1,348.5,671.3,349.7,672.7,349.7z"/>
	<path class="st0" d="M662.9,349.7c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6
		C660.2,348.5,661.4,349.7,662.9,349.7z"/>
	<path class="st0" d="M672.7,339.8c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C670.1,338.6,671.3,339.8,672.7,339.8z"/>
	<path class="st0" d="M662.9,369.3c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6
		C660.2,368.2,661.4,369.3,662.9,369.3z"/>
	<path class="st0" d="M672.7,369.3c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C670.1,368.2,671.3,369.3,672.7,369.3z"/>
	<path class="st0" d="M584,536.6c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S582.6,536.6,584,536.6z"/>
	<path class="st0" d="M692.4,108.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C695.1,109.4,693.9,108.2,692.4,108.2z"/>
	<path class="st0" d="M682.6,108.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C685.2,109.4,684,108.2,682.6,108.2z"/>
	<path class="st0" d="M662.9,359.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6
		C660.2,358.3,661.4,359.5,662.9,359.5z"/>
	<path class="st0" d="M613.6,462.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C616.2,463.7,615,462.5,613.6,462.5z"/>
	<path class="st0" d="M603.7,364.1c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C606.4,365.3,605.2,364.1,603.7,364.1z"/>
	<circle class="st0" cx="653" cy="347" r="2.6"/>
	<path class="st0" d="M643.2,492c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S644.6,492,643.2,492z"/>
	<path class="st0" d="M643.2,482.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C645.8,483.3,644.6,482.2,643.2,482.2z"/>
	<path class="st0" d="M49.3,61.7c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6c0-1.4-1.1-2.6-2.6-2.6L50,59.8
		C49.6,60.3,49.3,60.9,49.3,61.7z"/>
	<path class="st0" d="M51.9,68.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S53.4,68.9,51.9,68.9z"/>
	<path class="st0" d="M42,118.1c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S43.5,118.1,42,118.1z"/>
	<path class="st0" d="M42,127.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C44.7,129.1,43.5,127.9,42,127.9z"/>
	<path class="st0" d="M51.9,78.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C54.5,79.9,53.4,78.7,51.9,78.7z"/>
	<path class="st0" d="M51.9,88.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C54.5,89.7,53.4,88.6,51.9,88.6z"/>
	<path class="st0" d="M51.9,98.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S53.4,98.4,51.9,98.4z"/>
	<path class="st0" d="M741.7,295.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C744.3,296.4,743.2,295.2,741.7,295.2z"/>
	<path class="st0" d="M42,78.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6C44.7,79.9,43.5,78.7,42,78.7z"
		/>
	<path class="st0" d="M42,108.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C44.7,109.4,43.5,108.2,42,108.2z"/>
	<path class="st0" d="M42,98.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S43.5,98.4,42,98.4z"/>
	<path class="st0" d="M32.2,68.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S33.6,68.9,32.2,68.9z"/>
	<path class="st0" d="M42,68.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6C44.7,70.1,43.5,68.9,42,68.9z"
		/>
	<path class="st0" d="M32.2,78.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C34.8,79.9,33.6,78.7,32.2,78.7z"/>
	<path class="st0" d="M32.2,88.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C34.8,89.7,33.6,88.6,32.2,88.6z"/>
	<path class="st0" d="M32.2,143c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S30.7,143,32.2,143z"/>
	<path class="st0" d="M32.2,98.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S33.6,98.4,32.2,98.4z"/>
	<path class="st0" d="M51.9,137.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C54.5,138.9,53.4,137.8,51.9,137.8z"/>
	<path class="st0" d="M44.7,140.4c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c0.2,0,0.4,0,0.6-0.1l0.5-0.2
		C44.1,142.3,44.7,141.4,44.7,140.4z"/>
	<path class="st0" d="M51.9,108.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C54.5,109.4,53.4,108.2,51.9,108.2z"/>
	<path class="st0" d="M51.9,118.1c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S53.4,118.1,51.9,118.1z"/>
	<path class="st0" d="M51.9,127.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C54.5,129.1,53.4,127.9,51.9,127.9z"/>
	<path class="st0" d="M61.8,143c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6C59.1,141.8,60.3,143,61.8,143z
		"/>
	<path class="st0" d="M71.6,74.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6C69,73,70.2,74.1,71.6,74.1z"
		/>
	<path class="st0" d="M71.6,64.3c1.5,0,2.6-1.2,2.6-2.6S73.1,59,71.6,59S69,60.2,69,61.6S70.2,64.3,71.6,64.3z"/>
	<path class="st0" d="M61.8,59c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S63.2,59,61.8,59z"/>
	<path class="st0" d="M71.6,133.2c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C69,132,70.2,133.2,71.6,133.2z"/>
	<path class="st0" d="M71.6,113.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S70.2,113.5,71.6,113.5z"/>
	<path class="st0" d="M71.6,123.3c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S70.2,123.3,71.6,123.3z"/>
	<path class="st0" d="M71.6,93.8c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6C69,92.6,70.2,93.8,71.6,93.8z
		"/>
	<path class="st0" d="M71.6,103.7c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S70.2,103.7,71.6,103.7z"/>
	<path class="st0" d="M71.6,84c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S70.2,84,71.6,84z"/>
	<path class="st0" d="M61.8,74.1c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6C59.1,73,60.3,74.1,61.8,74.1z
		"/>
	<path class="st0" d="M61.8,93.8c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C59.1,92.6,60.3,93.8,61.8,93.8z"/>
	<path class="st0" d="M61.8,84c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6C59.1,82.8,60.3,84,61.8,84z"/>
	<path class="st0" d="M61.8,152.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C59.1,151.7,60.3,152.9,61.8,152.9z"/>
	<path class="st0" d="M61.8,103.7c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S60.3,103.7,61.8,103.7z"/>
	<path class="st0" d="M32.2,108.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C34.8,109.4,33.6,108.2,32.2,108.2z"/>
	<path class="st0" d="M61.8,113.5c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C59.1,112.3,60.3,113.5,61.8,113.5z"/>
	<path class="st0" d="M61.8,133.2c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6
		C59.1,132,60.3,133.2,61.8,133.2z"/>
	<path class="st0" d="M61.8,123.3c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S60.3,123.3,61.8,123.3z"/>
	<path class="st0" d="M42,88.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6C44.7,89.7,43.5,88.6,42,88.6z"
		/>
	<path class="st0" d="M741.7,157.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S743.2,157.4,741.7,157.4z"/>
	<path class="st0" d="M741.7,147.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S743.2,147.6,741.7,147.6z"/>
	<path class="st0" d="M741.7,187c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6C744.3,188.1,743.2,187,741.7,187z"
		/>
	<path class="st0" d="M741.7,177.1c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S743.2,177.1,741.7,177.1z"/>
	<path class="st0" d="M741.7,167.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S743.2,167.3,741.7,167.3z"/>
	<path class="st0" d="M741.7,137.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C744.3,138.9,743.2,137.8,741.7,137.8z"/>
	<path class="st0" d="M741.7,108.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C744.3,109.4,743.2,108.2,741.7,108.2z"/>
	<path class="st0" d="M741.7,88.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6C744.3,89.7,743.2,88.6,741.7,88.6z
		"/>
	<path class="st0" d="M741.7,118.1c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S743.2,118.1,741.7,118.1z"/>
	<path class="st0" d="M741.7,127.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C744.3,129.1,743.2,127.9,741.7,127.9z"/>
	<path class="st0" d="M741.7,98.4c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S743.2,98.4,741.7,98.4z"/>
	<path class="st0" d="M741.7,255.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S743.2,255.8,741.7,255.8z"/>
	<path class="st0" d="M741.7,265.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C744.3,266.9,743.2,265.7,741.7,265.7z"/>
	<path class="st0" d="M741.7,196.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S743.2,196.8,741.7,196.8z"/>
	<path class="st0" d="M741.7,246c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S743.2,246,741.7,246z"/>
	<path class="st0" d="M741.7,285.4c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C744.3,286.5,743.2,285.4,741.7,285.4z"/>
	<path class="st0" d="M741.7,275.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S743.2,275.5,741.7,275.5z"/>
	<path class="st0" d="M741.7,216.5c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S743.2,216.5,741.7,216.5z"/>
	<path class="st0" d="M741.7,236.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
		C744.3,237.3,743.2,236.2,741.7,236.2z"/>
	<path class="st0" d="M741.7,206.6c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S743.2,206.6,741.7,206.6z"/>
	<path class="st0" d="M741.7,226.3c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S743.2,226.3,741.7,226.3z"/>
	<path class="st0" d="M288.4,556.3c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6c0,0.9,0.5,1.8,1.2,2.2l-0.1-0.2
		l1.2,0.6C288.1,556.3,288.3,556.3,288.4,556.3z"/>
	<path class="st0" d="M32.2,118.1c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S33.6,118.1,32.2,118.1z"/>
	<path class="st0" d="M741.7,389c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6S740.3,389,741.7,389z"/>
	<path class="st0" d="M751.6,389c1.5,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6c-1.5,0-2.6,1.2-2.6,2.6S750.1,389,751.6,389z"/>
	<path class="st0" d="M741.7,398.9c1.5,0,2.6-1.2,2.6-2.6c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6
		C739.1,397.7,740.3,398.9,741.7,398.9z"/>
	<path class="st0" d="M741.7,59c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S743.2,59,741.7,59z"/>
	<path class="st0" d="M741.7,49.2c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S743.2,49.2,741.7,49.2z"/>
	<path class="st0" d="M741.7,68.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S743.2,68.9,741.7,68.9z"/>
	<path class="st0" d="M751.6,59c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S753,59,751.6,59z"/>
	<path class="st0" d="M751.6,68.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C754.2,70.1,753,68.9,751.6,68.9z"/>
	<path class="st0" d="M741.7,78.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6C744.3,79.9,743.2,78.7,741.7,78.7z
		"/>
	<path class="st0" d="M32.2,127.9c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6
		C34.8,129.1,33.6,127.9,32.2,127.9z"/>
</g>
</svg>

                `,
            }}
        />
    );
};

export default Map;
